import {
  AppBar,
  Paper,
  Grid,
  Button,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import logo from "../../assets/kinary_header_logo.svg";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// import MenuIcon from "@mui/icons-material/Menu";
import allContent from "../../content/en.json";

export interface IHeaderProps {
  matches: boolean;
}

const { common, header } = allContent;

export default function Header({ matches }: IHeaderProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isTablet = useMediaQuery("(max-width: 1023px)");
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const navigate = useNavigate();
  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: "none",
        bgcolor: "white",
        borderRadius: "0",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          alignContent: "center",
          height: matches ? "60px" : "80px",
          width: matches ? "89%" : "83%",
          margin: "auto",
          borderRadius: "0px",
          alignItems: "center",
        }}
      >
        <Grid
          gap={matches ? "24px" : "42px"}
          display="flex"
          alignItems="center"
        >
          <Link to="/" component={RouterLink}>
            <img
              src={logo}
              loading="lazy"
              alt="Kinary Logo"
              style={{
                height: matches ? "34px" : "48px",
                width: matches ? "100px" : "144px",
              }}
            />
          </Link>
          {!isMobile && (
            <Grid
              sx={{
                display: "flex",
              }}
            >
              {/* <Link
              href="#"
              onClick={(event) => {
                event.preventDefault();
              }}
              target="_self"
              color="#383838"
              marginRight={matches ? "24px" : "42px"}
            >
              <Typography
                variant="body2"
              >
                {header.about}
              </Typography>
            </Link>

            <Link
              href="#"
              onClick={(event) => {
                event.preventDefault();
              }}
              target="_self"
              color="#383838"
              marginRight={matches ? "24px" : "42px"}
            >
              <Typography
                variant="body2"
              >
                {header.resources}
              </Typography>
            </Link> */}

              {/* <Link
              href="#"
              onClick={(event) => {
                event.preventDefault();
                setIsOpen(true)
              }}
              target="_self"
              color="#383838"
            >
              <Typography
                variant="body2"
              >
                {header.contact}
              </Typography>
            </Link> */}
            </Grid>
          )}
        </Grid>
        <Grid
          container
          gap="16px"
          justifyContent="flex-end"
          alignItems="center"
          flexGrow={1}
        >
          {!isMobile ? (
            <>
              <Link
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/support");
                }}
                target="_self"
                color="#383838"
                sx={{
                  marginRight: isDesktop || isTablet ? "8px" : "16px",
                  textDecoration: "none",
                  "& :hover": {
                    textDecoration: "none",
                    color: "#F25832",
                  },
                  "&:active": {
                    textDecoration: "none",
                    color: "#F25832",
                  },
                }}
              >
                <Typography variant="body2">{header.contact}</Typography>
              </Link>
              <Link
                to="/training-login"
                component={RouterLink}
                underline="none"
              >
                <Button
                  variant="text"
                  disableTouchRipple
                  onClick={(event) => event.stopPropagation()}
                >
                  {common.training_program}
                </Button>
              </Link>
              {!isTablet && (
                <Link to="/download" component={RouterLink} underline="none">
                  <Button variant="contained" disableTouchRipple>
                    {common.download_for_free}
                  </Button>
                </Link>
              )}
            </>
          ) : (
            <>
              <Link
                to="/training-login"
                component={RouterLink}
                underline="none"
              >
                <Button
                  variant="text"
                  disableTouchRipple
                  onClick={(event) => event.stopPropagation()}
                >
                  {common.training_program}
                </Button>
              </Link>
              {/* <MenuIcon
                onClick={() => setIsOpen(true)}
                sx={{
                  cursor: "pointer",
                }}
              /> */}
            </>
          )}
        </Grid>
      </Paper>
    </AppBar>
  );
}
