import { Button, Grid, Typography, Link, useTheme, useMediaQuery } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import caregiverBg from "../../../assets/download_bg.jpg";
import { parseHTML } from "../../../service/utils";

interface ICaregiverFcHeroProps {
  matches: boolean;
  content: any;
  reference: any;
  banner: any;
  click: (ref: any) => void;
}

function CaregiverFcHero(props: ICaregiverFcHeroProps) {
  const { content, matches, reference, click, banner } = props;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.between("lg", "xl"));

  const { hero } = content;
  return (
    <Grid ref={reference} sx={{
      backgroundImage: `url(${caregiverBg})`,
      backgroundAttachment: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundColor: "#FEE5D4",
    }}>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: matches ? "column" : "row",
          alignItems: matches ? "center" : "flex-start",
          margin: "0 auto",
          width: matches ? "auto": isLargeDesktop ? "90%" : "80%",
          paddingTop: "80px", 
          paddingX: matches ? "20px" : "0px",
        }}
      >
        <Grid
          sx={{
            width: matches ? isTablet ? "415px" : "100%" : isLargeDesktop ? "634px" : "662px",
            display: "flex",
            flexDirection: "column",
            alignItems: matches ? "center" : "flex-start",
            paddingTop: "40px"
          }}
        >
          <Typography
            variant="h1"
            sx={{
              paddingBottom: matches ? isTablet ? "24px" : "8px" : isDesktop ? "24px" : "32px",
              textAlign: matches ? "center" : "left",
            }}
          >
            {parseHTML(hero.title)}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: matches ? isTablet ? "80%" : "100%" : "67%",
              color: "#383838",
              paddingBottom: matches ? isTablet ? "24px" : "16px" : isDesktop ? "24px" : "40px",
              textAlign: matches ? "center" : "left",
            }}
          >
            {hero.description}
          </Typography>
          <Link
            to="#"
            underline="none"
            sx={{
              maxWidth: "max-content",
            }}
            component={RouterLink}
          >
            <Button onClick={click} disableTouchRipple variant="contained" size={matches || isDesktop ? "medium" : "large"} sx={{ height: isDesktop ? "44px" : "auto"}}>
              {hero.register}
            </Button>
          </Link>
        </Grid>
        <Grid sx={{
            width: matches? "335px" : "656px",
            height: "auto",
            marginTop: matches ? "40px" : "0",
            lineHeight: "0"
        }}>
          <img
            loading="lazy"
            src={banner}
            alt={"banner"}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CaregiverFcHero;
