import {
  Grid,
  Input,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import Button from "@mui/material/Button";
import KinaryTipsCard from "./KinaryTipsCard";
import { blogOptions } from "../../content/options";
import allContent from "../../content/en.json";
import { useState } from "react";
import { setSubscriberEmail } from "../../service";
import { parseHTML } from "../../service/utils";
import orangeTick from "../../assets/orange-success-tick.svg";

interface IKinaryTips {
  matches: boolean;
}

const {
  home: { tips },
  common,
} = allContent;

export default function KinaryTips({ matches }: IKinaryTips) {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [errorMessage, setErrorMessage] = useState(
    common.invalid_email_message
  );
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const onSubscribeHandle = () => {
    const isEmailValid = emailRegex.test(email);
    setIsValidEmail(email === "" || isEmailValid);
    if (!email || !isEmailValid) return;
    setIsLoading(true);
    setSubscriberEmail(email)
      .then((res) => {
        if (res.ok) {
          setEmail("");
          setSubscribed(true);
          return res.json();
        } else {
          console.log("error");
          setIsValidEmail(false);
          setErrorMessage(common.server_error_message);
        }
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsValidEmail(false);
        setErrorMessage(common.server_error_message);
      });
  };
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          py: matches ? "80px" : "120px",
        }}
      >
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              maxWidth: !isTablet ? "100%" : "min(67%, 456px)",
              margin: isMobile ? "0" : "0 auto",
              paddingX: isMobile ? "32px" : "0",
            }}
          >
            {parseHTML(tips.title)}
          </Typography>
        </Grid>
        <Grid
          display="flex"
          gap={"20px"}
          flexDirection={isMobile ? "column" : "row"}
          paddingY={matches ? "40px" : "60px"}
          sx={{
            width: "100%",
            justifyContent: isTablet ? "flex-start" : "center",
            overflowX: isTablet ? "scroll" : "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            px: isMobile ? "20px" : isTablet ? "48px" : 0,
          }}
        >
          {blogOptions.map((value, index) => {
            return <KinaryTipsCard key={index} {...value} matches={matches} />;
          })}
        </Grid>
        <Link
          href="https://medium.com/@kinary"
          target="_blank"
          underline="none"
        >
          <Button variant="text" disableTouchRipple size="large">
            {tips.view_blogs}
          </Button>
        </Link>
      </Grid>
      <Grid
        container
        gap={matches ? "20px" : "16px"}
        sx={{
          paddingTop: matches ? "60px" : "35px",
          paddingBottom: matches ? "60px" : "35px",
          px: "32px",
          background: "#DBF8F4",
          // transform: "matrix(-1, 0, 0, -1, 0, 0)",
          flexDirection: "column",
        }}
      >
        <Grid
          item
          flex={1}
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            //  transform: "matrix(-1, 0, 0, -1, 0, 0)",
          }}
        >
          <Typography
            variant="h5"
            maxWidth={isMobile ? "311px" : "min(56%, 656px)"}
          >
            {parseHTML(tips.blog_msg)}
          </Typography>
        </Grid>
        {!subscribed ? (
          <Grid
            item
            flex={1}
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: "20px",
              width: "100%",
              alignItems: isMobile ? "center" : "flex-start",
              justifyContent: "center",
            }}
          >
            <Grid
              sx={{
                display: "block",
                flexDirection: "column",
                textAlign: "left",
                width: "100%",
                maxWidth: isMobile ? null : "min(43%, 400px)",
              }}
            >
              <Input
                error={!isValidEmail}
                placeholder={tips.email_id_placeholder}
                type="email"
                disableUnderline
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                sx={{
                  flex: "1",
                  margin: "0px",
                  width: "100%",
                  height: "46px",
                  color: "#383838",
                  background: "#FFFFFF",
                  minHeight: matches ? "40px" : null,
                  border: "none",
                  fontWeight: "400",
                  fontSize: isMobile ? "16px !important" : "18px !important",
                  lineHeight: isMobile ? "22px !important" : "24px !important",
                  letterSpacing: isMobile
                    ? "0.032px !important"
                    : "0.036px !important",
                  "& ::placeholder": {
                    fontWeight: 400,
                    fontSize: isMobile ? "16px !important" : "18px !important",
                    lineHeight: isMobile
                      ? "22px !important"
                      : "24px !important",
                    letterSpacing: isMobile
                      ? "0.032px !important"
                      : "0.036px !important",
                    color: "#8D8D8D !important",
                  },
                }}
              />
              {!isValidEmail && (
                <Typography color={"#D00000"} marginTop={"8px"} variant="body2">
                  {errorMessage}
                </Typography>
              )}
            </Grid>

            <Button
              disableTouchRipple
              variant="contained"
              onClick={onSubscribeHandle}
            >
              {isLoading ? (
                <CircularProgress size={30} color="inherit" />
              ) : (
                common.subscribe
              )}
            </Button>
          </Grid>
        ) : (
          <Grid
            margin={"0 auto"}
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "center" : "flex-start",
            }}
          >
            <Grid
              width={"24px"}
              height={"24px"}
              sx={{
                marginBottom: isMobile ? "11px" : "0",
                marginRight: isMobile ? "0" : "8px",
              }}
            >
              <img
                width={"100%"}
                height={"auto"}
                loading="lazy"
                src={orangeTick}
                alt={"orangeTick"}
              />
            </Grid>
            <Grid>
              <Typography variant="body2">{tips.success_subscribe}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
