import {
  Paper,
  Grid,
  Typography,
  useMediaQuery,
  Link,
  useTheme,
} from "@mui/material";
import arrowForward from "../../../assets/arrowForward.svg";
import allContent from "../../../content/en.json";


export default function KinaryTipsCard(props: any) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isExtraLargeDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const getDesktopWidth = () => {
    if (isExtraLargeDesktop) {
      return "465px"
    }
    if (isLargeDesktop) {
      return "386px"
    }
    if (isDesktop) {
      return "328px"
    }
    if (isTablet) {
      return "326px"
    }
    if (isMobile) {
      return "335px"
    }
  }
  return (
    <Link rel="noreferrer" href={props.link} target="_blank"  display="flex" alignItems="center" justifyContent="center" underline="none">
      <Grid height={"100%"} width={"100%"}>
        <Paper
          elevation={0}
          sx={{
            background: "#FFFFFF",
            boxShadow: "0px 4px 64px 0px rgba(0, 0, 0, 0.06)",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            maxWidth: isMobile? "100%" : getDesktopWidth(),
            width: "max-content",
            "& a > h6": {
              fontFamily: "Source Sans Pro !important",
            },
            "&:hover .read-arrow": !props.matches ? {
              animation: "moveLeftRight 1.5s linear forwards",
              "@keyframes moveLeftRight": {
                "0%, 100%": {
                  transform: "translateX(0)"
                },
                "50%": {
                  transform: "translateX(20px)"
                }
              }
            } : null,
            "&:hover .banner-image": !props.matches
              ? {
                  transform: "scale(1.1)",
                  transformOrigin: "50% 50%",
                     borderRadius: "8px 8px 0px 0px",
                }
              : null,
          }}
        >
         <Grid sx={{ display: "inline-block", overflow: "hidden",  borderRadius: "16px 16px 0px 0px" }}>
            <img
              src={props.blogImg}
              style={{
                width: "100%",
                height: "auto",
                display: "block",
                transition: "transform 1s",
              }}
              className={"banner-image"}
              alt="Banner"
            />
          </Grid>
          <Grid
            sx={{
              padding: props.matches ? "24px 20px" : "28px 20px 40px",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: isMobile ? "center" : "flex-start",
              height: "auto",
              width: "100%",
              flexGrow: "1",
            }}
          >
            <Grid height="100%" display="flex" flexDirection="column">
              <Typography
                variant="h5"
                sx={{
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {props.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#383838",
                  paddingTop: props.matches ? "12px" : "14px",
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {props.body}
              </Typography>
            </Grid>
            <Grid
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                color: "#F25832",
                paddingTop: props.matches ? "32px" : "42px",
                marginTop: "auto",
              }}
            >
              <Typography variant="subtitle1" color={"#F25832"}>
                {allContent.home.tips.card.read}
              </Typography>
              <Grid className={"read-arrow"} marginTop={isMobile ? "1px" : "3px"}>
                <img
                  width={"100%"}
                  height={"auto"}
                  loading="lazy"
                  src={arrowForward}
                  alt={"arrowForward"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Link>
  );
}
