import KinaryFooter from "../KinaryFooter";
import Layout from "../layout";
import { Grid } from "@mui/material";

interface IFCWProofProps {
  matches: boolean;
}


function FCWProof(props: IFCWProofProps) {
  const { matches } = props;
  return (
    <Layout  matches={matches}>
      <Grid>
      <iframe className="airtable-embed" title="fcwProof" src='https://airtable.com/embed/shrS7dEn2R0lm6hSS?backgroundColor=red frameborder="0" onmousewheel=""' width="100%" height="533" style={{background: "transparent", border: "1px solid #ccc"}}></iframe>
      </Grid>
      <KinaryFooter {...{ matches }} />
    </Layout>
  );
}

export default FCWProof;
