import {
  Button,
  Grid,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CalGrowsBadge from "../../../assets/CalGrows_Badge.webp";
import CloseIcon from "@mui/icons-material/Close";
import allContent from "../../../content/en.json";
import { useNavigate } from "react-router";
import { parseHTML } from "../../../service/utils";

const {
  home: { CaregiverTrainingPrograms },
} = allContent;

export default function CaregiverTrainingModal(props: {
  matches: boolean;
  isCaregiveModalOpen: boolean;
  setIsCaregiverModalOpen: (arg0: boolean) => void;
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const { matches, isCaregiveModalOpen, setIsCaregiverModalOpen } = props;

  return (
    <Modal
      sx={{ overflow: "scroll" }}
      open={isCaregiveModalOpen}
      onClose={() => setIsCaregiverModalOpen(false)}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            boxSizing: "border-box",
            borderRadius: matches ? "0" : "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "max-content",
            justifyContent: "center",
            padding:
              matches && !isTablet
                ? "24px 32px 40px"
                : isDesktop
                ? "44px 40px 28px 40px"
                : "44px 40px 28px 60px",
            marginTop: matches ? "0" : "110px",
            position: "relative",
            gap: matches ? (isMobile ? "32px" : "40px") : "8px",
            maxWidth: matches ? "100%" : isDesktop ? "676px" : "954px",
          }}
        >
          <Grid
            sx={{
              width: "100%",
              textAlign: "right",
              zIndex: "99",
              color: "#1F1F1F",
            }}
          >
            <CloseIcon
              onClick={() => props.setIsCaregiverModalOpen(false)}
              sx={{
                cursor: "pointer",
                width: isDesktop || isMobile ? "24px" : "32px",
                height: isDesktop || isMobile ? "24px" : "32px",
                "&:hover": { color: "#606060" },
                "&:active": { color: "#606060" },
              }}
            />
          </Grid>
          <Grid
            gap={matches ? "0" : isDesktop ? "32px" : "48px"}
            sx={{
              display: "flex",
              justifyContent: matches ? "center" : "flex-start",
              alignItems: matches ? "center" : "flex-start",
              flexDirection: matches ? "column" : "row",
              textAlign: matches ? "center" : "left",
              width: isTablet ? "66%" : "100%",
            }}
          >
            <Grid
              sx={{
                width: isMobile
                  ? "124px"
                  : isDesktop || isTablet
                  ? "169px"
                  : "254px",
                height: isMobile
                  ? "105px"
                  : isDesktop || isTablet
                  ? "143px"
                  : "213px",
              }}
            >
              <img
                width={"100%"}
                height={"auto"}
                src={CalGrowsBadge}
                loading="lazy"
                alt={CaregiverTrainingPrograms.altImage}
              />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                width: matches ? "100%" : isDesktop ? "67%" : "55%",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  paddingTop: matches ? (isTablet ? "32px" : "28px") : "0",
                  paddingBottom: "12px",
                }}
              >
                {parseHTML(CaregiverTrainingPrograms.registrationMessage)}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  paddingBottom: matches ? "80px" : "60px",
                  color: "#606060",
                }}
              >
                {CaregiverTrainingPrograms.registrationSubText}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  px: isMobile ? "32px" : "0",
                  paddingBottom: matches ? "16px" : "16px",
                }}
              >
                {CaregiverTrainingPrograms.selectCaregiverOption}
              </Typography>
              <Grid sx={{ width: matches ? "auto" : "100%" }}>
                <Button
                  variant="text"
                  size="large"
                  disableTouchRipple
                  onClick={() => navigate("/fcw_training")}
                  sx={{
                    padding: "16px 28px 16px 28px",
                    width: isTablet ? "80%" : "100%",
                    height: "60px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant={matches || isDesktop ? "body2" : "body1"}
                    sx={{
                      lineHeight: matches ? "24px !important" : "28px",
                      fontSize: matches ? "17px !important" : "24px",
                      fontWeight: "400",
                      color: "#1F1F1F",
                      letterSpacing: matches ? "0.036px !important" : "0.48px",
                      "&:hover": {
                        color: "#F25832",
                        boxShadow: "none",
                      },
                    }}
                  >
                    {CaregiverTrainingPrograms.familyCaregiver}
                  </Typography>
                </Button>
                <Button
                  disableTouchRipple
                  variant="text"
                  size="large"
                  onClick={() => navigate("/dcw_training")}
                  sx={{
                    padding: "16px 28px 16px 28px",
                    width: isTablet ? "80%" : "100%",
                    height: "60px",
                    marginTop: "12px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant={matches || isDesktop ? "body2" : "body1"}
                    sx={{
                      lineHeight: matches ? "24px !important" : "28px",
                      fontSize: matches ? "17px !important" : "24px",
                      fontWeight: "400",
                      color: "#1F1F1F",
                      letterSpacing: matches ? "0.036px !important" : "0.48px",
                      "&:hover": {
                        color: "#F25832",
                        boxShadow: "none",
                      },
                    }}
                  >
                    {CaregiverTrainingPrograms.workCaregiver}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Typography
              variant="subtitle1"
              sx={{
                paddingTop: matches ? "160px" : isDesktop ? "52px" : "72px",
                color: "#606060",
                textAlign: "center",
              }}
            >
              {CaregiverTrainingPrograms.sponsoredByMessage}
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    </Modal>
  );
}
