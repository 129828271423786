import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import CalGrowsBadge from "../../../assets/CalGrows_Badge.webp";
import inPerson from "../../../assets/inPerson.svg";
import careerCoaching from "../../../assets/careerCoaching.svg";
import payment from "../../../assets/payment.svg";
import onlineTraining from "../../../assets/onlineTraining.svg";
import { parseHTML } from "../../../service/utils";

interface ICaregiverCalgrowsProps {
  matches: boolean;
  content: any;
}

function CaregiverCalgrows(props: ICaregiverCalgrowsProps) {
  const { matches, content } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const { CalgrowsInnovation } = content;
  const gridImages: Record<string, string> = {
    inPerson: inPerson,
    onlineTraining: onlineTraining,
    payment: payment,
    careerCoaching: careerCoaching,
  };
  return (
    <Grid
      sx={{
        paddingX: matches ? (isMobile ? "32px" : "69px") : "0",
        paddingBottom: matches || isTablet ? "80px" : "120px",
        display: "flex",
        alignItems: "center",
        paddingTop: isMobile
          ? "92px"
          : isDesktop || isTablet
          ? "80px"
          : "120px",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: isDesktop ? "734px" : matches ? "100%" : "966px",
        }}
      >
        <Grid
          sx={{
            width: matches ? "154px" : "223px",
            height: matches ? "130px" : "188px",
            marginBottom: "60px",
          }}
        >
          <img
            width={"100%"}
            height={"auto"}
            loading="lazy"
            src={CalGrowsBadge}
            alt={CalgrowsInnovation.altImage}
          />
        </Grid>
        <Typography
          variant="h2"
          marginBottom={
            isMobile ? "28px" : isDesktop || isTablet ? "40px" : "60px"
          }
          sx={{
            display: isMobile ? "block" : "flex",
            flexDirection: "column",
            width: "80%",
          }}
        >
          {parseHTML(CalgrowsInnovation.title)}
        </Typography>
        <Typography
          variant="body1"
          marginBottom={
            isMobile ? "40px" : isDesktop || isTablet ? "60px" : "80px"
          }
          sx={{
            color: "#1F1F1F",
            textAlign: "center",
            width: matches ? "auto" : "85%",
          }}
        >
          {parseHTML(CalgrowsInnovation.description)}
        </Typography>
        <Grid
          gap={matches ? "80px" : (isDesktop ? "60px" : "120px")}
          sx={{
            width: isTablet ? "75%" : "100%",
            display: "flex", 
            justifyContent: "center",
            flexWrap: matches ? "wrap" : "nowrap",
          }}
        >
          {CalgrowsInnovation.grids.map((gridItem: any, index: any) => (
            <Grid
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: matches ? "center" : "space-between",
                flexDirection: "column",
              }}
            >
              <Grid
                sx={{
                  width: "109px",
                  height: "109px",
                  marginBottom: "16px",
                }}
              >
                <img
                  width={"100%"}
                  height={"auto"}
                  loading="lazy"
                  src={gridImages[gridItem.imgSrc]}
                  alt={gridItem.imgAlt}
                />
              </Grid>

              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  width: isMobile ? "100%" : gridItem.width
                }}
              >
                {gridItem.message}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CaregiverCalgrows;
