import { Grid } from "@mui/material";
import KinaryFooter from "../KinaryFooter";
import TrainingBannerSection from "../TrainingBannerSection";
import allContent from "../../content/en.json";
import TrainingCaregiver from "../TrainingCaregiver";
import DirectCareWorkersIcon1 from "../../assets/DirectCareWorkersIcon1.svg";
import DirectCareWorkersIcon2 from "../../assets/DirectCareWorkersIcon2.svg";
import FamilycaregiversIcon1 from "../../assets/FamilycaregiversIcon1.svg";
import FamilycaregiversIcon2 from "../../assets/FamilycaregiversIcon2.svg";
import TrainingLoginMobile from "../TrainingLoginMobile";
import Header from "../Header";

interface ITrainingProgramProps {
  matches: boolean;
}

const {
  training_program: { directCareWorkers, familyCaregivers },
} = allContent;

function TrainingProgram(props: ITrainingProgramProps) {
  const { matches } = props;
  return (
    <>
    <Grid sx={{
      position: matches ? "fixed" : "static",
      width: "100%",
    }}>
      <Header matches={matches} />
      {matches && <TrainingLoginMobile page={"training-program"} />}
    </Grid>
      <Grid
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingTop={matches ? "110px" : "0"}
      >
        <TrainingBannerSection {...props} />
        <TrainingCaregiver matches={matches} content={directCareWorkers} color={"#FFF5EE"} images={{section1Url: DirectCareWorkersIcon1, section2Url: DirectCareWorkersIcon2}} />
        <iframe className="airtable-embed" title="direct-care" src='https://airtable.com/embed/shrfxhgQuT56E16uj?backgroundColor=blue&viewControls=on frameborder="0" onmousewheel=""' width="100%" height="533" style={{background: "transparent", border: "1px solid #ccc"}}></iframe>
        <TrainingCaregiver matches={matches} content={familyCaregivers} color={"#E0FAF5"} images={{section1Url: FamilycaregiversIcon1, section2Url: FamilycaregiversIcon2}}/>
        <iframe className="airtable-embed" title="family-care" src='https://airtable.com/embed/shrZ6irPmfO3WL7vS?backgroundColor=blue frameborder="0" onmousewheel=""' width="100%" height="533" style={{background: "transparent", border: "1px solid #ccc"}}></iframe>
      </Grid>
      <KinaryFooter {...{ matches }} />
    </>
  );
}

export default TrainingProgram;
