import React, { useEffect, useState } from "react";
import "./App.css";
import Home from "./component/Home";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { Alert, createTheme, useMediaQuery } from "@mui/material";
import Download from "./component/Download";
import StaticContent from "./component/StaticContent";
import {
  cookiePolicyOptions,
  privacyPolicyOptions,
  termsOptions,
} from "./content/options";
import FAQ from "./component/FAQ";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteUserAccount from "./component/DeleteUserAccount";
import TrainingProgram from "./component/TrainingProgram";
import TrainingLogin from "./component/TrainingLogin";
import DCWProof from "./component/DCWProof";
import FCWProof from "./component/FCWProof";
import PaidCareGiver from "./component/Caregiver/PaidCareGiver";
import FamilyCareGiver from "./component/Caregiver/FamilyCareGiver";
import { initGA } from "./utility/analytics";
import WrapperRoute from "./component/WrappedRoute";
import allContent from "./content/en.json";
import Support from "./component/Support";
import { clarity } from 'react-microsoft-clarity';
const { pageTitle, home } = allContent;



function App() {
  const matches = useMediaQuery("(max-width: 1023px)");
  const headingStyles = {
    fontFamily: "Lora",
    color: "#1F1F1F",
    fontWeight: "500",
  };

  const bodyStyles = {
    fontFamily: "Source Sans Pro",
    color: "#1F1F1F",
    fontWeight: "400",
  };
  const theme = createTheme({
    spacing: 10,
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1200,
        xl: 1920,
      },
    },
    typography: {
      caption: {
        fontFamily: "Source Sans Pro",
        color: "#5E5E5E",
      },
      button: {
        fontFamily: "Source Sans Pro",
      },
    },
    palette: {
      primary: { main: "#F25832", dark: "#D24624" },
      secondary: { main: "#08622C", dark: "#083B33" },
      text: {
        primary: "#1F1F1F",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => {
            return {
              ...getButtonStyles(ownerState),
            };
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            fontFamily: "Source Sans Pro",
            fontWeight: 400,
            border: "1px solid #C0C0C0",
            padding: "16px 20px !important",
            borderRadius: "8px",
            background: "#FFFFFF",
            marginBottom: "0",
            width: "100%",
            height: "60px",
            color: "#1F1F1F",
            "&.Mui-error": {
              border: "1px solid #D00000",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "&.MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
              outline: "none",
            },
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          ...{'root': {
            width: "100%",
            height: "60px",
          }},
          select: {
            fontFamily: "Source Sans Pro",
            fontWeight: 400,
            padding: "16px 20px !important",
            borderRadius: "8px",
            background: "#FFFFFF",
            marginBottom: "0",
            width: "100%",
            color: "#1F1F1F",
            "&.Mui-error": {
              border: "1px solid #D00000",
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: ({ ownerState }) => {
            return {
              color: "white",
              textDecorationColor: "currentcolor",
              textUnderlineOffset: "4px",
              textDecoration: "none",
              "& :hover, :active": {
                textDecoration:
                  ownerState.underline === "none" ? "none" : "underline",
              },
            };
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            backgroundColor: "white",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: "Source Sans Pro",
            fontSize: "16px",
            lineHeight: "20px",
            "&:hover": {
              background: "#FAFAFA",
            },
            "&:focus-visible": {
              background: "#FAFAFA",
            },
            "&.Mui-selected": {
              background: "#FAFAFA",
              "&:hover": {
                background: "#FAFAFA",
              },
              "&:focus-visible": {
                background: "#FAFAFA",
              },
            }
          },
        },
      },
    },
  });

  theme.typography.h1 = {
    ...headingStyles,
    [theme.breakpoints.up("xs")]: {
      fontSize: "32px",
      lineHeight: "38px",
      letterSpacing: "0.16px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "42px",
      lineHeight: "48px",
      letterSpacing: "0.21px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "64px",
      lineHeight: "72px",
      letterSpacing: "0.32px",
    },
  };

  theme.typography.h2 = {
    ...headingStyles,
    [theme.breakpoints.up("xs")]: {
      fontSize: "28px",
      lineHeight: "34px",
      letterSpacing: "0.14px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "32px",
      lineHeight: "38px",
      letterSpacing: "0.16px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "48px",
      lineHeight: "55px",
      letterSpacing: "0.24px",
    },
  };

  theme.typography.h3 = {
    ...headingStyles,
    [theme.breakpoints.up("xs")]: {
      fontSize: "24px",
      lineHeight: "32px",
      letterSpacing: "0.12px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "26px",
      lineHeight: "34px",
      letterSpacing: "0.13px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "32px",
      lineHeight: "40px",
      letterSpacing: "0.16px",
    },
  };

  theme.typography.h4 = {
    ...headingStyles,
    [theme.breakpoints.up("xs")]: {
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.1px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "22px",
      lineHeight: "28px",
      letterSpacing: "0.1px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "24px",
      lineHeight: "32px",
      letterSpacing: "0.12px",
    },
  };

  theme.typography.h5 = {
    ...headingStyles,
    [theme.breakpoints.up("xs")]: {
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.09px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.1px",
    },
  };

  theme.typography.h6 = {
    ...headingStyles,
    [theme.breakpoints.up("xs")]: {
      fontSize: "16px",
      lineHeight: "22px",
      letterSpacing: "0.08px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.09px",
    },
  };

  theme.typography.body1 = {
    ...bodyStyles,
    [theme.breakpoints.up("xs")]: {
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.036px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.04px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "24px",
      lineHeight: "34px",
      letterSpacing: "0.048px",
    },
  };

  theme.typography.body2 = {
    ...bodyStyles,
    [theme.breakpoints.up("xs")]: {
      fontSize: "16px",
      lineHeight: "22px",
      letterSpacing: "0.032px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.036px",
    },
  };
  theme.typography.subtitle1 = {
    ...theme.typography.body2,
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.028px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
      lineHeight: "22px",
      letterSpacing: "0.032px",
    },
  };

  const getButtonStyles = (ownerState: any) => {
    let commonStyles = {} as any;
    const { variant, size } = ownerState;

    commonStyles = {
      textAlign: "center",
      textTransform: "none",
      borderRadius: "8px",
      fontWeight: "400",
      fontFamily: "Source Sans Pro",
      boxShadow: "none",
      minWidth: "135px",
    };

    if (variant === "contained") {
      return {
        ...commonStyles,
        "&:disabled": {
          background: "#D8D8D8",
          color: "#fff",
        },
        "&:hover": {
          backgroundColor: "#D24624",
          boxShadow: "none",
        },
        "&:active": {
          backgroundColor: "#D24624",
          boxShadow: "none",
        },
        ...(size === "medium"
          ? {
              lineHeight: matches ? "24px" : "26px",
              fontSize: matches ? "18px" : "20px",
              padding: matches ? "10px 20px" : "10px 24px",
              height: matches ? "44px" : "46px",
              letterSpacing: matches ? "0.36px" : "0.4px",
            }
          : size === "large"
          ? {
              lineHeight: matches ? "24px" : "26px",
              fontSize: matches ? "18px" : "24px",
              padding: matches ? "10px 20px" : "16px 28px",
              height: matches ? "44px" : "60px",
              letterSpacing: matches ? "0.36px" : "0.48px",
            }
          : {}),
      };
    }

    if (variant === "text") {
      return {
        ...commonStyles,
        background: "#EDF4F4",
        color: "#1F1F1F",
        "&:disabled": {
          background: "#FFFFFF",
          color: "#D8D8D8",
          border: "1px solid #D8D8D8",
        },
        "&:hover": {
          color: "#F25832",
          boxShadow: "none",
          background: "#EDF4F4",
        },
        "&:active": {
          color: "#F25832",
          boxShadow: "none",
        },
        ...(size === "medium"
          ? {
              lineHeight: matches ? "24px" : "26px",
              fontSize: matches ? "18px" : "20px",
              padding: matches ? "10px 20px" : "10px 24px",
              height: matches ? "44px" : "46px",
              letterSpacing: matches ? "0.36px" : "0.4px",
            }
          : size === "large"
          ? {
              lineHeight: matches ? "24px" : "26px",
              fontSize: matches ? "18px" : "24px",
              padding: matches ? "10px 20px" : "16px 28px",
              height: matches ? "44px" : "60px",
              letterSpacing: matches ? "0.36px" : "0.48px",
            }
          : {}),
      };
    }

    return commonStyles;
  };

  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    initGA(); // Initialize Google Analytics
    clarity.init(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ?? 'lr2k6ks3mh');
    clarity.consent();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {openAlert && (
          <Alert
            icon={<CheckCircleIcon color="success" />}
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              backgroundColor: "#fff",
              zIndex: 1,
            }}
            onClose={() => setOpenAlert(false)}
          >
            {home.toastMessage}
          </Alert>
        )}
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <WrapperRoute title={pageTitle.home}>
                  <Home matches={matches} />
                </WrapperRoute>
              }
            />
            <Route
              path="/download"
              element={
                <WrapperRoute title={pageTitle.download}>
                  <Download matches={matches} />
                </WrapperRoute>
              }
            />
            <Route
              path="/cookie-policy"
              element={
                <WrapperRoute title={pageTitle.cookiePolicy}>
                  <StaticContent {...cookiePolicyOptions} />
                </WrapperRoute>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <WrapperRoute title={pageTitle.privacyPolicy}>
                  <StaticContent {...privacyPolicyOptions} />
                </WrapperRoute>
              }
            />
            <Route
              path="/terms"
              element={
                <WrapperRoute title={pageTitle.terms}>
                  <StaticContent {...termsOptions} />
                </WrapperRoute>
              }
            />
            <Route
              path="/faq"
              element={
                <WrapperRoute title={pageTitle.faq}>
                  <FAQ matches={matches} />
                </WrapperRoute>
              }
            />
            <Route
              path="/deleteuseraccount"
              element={
                <WrapperRoute title={pageTitle.deleteUserAccount}>
                  <DeleteUserAccount
                    setOpenAlert={setOpenAlert}
                    matches={matches}
                  />
                </WrapperRoute>
              }
            />
            <Route
              path="/training-program"
              element={
                <WrapperRoute title={pageTitle.trainingProgram}>
                  <TrainingProgram matches={matches} />
                </WrapperRoute>
              }
            />
            <Route
              path="/training-login"
              element={
                <WrapperRoute title={pageTitle.trainingLogin}>
                  <TrainingLogin matches={matches} />
                </WrapperRoute>
              }
            />
            <Route
              path="/dcwproof"
              element={
                <WrapperRoute title={pageTitle.dcwProof}>
                  <DCWProof matches={matches} />
                </WrapperRoute>
              }
            />
            <Route
              path="/fcwproof"
              element={
                <WrapperRoute title={pageTitle.fcwProof}>
                  <FCWProof matches={matches} />
                </WrapperRoute>
              }
            />
            <Route
              path="/dcw_training"
              element={
                <WrapperRoute title={pageTitle.dcwTraining}>
                  <PaidCareGiver matches={matches} />
                </WrapperRoute>
              }
            />
            <Route
              path="/fcw_training"
              element={
                <WrapperRoute title={pageTitle.fcwTraining}>
                  <FamilyCareGiver matches={matches} />
                </WrapperRoute>
              }
            />
            <Route
              path="/support"
              element={
                <WrapperRoute title={pageTitle.support}>
                  <Support matches={matches} />
                </WrapperRoute>
              }
            />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
