import { Grid, Typography, Button, Divider, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import seatsIcon from "../../../assets/seats-icon.svg";
import upArrow from "../../../assets/up-arrow.svg";
import downArrow from "../../../assets/down-arrow.svg";
import orangeTick from "../../../assets/orange-tick.svg";
import selfPacedOnline from "../../../assets/selfPacedOnline.svg";

interface ICoursesAirtableCard {
  matches: boolean;
  item: any;
  index: number;
  courses: any;
  isMobile: any;
  isDesktop: any;
  content: any;
}

function CoursesAirtableCard(props: ICoursesAirtableCard) {
  const { matches, item, index, courses, isMobile, isDesktop, content } = props;
  const [viewEligibility, setViewEligibility] = useState(false);
  const [dateTime, setDateTime] = useState(null as any);
  const [coursesDesc, coursesList] = item["Description"].split(":");
  const [desc, listTitle] = coursesDesc.split(".");

  const courseDelivery = (item: string) => {
    if (item === "Self Paced Online") {
      return {
        bg: "#F2F7FC",
        border: "#CCDFF1"
      }
    } else if (item === "Live Virtual") {
      return {
        bg: "#FEF2F0",
        border: "#FDE6E0"
      }
    } else if (item === "Live In Person") {
      return {
        bg: "#F4F1FF",
        border: "#E8E3FC"
      }
    }
    return {
        bg: "#E8FBF7",
        border: "#C7E5E0"
      }
  }

  useEffect(() => {
    setDateTime(formatDate(item["Start date"]));
    // eslint-disable-next-line
  }, []);

  const formatDate = (date: string): { date: string; time: string } => {
    const formattedDate = new Date(date);
    const time = formattedDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const d = formattedDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return { date: d, time };
  };

  return (
    <Grid
      key={`${item["Name"]}_${index}`}
      sx={{
        display: "flex",
        background: "#FFFFFF",
        flexDirection: "column",
        borderRadius: "16px",
        boxShadow: "0px 6px 60px 0px rgba(0, 0, 0, 0.06)",
        marginTop: matches ? "24px" : "28px",
        width: matches ? "100%" : "80%",
        maxWidth: "1274px",
        padding: matches ? (isMobile ? "32px" : "40px") : "40px 52px 40px 52px",
      }}
    >
      <Grid display={"flex"} gap={"8px"} flexDirection={isMobile ? "column" : "row"}>
        {item["Status"] && (
          <Grid
            sx={{
              background: "#E8FBF7",
              border: "1px solid #C7E5E0",
              padding: matches ? "4px 12px" : "6px 20px",
              borderRadius: "100px",
              alignSelf: "flex-start"
            }}
          >
            <Typography variant="subtitle1" color={"#1F1F1F"}>
              {item["Status"]}
            </Typography>
          </Grid>
        )}
        {item["Course Delivery"] && (
          <Grid
            sx={{
              background: courseDelivery(item["Course Delivery"]).bg,
              border: `1px solid ${courseDelivery(item["Course Delivery"]).border}`,
              padding: matches ? "4px 12px" : "6px 20px",
              borderRadius: "100px",
              alignSelf: "flex-start"
            }}
          >
            <Typography variant="subtitle1" color={"#1F1F1F"}>
              {item["Course Delivery"]}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        paddingTop={"20px"}
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={isMobile ? "column" : "row"}
        gap={isMobile ? "28px" : "0"}
      >
        <Grid display={"flex"} flexDirection={"column"} width={"100%"}>
          <Typography variant="h4" sx={{ color: "#1F1F1F", textAlign: "left" }}>
            {item["Name"]}
          </Typography>
          <Grid display={"flex"} sx={{ marginTop: "20px" }}>
            {item["Course Delivery"] === "Self Paced Online" ? (
              <Grid display={"flex"} gap={"8px"}>
                <Grid
                  sx={{
                    width: isMobile ? "12px" : "16px",
                    height: isMobile ? "13.5px" : "18px",
                    paddingTop: "3px",
                  }}
                >
                  <img
                    width={"100%"}
                    height={"auto"}
                    loading="lazy"
                    src={selfPacedOnline}
                    alt={"Self Paced Online"}
                  />
                </Grid>
                {(
                  <Typography
                    variant="body2"
                    sx={{ color: "#1F1F1F", textAlign: "left" }}
                  >
                    {content.selfPacedOnline}
                  </Typography>
                )}
              </Grid>
            ) : (
              <Grid
                display={"flex"}
                flexDirection={matches ? "column" : "row"}
                gap={matches ? "8px" : "28px"}
              >
                {dateTime && (
                  <Grid display={"flex"} gap={"8px"}>
                    <Grid
                      sx={{
                        width: isMobile ? "12px" : "16px",
                        height: isMobile ? "13.5px" : "18px",
                        paddingTop: "3px",
                      }}
                    >
                      <img
                        width={"100%"}
                        height={"auto"}
                        loading="lazy"
                        src={selfPacedOnline}
                        alt={"date-icon"}
                      />
                    </Grid>
                    <Typography variant="body2" color="#1F1F1F">
                      {`${dateTime.date} at ${dateTime.time}`}
                    </Typography>
                  </Grid>
                )}
                <Grid display={"flex"} gap={"8px"}>
                  <Grid
                    sx={{
                      width: isMobile ? "15px" : "20px",
                      height: isMobile ? "13.5px" : "18px",
                      paddingTop: "3px",
                    }}
                  >
                    <img
                      width={"100%"}
                      height={"auto"}
                      loading="lazy"
                      src={seatsIcon}
                      alt={"Seats Available"}
                    />
                  </Grid>
                  <Typography variant="body2" color="#383838">
                    {courses.seatsAvailable}: {item["Seats Available"]}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Link
          to={item["Register"].url}
          underline="none"
          target="_blank"
          component={RouterLink}
        >
          <Button
            sx={{ width: isMobile ? "100%" : "auto" }}
            disableTouchRipple
            variant="contained"
            size="medium"
          >
            {item["Register"].label}
          </Button>
        </Link>
      </Grid>
      {!isMobile && (
        <Divider
          variant="fullWidth"
          sx={{
            borderWidth: "1px",
            borderColor: "ECECEC",
            margin: "32px 0",
          }}
        />
      )}
      <Grid sx={{ marginTop: isMobile ? "32px" : "0" }}>
        <Typography
          variant="body2"
          sx={{ color: "#383838", textAlign: "left" }}
        >
          {desc.trim()}.
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "#383838", textAlign: "left" }}
        >
          {listTitle.trim()}:
        </Typography>
        <Grid width={matches || isDesktop ? "100%" : "70%"}>
          <ul
            style={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: "45px",
              marginTop: "20px",
              marginBottom: "40px",
              paddingLeft: "20px",
              flexDirection: matches ? "column" : "row"
            }}
          >
            {coursesList?.split(",").map((course: string, index: number) => (
              <li key={`${course}_${index}`}>
                <Grid width= {matches? "100%" : "307px"}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#383838",
                      textAlign: "left",
                      marginBottom: "8px",
                    }}
                  >
                    {course}
                  </Typography>
                </Grid>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
      <Grid
        display={"flex"}
        flexDirection={"column"}
        sx={{ cursor: "pointer" }}
      >
        <Grid
          display="flex"
          onClick={() => setViewEligibility(!viewEligibility)}
          paddingBottom={"8px"}
        >
          <Typography variant="subtitle1" color={"#1F1F1F"}>
            {courses.viewEligibility}
          </Typography>
          <img
            width={matches ? "16px" : "20px"}
            height={matches ? "16px" : "20px"}
            loading="lazy"
            src={!viewEligibility ? downArrow : upArrow}
            alt={"Info-icon"}
            style={{
              paddingTop: "2px",
              marginLeft: "2px",
            }}
          />
        </Grid>
        {viewEligibility && (
          <Grid display={"flex"} flexDirection={"column"} gap={"6px"}>
            {item["Eligibility"] &&
              item["Eligibility"]
                .split("\n")
                .map((item: any, index: number) => (
                  <Grid
                    key={index}
                    display={"flex"}
                    gap={"12px"}
                    alignItems={"baseline"}
                  >
                    <img
                      width={matches ? "13px" : "16px"}
                      height={matches ? "10px" : "11px"}
                      loading="lazy"
                      src={orangeTick}
                      alt={"orange Success tick"}
                    />
                    <Typography
                      variant="subtitle1"
                      color={"#383838"}
                      sx={{ textAlign: "left" }}
                    >
                      {item}
                    </Typography>
                  </Grid>
                ))}
          </Grid>
        )}
      </Grid>
      {/* {matches && (
        <Grid sx={{ paddingTop: "30px", width: "100%" }}>
          <Link
            to={item["Register"].url}
            underline="none"
            target="_blank"
            component={RouterLink}
          >
            <Button
              disableTouchRipple
              variant="contained"
              sx={{ width: "100%" }}
              size="medium"
            >
              {item["Register"].label}
            </Button>
          </Link>
        </Grid>
      )} */}
    </Grid>
  );
}

export default CoursesAirtableCard;
