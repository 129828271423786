import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ?? 'G-4BTP1RVRKW');
};

export const trackPageView = (location: any) => {
    ReactGA.send({
        hitType: "pageview",
        page: location,
      });
};

export const logEvent = (category: any, action: any, label: any) => {
  ReactGA.event({
    category,
    action,
    label
  });
};