import { Button, Grid, Link, Typography, useTheme, useMediaQuery } from "@mui/material";
import allContent from "../../content/en.json";
import { Link as RouterLink, useNavigate } from "react-router-dom";

interface IKinaryFAQ {
  matches: boolean;
}

const {
  home: { FAQ },
  common,
} = allContent;

export default function KinaryFAQ({ matches }: IKinaryFAQ) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  return (
    <Grid
      sx={{
        padding: matches ? "80px 32px" : "120px 0px",
      }}
    >
      <Typography
        variant="h2"
      >
        {FAQ.title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          margin: !matches ? "16px auto 40px" : "16px auto 32px",
          maxWidth: !isMobile ? (isTablet || isDesktop ? "min(67%, 502px)" : "min(67%, 854px)") : null,
        }}
      >
        {FAQ.description}
      </Typography>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: matches ? "16px" : "24px",
          gap: "20px",
        }}
      >
        <Link to="/faq" underline="none" component={RouterLink}>
          <Button disableTouchRipple variant="text" size="large">{FAQ.view_faq}</Button>
        </Link>
        <Button disableTouchRipple variant="text" size="large" onClick={() => navigate("/support")}>
          {common.contact_us}
        </Button>
      </Grid>
    </Grid>
  );
}
