import { Grid, Typography, styled, useTheme, useMediaQuery } from "@mui/material";
import background from "../../assets/kinary-capability-background.svg";
import { capabilitiesData } from "../../content/options";
import allContent from "../../content/en.json";
import { parseHTML } from "../../service/utils";

interface IKinaryCapability {
  matches: boolean;
}

const {
  home: { capabilities },
} = allContent;

export default function KinaryCapability({ matches }: IKinaryCapability) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  return (
    <Grid
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={isMobile ? "58px" : "80px"}
      sx={{
        display: "flex",
        py: matches ? "80px" : "120px",
        px: isMobile ? "32px" : "0",
        backgroundImage: `url(${background})`,
        backgroundAttachment: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        "& h5": {
          fontWeight: 400,
          lineHeight: "28px",
        },
      }}
    >
      <Grid>
        <Typography
          variant="h2"
          marginBottom={matches ? "20px" : "28px"}
        >
          {parseHTML(capabilities.title)}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            maxWidth: matches ? "clamp(335px, 72%, 100%)" : (isDesktop ? "clamp(335px, 65%, 100%)" : "max(50%, 960px)"),
            margin: "auto",
          }}
        >
          {capabilities.description}
        </Typography>
      </Grid>
      <Grid
        flexDirection={!isMobile ? "row" : "column"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          maxWidth: isTablet || isDesktop ? "990px" : "1030px",
          display: "flex",
          rowGap: isMobile ? "48px" : (isTablet ? "60px" : "55px"),
          columnGap: isMobile ? "50px" : (isTablet ? "60px" : "80px"),
          flexWrap: "wrap"
        }}
      >
        {capabilitiesData.map((capability) => (
          <Capability
            matches={matches}
            {...capability}
            key={capability.heading}
            isTablet={isTablet}
            isMobile={isMobile}
          />
        ))}
      </Grid>
    </Grid>
  );
}

const IconContainer = styled("div")(() => ({
  height: "110px",
  width: "110px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "whitesmoke",
  borderRadius: "50%",
  marginBottom: "1rem",
}));

export interface ICapabilityProps {
  heading: string;
  description: string;
  iconPath: string;
  iconAlt: string;
  matches: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
}

const Capability = ({
  heading,
  description,
  iconPath,
  iconAlt,
  matches,
  isMobile,
  isTablet
}: ICapabilityProps) => {
  return (
    <Grid
      item
      sx={{
        maxWidth: isMobile ? "100%" : (isTablet ? "243px" : "279px"),
      }}
    >
      <Grid container flexDirection="column" alignItems="center">
        <Grid item xs={12}>
          <IconContainer>
            <img src={iconPath} alt={iconAlt} />
          </IconContainer>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h4">
            {heading}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#383838",
              marginTop: matches ? "12px" : "16px",
            }}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
