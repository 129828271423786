import { useMediaQuery, useTheme, Grid, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Define custom styles using makeStyles
const useStyles = makeStyles({
  inputFieldLabel: {
    color: "#1F1F1F",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.036px",
  },
  errorText: {
    color: "#D00000",
  },
});

interface IKinaryTextAreaProps {
  inputContent: any;
  fieldName: string;
  handleChange: (event: React.ChangeEvent<any>) => void;
  value: string;
  hasError: boolean;
  errorMessage: string | undefined;
}

export const KinaryTextArea = ({
  inputContent,
  handleChange,
  value,
  hasError,
  errorMessage,
  fieldName,
}: IKinaryTextAreaProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const classes = useStyles();

  return (
    <Grid>
      <Grid className={classes.inputFieldLabel} paddingBottom={"8px"}>
        {inputContent.label}
      </Grid>
      <TextField
        size="small"
        placeholder={inputContent.placeholder}
        multiline
        name={fieldName}
        className="textarea-root"
        onChange={handleChange}
        value={value}
        rows={3.5}
        error={hasError}
        sx={{
          background: "#FFF",
          width: "100%",
          border: hasError ? "none" : "1px solid #C0C0C0",
          borderRadius: "8px",
          color: "#1F1F1F",
          boxShadow: "none",
          "& fieldset": { border: 'none !important' },
          ".MuiOutlinedInput-root": {
            padding: "16px 20px"
          },
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
            outline: "none",
          },
          "& .Mui-error": {
            border: "1px solid #D00000 !important",
          },
          "&:focus-within": {
            outline: "1px solid #8D8D8D !important",
          },

          textarea: {
            fontFamily: "Source Sans Pro",
            fontSize: isMobile ? "16px !important" : "18px !important",
            lineHeight: isMobile ? "22px !important" : "24px !important",
            fontWeight: "400",
            letterSpacing: isMobile
              ? "0.032px !important"
              : "0.036px !important",
          },
          "& ::placeholder": {
            color: "#8D8D8D !important",
            fontFamily: "Source Sans Pro",
            fontSize: isMobile ? "16px !important" : "18px !important",
            lineHeight: isMobile ? "22px !important" : "24px !important",
            fontWeight: 400,
            letterSpacing: isMobile
              ? "0.032px !important"
              : "0.036px !important",
          },
        }}
      />
      {hasError ? (
        <Grid
          paddingTop={"8px"}
          className={`${classes.inputFieldLabel} ${classes.errorText}`}
        >
          {errorMessage}
        </Grid>
      ) : null}
    </Grid>
  );
};
