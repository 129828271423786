import { useEffect } from 'react';
import {useLocation } from 'react-router-dom';
import { trackPageView } from '../../utility/analytics';
import useDocumentTitle from '../../hooks/useDocumentTitle';


function WrapperRoute ({ children, title }: any) {
  const location = useLocation();
  useDocumentTitle(title);
  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return children;
};

export default WrapperRoute;

