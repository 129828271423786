import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import Free_Training_desktop from "../../../assets/FreeTrainingDesktop.svg";
import Financial_Incentives_desktop from "../../../assets/FinancialIncentivesDesktop.svg";
import OnlineCommunity_desktop from "../../../assets/Online_community_lg.svg";
import OnlineCommunity from "../../../assets/Online_community_sm.svg";
import digital_tools_desktop from "../../../assets/DigitalToolsDesktop.svg";
import { parseHTML } from "../../../service/utils";

interface ICaregiverKeyBenefitsProps {
  matches: boolean;
  content: any;
  page: string;
}

function CaregiverKeyBenefits(props: ICaregiverKeyBenefitsProps) {
  const { matches, content, page } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isExtraLargeDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const { keyBenefits } = content;
  const getPadding = () => {
    if (isDesktop) return "87px";
    if (isTablet) return "48px";
    if (isMobile) return "20px";
    return "0";
  };
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: matches || isTablet ? "80px" : "120px",
        paddingBottom: matches ? "60px" : "120px",
        px: getPadding(),
      }}
    >
      <Typography
        variant="h2"
        sx={{
          width: isTablet ? "70%" : "100%",
          marginBottom: isMobile
            ? "32px"
            : isDesktop || isTablet
            ? "40px"
            : "60px",
        }}
      >
        {parseHTML(keyBenefits.title)}
      </Typography>

      <Grid                           
        sx={{
          display: "flex",
          flexDirection: matches ? "row" : "column",
          alignItems: "center",
          maxWidth: matches || isDesktop ? "100%" : isExtraLargeDesktop ? "1274px" : "80%",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: matches ? "column" : "row",
            justifyContent: "space-between",
            flexWrap: matches ? "nowrap" : "wrap",
            gap: "20px",
          }}
        >
          <Grid
            gap={
              matches || isDesktop
                ? "35px"
                : isExtraLargeDesktop
                ? "81px"
                : "104px"
            }
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "flex-start",
              background: "#E0FAF5",
              objectFit: "cover",
              backgroundRepeat: "round",
              borderRadius: "16px",
              padding: matches
                ? isTablet ? "40px 40px 40px 60px" : "32px 32px 40px 32px"
                : isDesktop
                ? "60px 50px 44px 60px"
                : "60px",
              width: matches ? "100%" : "100%",
              textAlign: matches ? "center" : "left",
              position: "relative",
            }}
          >
            <Grid width={isLargeDesktop || isExtraLargeDesktop ? "483px" : isDesktop ? "51.5%" : isTablet ? "45%" : "100%"} sx={{ textAlign: "left"}}>
              <Typography
                variant="h3"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                }}
              >
                {keyBenefits.freeTraining}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#383838",
                  marginRight: matches || isDesktop ? "0" : "4px",
                }}
              >
                {keyBenefits.freeTrainingDesc}
              </Typography>
            </Grid>
            <Grid
              sx={{
                width: matches ? "271px" : isDesktop ? "324px" : "512px",
                height: matches ? "163px" : isDesktop ? "195px" : "309px",
                alignSelf: "center",
              }}
            >
              <img
                src={Free_Training_desktop}
                width={"100%"}
                height={"auto"}
                alt={"Free_Training"}
                loading="lazy"
              />
            </Grid>
          </Grid>

          {isLargeDesktop ? (
            <Grid
              display={"flex"}
              justifyContent={"space-between"}
              gap={"20px"}
              flexDirection={matches || isDesktop ? "column" : "row"}
            >
              <Grid
                sx={{
                  display: "flex",
                  height: isLargeDesktop ? "429px" : "auto",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flex: 1,
                  padding: matches ? "40px 24px" : "60px 70px 0 60px",
                  background: `#FFECC8`,
                  borderRadius: "16px",
                  textAlign: matches ? "center" : "left",
                  position: "relative",
                }}
              >
                <Grid>
                  <Typography
                    variant="h3"
                    sx={{
                      width: "100%",
                      marginBottom: "16px",
                    }}
                  >
                    {(page !== "familyCareGiver") ? keyBenefits.financialIncentives: keyBenefits.OnlineCommunity}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#383838",
                      width: page !== "familyCareGiver" ? "60%" : "60%",
                    }}
                  >
                    {(page !== "familyCareGiver") ? keyBenefits.financialIncetivesDesc: keyBenefits.OnlineCommunityDesc}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    width: page !== "familyCareGiver" ? "203px" : "246px",
                    height: "auto",
                    position: matches || isDesktop ? "relative" : "absolute",
                    right: page !== "familyCareGiver" ? "60px" : "0px",
                    bottom: page !== "familyCareGiver" ? "0px" : "10px",
                  }}
                >
                  <img
                    src={
                      page !== "familyCareGiver"
                        ? Financial_Incentives_desktop
                        : (matches || isDesktop ? OnlineCommunity : OnlineCommunity_desktop)
                    }
                    width={"100%"}
                    height={"auto"}
                    alt={page === "familyCareGiver" ? "Financial_Incentives" : "Online Community"}
                    loading="lazy"
                  />
                </Grid>
              </Grid>
              <Grid
                sx={{
                  flex: 1,
                  height: isLargeDesktop ? "429px" : "auto",
                  background: `#FFE0DF`,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  padding: matches ? "40px 24px" : "60px 70px 0 60px",
                  borderRadius: "16px",
                  textAlign: matches ? "center" : "left",
                  position: "relative",
                }}
              >
                <Grid>
                  <Typography
                    variant="h3"
                    sx={{
                      width: "100%",
                      marginBottom: "16px",
                    }}
                  >
                    {keyBenefits.digitalTool}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#383838",
                      width: "63%",
                    }}
                  >
                    {keyBenefits.digitalToolDesc}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    width: "160px",
                    height: "283px",
                    position: matches || isDesktop ? "relative" : "absolute",
                    right: "60px",
                    bottom: 0,
                  }}
                >
                  <img
                    src={digital_tools_desktop}
                    width={"100%"}
                    height={"auto"}
                    alt={"digital_tools"}
                    loading="lazy"
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                gap={
                   isDesktop
                    ? "94px"
                    : isTablet ? "61px" : "39px"
                } 
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: isMobile ? "column" : "row",
                  background: "#FFECC8",
                  objectFit: "cover",
                  backgroundRepeat: "round",
                  borderRadius: "16px",
                  padding: matches
                    ? (isTablet ? `70px 62px ${page === "familyCareGiver" ? "53px" : "0"} 60px` : "32px 32px 0")
                    :  `58px ${page === "familyCareGiver" ? "43px" : "132px"} 0 60px`,
                  width: matches ? "100%" : "100%",
                  textAlign: matches ? "center" : "left",
                  position: "relative",
                }}
              >
                <Grid width={isDesktop ? "59%" : isTablet ? "57%" : "100%"} sx={{textAlign: "left"}}>
                  <Typography
                    variant="h3"
                    sx={{
                      width: "100%",
                      marginBottom: "16px",
                    }}
                  >
                    {(page !== "familyCareGiver") ? keyBenefits.financialIncentives: keyBenefits.OnlineCommunity}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#383838",
                    }}
                  >
                    {(page !== "familyCareGiver") ? keyBenefits.financialIncetivesDesc: keyBenefits.OnlineCommunityDesc}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    width: page === "familyCareGiver" ? isDesktop ? "296px" : "266px" : "176px",
                    height: page === "familyCareGiver" ? isDesktop ? "210px" : "189px" : "238px",
                    alignSelf: "center",
                  }}
                >
                  <img
                    src={page === "familyCareGiver"
                    ? OnlineCommunity
                    : Financial_Incentives_desktop}
                    width={"100%"}
                    height={"auto"}
                    alt={"Financial Incentives"}
                    loading="lazy"
                  />
                </Grid>
              </Grid>

              <Grid
                gap={
                   isDesktop
                    ? "116px"
                    : (isTablet ? "89px" : "65px")
                }
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: isMobile ? "column" : "row",
                  background: "#FFE0DF",
                  objectFit: "cover",
                  backgroundRepeat: "round",
                  borderRadius: "16px",
                  padding: matches
                    ? (isTablet ? "63px 76px 0 60px" : "32px 32px 0")
                    :  `63px 164px 0 60px`,
                  width: matches ? "100%" : "100%",
                  textAlign: matches ? "center" : "left",
                  position: "relative",
                }}
              >
                <Grid width={isDesktop ? "61%" : isTablet ? "59%" : "100%"} sx={{textAlign: "left"}}>
                  <Typography
                    variant="h3"
                    sx={{
                      width: "100%",
                      marginBottom: "16px",
                    }}
                  >
                    {keyBenefits.digitalTool}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#383838",
                    }}
                  >
                    {keyBenefits.digitalToolDesc}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    width: isMobile ? "147px" : "134px",
                    height:  isMobile ? "259px" :  "237px",
                    alignSelf: "center",
                  }}
                >
                  <img
                    src={digital_tools_desktop}
                    width={"100%"}
                    height={"auto"}
                    alt={"Access to Digital Tools"}
                    loading="lazy"
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CaregiverKeyBenefits;
