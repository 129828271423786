import { Input, useMediaQuery, useTheme, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";

// Define custom styles using makeStyles
const useStyles = makeStyles({
  inputFieldLabel: {
    color: "#1F1F1F",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.036px",
  },
  errorText: {
    color: "#D00000",
  },
});

interface IKinaryInputProps {
  inputContent: any;
  fieldName: string;
  inputRef?: any;
  fieldType: string;
  handleChange: (event: React.ChangeEvent<any>) => void;
  value: string;
  hasError: boolean;
  errorMessage: string | undefined;
}

export const KinaryInput = ({
  inputContent,
  handleChange,
  value,
  hasError,
  errorMessage,
  fieldName,
  inputRef,
  fieldType,
}: IKinaryInputProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const classes = useStyles();

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  return (
    <Grid>
      <Grid className={classes.inputFieldLabel} paddingBottom={"8px"}>
        {inputContent.label}
      </Grid>
      <Input
        placeholder={inputContent.placeholder}
        type={fieldType}
        disableUnderline={true}
        inputRef={inputRef}
        name={fieldName}
        onChange={handleChange}
        value={value}
        error={hasError}
        sx={{
          fontSize:
                isMobile
                  ? "16px !important"
                  : "18px !important",
              lineHeight:
                isMobile
                  ? "22px !important"
                  : "24px !important",
              letterSpacing:
                isMobile
                  ? "0.032px !important"
                  : "0.036px !important",
              "& ::placeholder": {
                fontWeight: 400,
                fontSize: 
                  isMobile
                    ? "16px !important"
                    : "18px !important",
                lineHeight: 
                  isMobile
                    ? "22px !important"
                    : "24px !important",
                letterSpacing:
                  isMobile
                    ? "0.032px !important"
                    : "0.036px !important",
                color: "#8D8D8D !important",
              },
              "&.MuiInputBase-root.Mui-focused ": {
                outline: "1px solid #8D8D8D",
              }
        }}
      />
      {hasError ? (
        <Grid
          paddingTop={"8px"}
          className={`${classes.inputFieldLabel} ${classes.errorText}`}
        >
          {errorMessage}
        </Grid>
      ) : null}
    </Grid>
  );
};
