import { useState } from "react";
import BannerSection from "../BannerSection";
import KinaryBase from "../KinaryBase";
import KinaryCapability from "../KinaryCapability";
import KinaryFAQ from "../KinaryFAQ";
import KinaryFooter from "../KinaryFooter";
import KinaryOverview1 from "../KinaryOverview1";
import KinaryTestimonial from "../KinaryTestimonial";
import KinaryTips from "../KinaryTips";
import Layout from "../layout";
import CaregiverTrainingModal from "../Caregiver/CaregiverTrainingModal";
import CaregiverTrainingProgram from "../CaregiverTrainingProgram";
import { overViewItems } from "../../content/options";

interface IHome {
  matches: boolean;
}

export default function Home(props: IHome) {
  const [isCaregiveModalOpen, setIsCaregiverModalOpen] = useState(false);
  const hideTestimonials = process.env.REACT_APP_HIDE_TESTIMONIALS === "true";
  return (
    <>
     <CaregiverTrainingModal
          setIsCaregiverModalOpen={setIsCaregiverModalOpen}
          isCaregiveModalOpen={isCaregiveModalOpen}
          matches={props.matches}
        />
    <Layout setIsCaregiverModalOpen={setIsCaregiverModalOpen} matches={props.matches} page={"home"}>
      {/* {props.matches && <TrainingLoginMobile page={"home"} />} */}
      <BannerSection matches={props.matches} />
      {/* <KinaryThinBanner matches={props.matches} /> */}
      <KinaryBase matches={props.matches} />
      <KinaryOverview1 
        matches={props.matches} 
        background="linear-gradient(270deg, rgba(255, 240, 211, 0.40) 0%, rgba(254, 225, 204, 0.00) 100%)"
        image={"individual-care-plans"}
        order={"normal"}
        content={overViewItems[0]}
         />
      <KinaryOverview1 
        matches={props.matches} 
        background="#FFFFFF"
        image={"involve others"}
        order={"reverse"}
        content={overViewItems[1]}
         />
         <KinaryOverview1 
        matches={props.matches} 
        background="linear-gradient(270deg, rgba(255, 240, 211, 0.40) 0%, rgba(254, 225, 204, 0.00) 100%)"
        image={"quality"}
        order={"normal"}
        content={overViewItems[2]}
         />
         <KinaryOverview1 
        matches={props.matches} 
        background="#FFFFFF"
        image={"location"}
        order={"reverse"}
        content={overViewItems[3]}
         />   
      <KinaryCapability matches={props.matches} />
      <CaregiverTrainingProgram matches={props.matches} setIsCaregiverModalOpen={setIsCaregiverModalOpen} />
      <KinaryTips matches={props.matches} />
      {!hideTestimonials && <KinaryTestimonial matches={props.matches} />}
      <KinaryFAQ matches={props.matches} />
      <KinaryFooter matches={props.matches} />
    </Layout>
    </>
  );
}
