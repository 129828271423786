import { Grid } from "@mui/material";
import { useRef } from "react";
import allContent from "../../../content/en.json";
import KinaryFooter from "../../KinaryFooter";
import Layout from "../../layout";
import CaregiverCalgrows from "../CaregiverCalgrows";
import CaregiverCourses from "../CaregiverCourses";
import CaregiverDetails from "../CaregiverDetails";
import CaregiverEligibility from "../CaregiverEligibility";
import CaregiverFcHero from "../CaregiverFcHero";
import CaregiverKeyBenefits from "../CaregiverKeyBenefits";
import Fc_hero from "../../../assets/Fc_hero.png";
import Testimonials from "../../Testimonials";

const {
  familyCaregiver
} = allContent;

let content = familyCaregiver;

interface IFamilyCareGiverProps {
  matches: boolean;
}

function FamilyCareGiver(props: IFamilyCareGiverProps) {
  const { matches } = props;
  const coursesRef = useRef();
  const registerForFreeRef = useRef();
  const scrollToDiv = (ref: any) => {
    window.scrollTo(0, ref.current.offsetTop)
  };

  return (
    <Layout matches={props.matches}>
      <Grid>
        <CaregiverFcHero
          matches={matches}
          content={content}
          reference={registerForFreeRef}
          banner={Fc_hero}
          click={() => scrollToDiv(coursesRef)}
        />
        <CaregiverCalgrows  content={content} matches={matches} />
        <CaregiverKeyBenefits  content={content} matches={matches} page={"familyCareGiver"} />
        <CaregiverDetails  content={content} />
        <CaregiverEligibility  content={content} matches={matches} />
         <Testimonials matches={matches}></Testimonials>
        <CaregiverCourses  content={content} matches={matches} reference={coursesRef} page={"fcw_training"} />
      </Grid>
      <KinaryFooter {...{ matches }} />
    </Layout>
  );
}

export default FamilyCareGiver;
