import {
  useMediaQuery,
  useTheme,
  Select,
  Grid,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Define custom styles using makeStyles
const useStyles = makeStyles({
  selectFieldLabel: {
    color: "#1F1F1F",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.036px",
  },
  errorText: {
    color: "#D00000",
  },
});

interface IKinarySelectProps {
  selectContent: any;
  fieldName: string;
  handleChange: (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => void;
  value: string;
  hasError: boolean;
  errorMessage: string | undefined;
}

export const KinarySelect = ({
  selectContent,
  handleChange,
  value,
  hasError,
  errorMessage,
  fieldName,
}: IKinarySelectProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  return (
    <Grid>
      <Grid className={classes.selectFieldLabel} paddingBottom={"8px"}>
        {selectContent.label}
      </Grid>
      <Select
        labelId="select-subject"
        displayEmpty
        value={value}
        name={fieldName}
        onChange={handleChange}
        className={classes.selectFieldLabel}
        IconComponent={ExpandMoreIcon}
        error={hasError}
        sx={{
          border: hasError ? "none" : "1px solid #C0C0C0",
          fontSize: isMobile ? "16px !important" : "18px !important",
          lineHeight: isMobile ? "22px !important" : "24px !important",
          letterSpacing: isMobile ? "0.032px !important" : "0.036px !important",
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
            outline: "none",
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid #8D8D8D",
            },
            "&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid #D00000",
            },
        }}
      >
        <MenuItem
          sx={{
            color: "#C0C0C0",
            fontFamily: "Source Sans Pro !important",
            fontSize: isMobile ? "16px !important" : "18px !important",
            lineHeight: isMobile ? "22px !important" : "24px !important",
            fontWeight: 400,
            letterSpacing: isMobile
              ? "0.032px !important"
              : "0.036px !important",
          }}
          disabled
          value=""
        >
          <Grid
            sx={{
              color: "#1F1F1F",
              fontFamily: "Source Sans Pro",
              fontSize: isMobile ? "16px !important" : "18px !important",
              lineHeight: isMobile ? "22px !important" : "24px !important",
              fontWeight: 400,
              letterSpacing: isMobile
                ? "0.032px !important"
                : "0.036px !important",
            }}
          >
            {selectContent.placeholder}
          </Grid>
        </MenuItem>
        {selectContent.options.map(
          (ele: { label: string; value: string }, index: number) => (
            <MenuItem
              disableTouchRipple
              sx={{
                color: "#1F1F1F",
                fontFamily: "Source Sans Pro",
                fontSize: isMobile ? "16px !important" : "18px !important",
                lineHeight: isMobile ? "22px !important" : "24px !important",
                fontWeight: 400,
                letterSpacing: isMobile
                  ? "0.032px !important"
                  : "0.036px !important",
              }}
              key={index}
              value={ele.value}
            >
              {ele.label}
            </MenuItem>
          )
        )}
      </Select>
      {/* Display validation error if exists */}
      {hasError ? (
        <Grid
          paddingTop={"8px"}
          className={`${classes.selectFieldLabel} ${classes.errorText}`}
        >
          {errorMessage}
        </Grid>
      ) : null}
    </Grid>
  );
};

