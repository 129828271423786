import { Divider, Grid, Typography } from "@mui/material";

interface ITrainingCaregiverProps {
  matches: boolean;
  content: any;
  color: string;
  images: { section1Url: string; section2Url: string };
}

function TrainingCaregiver(props: ITrainingCaregiverProps) {
  const {
    color,
    matches,
    content: { title, section1, section2 },
    images: { section1Url, section2Url },
  } = props;
  return (
    <Grid paddingY={matches ? "60px" : "100px"} justifyContent={"center"}>
      <Typography
        variant="h1"
        sx={{
          fontSize: matches ? "28px" : "48px",
          lineHeight: matches ? "32px" : "52px",
        }}
        lineHeight={"52px"}
        fontWeight={400}
      >
        {title}
      </Typography>
      <Grid
        paddingY={"50px"}
        paddingX={matches ? "24px" : "0"}
        display="flex"
        justifyContent={"center"}
        flexDirection={matches ? "column" : "row"}
        width={matches ? "84%" : "64%"}
        margin={matches ? "0 auto" : "48px auto 0"}
        marginTop = {matches ? "24px" : "48px"}
        sx={{ backgroundColor: color }}
        borderRadius={"16px"}
      >
        <Grid flex={"1"} display={"flex"} justifyContent={"center"}>
          <Grid width={matches? "100%" : "80%"}>
            <Grid
              display={"flex"}
              alignSelf={matches ? "center" : "flex-start"}
              justifyContent={ matches ? "center" : "flex-start"}
              paddingBottom={"10px"}
            >
              <img
                src={section1Url}
                loading="lazy"
                width={ matches ? "80px" : "100px"}
                height={ matches ? "80px" :"100px"}
                alt="Helping hand"
              />
            </Grid>
            <Typography
              variant="h2"
              sx={{
                fontSize: matches ? "20px" : "28px",
                fontFamily: "Source Sans Pro",
                lineHeight: matches ? "24px" : "32px",
                fontWeight: "600",
                textAlign: matches ? "center" : "left",
                marginBottom: "12px",
              }}
            >
              {section1.title}
            </Typography>
            <Typography
              sx={{ fontFamily: "Source Sans Pro" }}
              textAlign={ matches ? "center" : "left"}
              variant="body1"
            >
              {section1.description}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginY: matches ? "40px" : "0"}} orientation={matches ? "horizontal" : "vertical"} flexItem />
        <Grid flex={"1"} display={"flex"} justifyContent={"center"}>
          <Grid width={matches? "100%" : "80%"}>
            <Grid
              display={"flex"}
              alignSelf={ matches ? "center" : "flex-start"}
              justifyContent={ matches ? "center" : "flex-start"}
              paddingBottom={"10px"}
            >
              <img
                src={section2Url}
                loading="lazy"
                width={ matches ? "80px" : "100px"}
                height={ matches ? "80px" : "100px"}
                alt="Helping hand"
              />
            </Grid>
            <Typography
              variant="h2"
              sx={{
                fontSize: matches ? "20px" : "28px",
                fontFamily: "Source Sans Pro",
                lineHeight: matches ? "24px" : "32px",
                fontWeight: "600",
                textAlign: matches ? "center" : "left",
                marginBottom: "12px",
              }}
            >
              {section2.title}
            </Typography>
            <Typography
              sx={{ fontFamily: "Source Sans Pro" }}
              textAlign={ matches ? "center" : "left"}
              variant="body1"
            >
              {section2.description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TrainingCaregiver;
