import React from "react";
import allContent from "../../content/en.json";
import { Box, Grid, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import right_arrow from "../../assets/right-arrow.svg";

const {
  home: { thinBanner },
  common,
} = allContent;

interface IKinaryThinBannerProps {
  matches: boolean;
  setIsCaregiverModalOpen: (arg0: boolean) => void;
}

function KinaryThinBanner(props: IKinaryThinBannerProps) {
  const theme = useTheme();
  const matches1 = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const { matches, setIsCaregiverModalOpen } = props;
  return (
    <Box
      onClick={() => { isMobile && setIsCaregiverModalOpen(true)}}
      sx={{
        display: matches && !matches1 ? "inline" : "flex",
        justifyContent: matches && !matches1 ? "none" : "center",
        backgroundColor: "#083B33",
        paddingY: matches ? "15px" : "18px",
        width: "100%",
        paddingX: matches ? "20px" : "auto",
        textAlign: "center",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: "#FFFFFF",
          textAlign: "center",
          paddingRight: "5px",
        }}
      >
        {thinBanner.title}
      </Typography>
      <Grid display={"flex"} justifyContent={"center"}>
        <Typography
          variant="body2"
          sx={{
            color: "#FFFFFF",
            textAlign: "center",
            borderRight: "1px solid #FFFFFF",
            paddingRight: "14px",
          }}
        >
          {thinBanner.title1}
        </Typography>

        <Link
          to="#"
          component={RouterLink}
          onClick={(event) => {
            event.stopPropagation();
            setIsCaregiverModalOpen(true);
          }}
          target="_self"
          color="#FFFFFF"
          underline="none"
          sx={{
            alignItems: "center",
            display: "flex",
            fontSize: matches ? "16px" : "20px",
            lineHeight: matches ? "22px" : "28px",
            fontWeight: matches ? "400" : "600",
            fontFamily: "Source Sans Pro",
            justifyContent: "center",
            textDecoration: "none",
            paddingLeft: "14px",
            "&:hover div": {
              animation: "moveLeftRight 1.5s linear forwards",
              "@keyframes moveLeftRight": {
                "0%, 100%": {
                  transform: "translateX(0)",
                },
                "50%": {
                  transform: "translateX(20px)",
                },
              },
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "#FFFFFF",
            }}
          >
            {common.getStarted}
          </Typography>

          <Grid
            style={{
              display: "flex",
              width: matches ? (matches1 ? "16px" : "14px") : "18px",
              height: matches ? (matches1 ? "16px" : "14px") : "18px",
              marginLeft: matches ? "6px" : "8px",
              marginTop: "1px",
              color: "#FFFFFF",
            }}
          >
            <img
              src={right_arrow}
              width={"100%"}
              height={"auto"}
              alt={"right_arrow"}
              loading="lazy"
            />
          </Grid>
        </Link>
      </Grid>
    </Box>
  );
}

export default KinaryThinBanner;
