import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Link,
} from "@mui/material";
import * as React from "react";
import Header from "../Header";
import background from "../../assets/kinary-faq.png";
import KinaryFooter from "../KinaryFooter";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import content from "../../content/en.json";
import { faqOptions } from "../../content/options";
import { parseHTML } from "../../service/utils";
import { useNavigate } from "react-router-dom";

export interface IFAQProps {
  matches: boolean;
}

export default function FAQ({ matches }: IFAQProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isExtraLargeDesktop = useMediaQuery(theme.breakpoints.up("xl"));

  const getFaqWidth = () => {
    if(isDesktop) return "52%";
    if(isLargeDesktop) return "58%";
    if(isExtraLargeDesktop) return "43%";
    if(isTablet) return "68%";
    return "100%";
  };

  return (
    <Grid
      container
      display="flex"
      width="100%"
      sx={{
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <Header matches={matches} />
      <Grid
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent="center"
        sx={{
          paddingY: matches ? isMobile? "55px" : "90px" : isDesktop ? "90px" : "80px",
          paddingX: isMobile ? "32px" : "0",
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPositionY: "50%",
        }}
      >
        <Grid maxWidth={getFaqWidth()}>
          <Typography variant="h2">
            {content.FAQ.title}
          </Typography>
          <Typography
            variant="body1"
            sx={{ pt: "20px" }}
          >
            {content.FAQ.description1}
            <Link
            href="#"
            onClick={(event) => {event.preventDefault();navigate("/support")}}
            target="_self"
            color="#1F1F1F"
            sx={{
              marginX: "6px",
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              textDecorationThickness: "1.5px",
            }}
          >
            {content.common.contact_us.toLowerCase()}
          </Link>
            {content.FAQ.description2}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        width="100%"
        sx={{
          backgroundColor: "#fff",
          alignItems: "center",
          justifyContent: "center",
          paddingX: matches ? isTablet ? "134px" : "32px" : "0",
          paddingBottom: "120px",
          flexDirection: "column",
          "& > *": {
            maxWidth: matches ? "90vw" : null,
          },
        }}
      >
        {faqOptions.map((ele, index) => (
          <Grid key={ele.category}>
            <Grid
              display="flex"
              sx={{
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  paddingTop: matches ? "40px" : "80px",
                  paddingBottom: matches ? isTablet ? "48px": "24px" : isDesktop ? "48px" : "60px",
                }}
              >
                {ele.category}
              </Typography>
            </Grid>
            {ele.questionSet.map((question, qIndex) => (
              <Accordion
                key={`${question}-${qIndex}`}
                sx={{
                  maxWidth: !matches ? isExtraLargeDesktop ? "626px" : (isDesktop ? "676px" : "710px") : "100%",
                  "&::before": {
                    backgroundColor: "transparent",
                  },
                  boxShadow: "none",
                  borderBottom: "1px solid #ECECEC",
                  borderRadius: "0px !important",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color:"#1F1F1F"}} />}
                  sx={{
                    marginTop: qIndex === 0 ? "0 !important" : "32px",
                    margin: matches || isDesktop ? "24px 0" : "32px 0px",
                    minHeight: "auto !important",
                    width: "100%",
                    padding: "0",
                    "div": {
                      marginY: "0 !important",
                    },
                    "&.Mui-expanded": {
                      marginBottom: isMobile? "12px !important" : "14px !important"
                    }
                  }}
                >
                  <Typography
                    variant="body1"
                    textAlign="left"
                  >
                    {question.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: "0px",
                    marginBottom: "32px",
                  }}
                >
                  <Typography
                    variant="body2"
                    textAlign="left"
                  >
                    {parseHTML(question.answer)}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        ))}
      </Grid>
      <KinaryFooter {...{ matches }} />
    </Grid>
  );
}
