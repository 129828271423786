export interface SetContactUs {
    full_name: string;
    email: string;
    subject: string;
    message: string;
    created_on: string;
}

export interface IDeletedUserAccount {
    name: string;
    phone_number: string;
    email: string;
    reason: string;
    comments?: string;
}

export const setContactUsDetails = (args: SetContactUs) => {
    return fetch('https://sheetdb.io/api/v1/' + process.env.REACT_APP_SHEETDB_API_KEY + '?sheet=contact-us', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: [
                {
                    id: 'INCREMENT',
                    ...args
                }
            ]
        })
    })
}

export const setSubscriberEmail = (email: string) => {
    return fetch('https://sheetdb.io/api/v1/' + process.env.REACT_APP_SHEETDB_API_KEY + '?sheet=subscribe-email', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: [
                {
                    id: 'INCREMENT',
                    email_id: email,
                    created_on: new Date().toString()
                }
            ]
        })
    })
}

export const setDeletedAccountDetails = (args: IDeletedUserAccount) => {
    return fetch('https://sheetdb.io/api/v1/' + process.env.REACT_APP_SHEETDB_API_KEY + '?sheet=deleted-user-account', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: [
                {
                    id: 'INCREMENT',
                    ...args,
                    created_on: new Date().toString()
                }
            ]
        })
    })
}