import { Grid, Link, Typography, useTheme, useMediaQuery } from "@mui/material";
import tick from "../../../assets/tick.svg";
import asterisk from "../../../assets/asterisk.svg";
import { useNavigate } from "react-router-dom";

interface ICaregiverEligibilityProps {
  matches: boolean;
  content: any;
}

function CaregiverEligibility(props: ICaregiverEligibilityProps) {
  const { matches, content } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const { eligibility } = content;
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "center",
        paddingY: matches || isDesktop ? "80px" : "120px",
        paddingX: matches ? (isTablet ? "48px" : "20px") : "0",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: matches ? "100%" : "960px",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              marginBottom: matches || isDesktop ? (isTablet ? "16px" : "12px") : "20px",
              textAlign: matches ? "center" : "center",
            }}
          >
            {eligibility.title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: matches ? (isTablet ? "60px" : "32px") : (isDesktop ? "40px" : "60px"),
              color: "#383838",
              textAlign: matches ? "center" : "left",
            }}
          >
            {eligibility.eligibilityDesc}
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: matches ? (isTablet ? "24px" : "16px") : (isDesktop ? "24px" : "32px"),
          }}
        >
          <Grid sx={{
            display: "flex",
            flexDirection: matches ? "column" : "row",
            gap: "20px",
          }}>
            {eligibility.eligibilityCriterias.map(
              (criteria: any, index: number) => (
                <Grid
                  key={index}
                  sx={{
                    display: "flex",
                    borderRadius: "16px",
                    gap: matches ? (isTablet ? "32px" : "24") : "0",
                    padding: matches ? (isTablet ? "40px" : "40px 32px") : "40px",
                    background: "#FFFFFF",
                    flexDirection: !isTablet ? "column" : "row",
                    alignItems: "flex-start",
                    width: matches ? "100%" : (isDesktop ? "415px" : "467px"),
                    boxShadow: "0px 6px 60px 0px rgba(0, 0, 0, 0.06)"

                  }}
                >
                  <Grid
                    sx={{
                      width: isMobile ? "60px" : "100px",
                      height: isMobile ? "60px" : "100px",
                      textAlign: "center",
                      marginBottom: matches ? "22px" : "32px",
                    }}
                  >
                    <img width={"100%"} height={"auto"} src={tick} alt={"tick"} loading="lazy" />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      flex: "1"
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        marginBottom: "20px",
                        color: "#1F1F1F",
                        textAlign: "left",
                      }}
                    >
                      {criteria.eligibilityCriteria}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        paddingBottom: "12px",
                        color: "#606060",
                        textAlign: "left",
                      }}
                    >
                      {criteria.eligibilityCriteriaText}
                    </Typography>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                
              }}
            >
              <Grid
                sx={{
                  width: "14px",
                  height: "24px",
                  marginRight: "4px",
                  marginTop: "2px",
                }}
              >
                <img src={asterisk} alt={"asterisk"} loading="lazy" />
              </Grid>
              <Typography
                variant="body2"
                sx={{
                  color: "#383838",
                  textAlign: "left",
                }}
              >
                {eligibility.eligibiltyTC}
              </Typography>
            </Grid>
            {eligibility.dcText && (
              <Grid
                sx={{
                  display: "flex",
                  paddingTop: "20px",
                  justifyContent: "center",
                }}
              >
                <Grid
                  sx={{
                    width: "14px",
                    height: "24px",
                    marginRight: "4px",
                    marginTop: "2px",
                  }}
                >
                  <img src={asterisk} alt={"asterisk"} loading="lazy" />
                </Grid>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#383838",
                    textAlign: "left",
                  }}
                >
                  {eligibility.dcText}
                  <Link
                    href="#"
                    onClick={(event: any) => {
                      event.preventDefault();
                      navigate("/dcw_training");
                    }}
                    target="_self"
                    color="#383838"
                    sx={{
                      marginX: "6px",
                      textDecoration: "underline",
                      textUnderlineOffset: "4px",
                      textDecorationThickness: "1.5px",
                      "&:hover": {
                        color: "#F25832",
                        textDecorationColor: "#F25832"
                      },
                      "&:active": {
                        color: "#F25832",
                        textDecorationColor: "#F25832"
                      }
                    }}
                  >
                    {eligibility.dcNavLink}
                  </Link>
                </Typography>
              </Grid>
            )}
            
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CaregiverEligibility;
