import {
  Button,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import bannerImgXl from "../../assets/kinary-banner_XlDesktop.png";
import bannerImgLg from "../../assets/kinary-banner_largeDesktop.png";
import bannerImgMd from "../../assets/kinary-banner_Desktop.png";
import bannerImgSm from "../../assets/kinary-banner_smallDesktop.png";
import bannerImgXs from "../../assets/kinary-banner_mobile.webp";
import allContent from "../../content/en.json";
import { parseHTML } from "../../service/utils";
import { Link as RouterLink } from "react-router-dom";

export interface IBannerSectionProps {
  matches: boolean;
}

const {
  home: { banner },
  common,
} = allContent;

export default function BannerSection({ matches }: IBannerSectionProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isExtraLargeDesktop = useMediaQuery(theme.breakpoints.up("xl"));

  const getBannerImage = () => {
    if (isMobile) return bannerImgXs;

    if (isTablet) return bannerImgSm;

    if (isDesktop) return bannerImgMd;

    if (isLargeDesktop ) return bannerImgLg;

    if (isExtraLargeDesktop) return bannerImgXl;
  };

  return (
    <Grid
      container
      sx={{
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Grid
        sx={{
          position: "relative",
          textAlign: "left",
          lineHeight: "0",
          display: isMobile ? "flex" : "inline",
          flexDirection: "column",
          background: isMobile
            ? "linear-gradient(180deg, rgba(255, 240, 211, 0.40) 0%, rgba(254, 225, 204, 0.00) 100%)"
            : "linear-gradient(90deg, rgba(0, 0, 0, 0.30) 21.88%, rgba(0, 0, 0, 0.00) 59.90%)",
        }}
      >
        <img
          alt="banner"
          src={getBannerImage()}
          
          style={{
            width: "100%",
            height: "auto",
            zIndex: "-1",
            order: "2",
            position: "inherit",
            minHeight: isMobile ? "0" : "425px",
          }}
        />
        <Grid
          item
          container
          display="flex"
          flexDirection="column"
          sx={{
            position: isMobile ? "initial" : "absolute",
            padding: isMobile ? "40px 32px 13px" : "0",
            top: isTablet ? "26%" : (isDesktop ? "35%" : "43%"),
            transform: !isMobile ? "translateY(-50%)" : "0",
            left: matches ? "6.5%" : "8.5%",
            maxWidth: matches || isDesktop ?  (isMobile ? "100%" : "min(346px, 100%)") : "min(525px, 100%)",
            textAlign: isMobile  ? "center" : "left",
            alignItems: isMobile ? "center" : "left",
          }}
        >
          <Typography
            variant="h1"
            color={isMobile ? "#1F1F1F" : "#FFFFFF"}
          >
            {parseHTML(banner.title)}
          </Typography>
          <Typography
            variant="body1"
            color={isMobile ? "#1F1F1F" : "#FFFFFF"}
            maxWidth= {matches ?  (isMobile ? "100%" : "min(306px, 100%)") : "min(478px, 100%)"}
            sx={{
              marginTop: matches ? "12px" : "16px",
            }}
          >
            {parseHTML(banner.description)}
          </Typography>
          <Link
            to="/download"
            underline="none"
            sx={{
              maxWidth: "max-content",
              marginTop: matches ? "20px" : "30px",
            }}
            component={RouterLink}
          >
            <Button
              variant="contained"
              size="large"
              disableTouchRipple
            >
              {common.download_for_free}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
}
