import { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  styled,
  Paper,
  Button,
  CircularProgress,
} from "@mui/material";
import bannerImgXl from "../../assets/contact-xl.webp";
import bannerImgLg from "../../assets/contact-lg.webp";
import bannerImgMd from "../../assets/contact-md.webp";
import bannerImgSm from "../../assets/contact-sm.webp";
import bannerImgXs from "../../assets/contact-xs.webp";
import success from "../../assets/success-support.svg";
import KinaryFooter from "../KinaryFooter";
import Layout from "../layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setContactUsDetails } from "../../service";
import allContent from "../../content/en.json";
import CaregiverTrainingModal from "../Caregiver/CaregiverTrainingModal";
import { parseHTML } from "../../service/utils";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router-dom";
import { KinaryInput } from "../../Shared/KinaryInput";
import { KinarySelect } from "../../Shared/KinarySelect";
import { KinaryTextArea } from "../../Shared/KinaryTextArea";

const content = allContent.support;

const formSchema = Yup.object().shape({
  full_name: Yup.string().required("This is a required field"),
  email: Yup.string()
    .required("This is a required field")
    .email(allContent.common.invalid_email_message)
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      allContent.common.invalid_email_message
    ),
  subject: Yup.string().required("This is a required field"),
  message: Yup.string().required("This is a required field"),
  created_on: Yup.string(),
});

const Form = styled("form")(() => ({
  width: "100%",
}));

export default function Support(props: { matches: boolean; reason?: string }) {
  const { matches, reason } = props;
  const [error, setError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isCaregiveModalOpen, setIsCaregiverModalOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isExtraLargeDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const inputRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      full_name: "",
      email: "",
      subject: reason ?? "",
      message: "",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      setContactUsDetails({ ...values, created_on: new Date().toString() })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            setError(true);
          }
        })
        .then(() => {
          setShowSuccess(true);
        })
        .catch(() => setError(true))
        .finally(() => setIsLoading(false));
    },
  });

  const getBgImage = () => {
    if (isMobile) return bannerImgXs;
    if (isTablet) return bannerImgSm;
    if (isDesktop) return bannerImgMd;
    if (isLargeDesktop) return bannerImgLg;
    return bannerImgXl;
  };

  useEffect(
    () => () => {
      formik.resetForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <>
      <CaregiverTrainingModal
        setIsCaregiverModalOpen={setIsCaregiverModalOpen}
        isCaregiveModalOpen={isCaregiveModalOpen}
        matches={matches}
      />

      <Layout
        setIsCaregiverModalOpen={setIsCaregiverModalOpen}
        matches={matches}
        page={"support"}
      >
        <Grid
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={matches ? "column" : "row"}
          gap={
            matches ? (isMobile ? "24px" : "40px") : isDesktop ? "3.5%" : "80px"
          }
          sx={{
            backgroundImage: `url(${getBgImage()})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPositionY: "100%",
            padding: matches
              ? isTablet
                ? "48px 0 370px"
                : "32px 0 316px"
              : isDesktop
              ? "32px 0 135px"
              : "52px 0 190px",
          }}
        >
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={matches ? "column" : "row"}
            gap={
              matches
                ? isMobile
                  ? "24px"
                  : "40px"
                : isExtraLargeDesktop
                ? "80px"
                : "0"
            }
            sx={{
              width: matches || isExtraLargeDesktop ? "auto" : "83%",
              display: "flex",
              margin: isExtraLargeDesktop ? "0" : "0 auto",
              justifyContent: matches ? "center" : "space-between",
            }}
          >
            <Grid
              sx={{
                alignSelf: "flex-start",
                textAlign: isTablet ? "center" : "left",
                width: matches
                  ? isTablet
                    ? "75%"
                    : "100%"
                  : isDesktop
                  ? "48%"
                  : "48%",
                display: "flex",
                flexDirection: "column",
                margin: matches ? "0 auto" : "0",
                paddingX: isMobile ? "32px" : "0",
              }}
            >
              <Typography
                variant={"h2"}
                sx={{ padding: matches ? "0 0 12px 0" : "48px 0 16px" }}
              >
                {parseHTML(content.title)}
              </Typography>
              <Typography
                sx={{
                  margin: matches ? "0 auto" : "0",
                  width: matches
                    ? isTablet
                      ? "90%"
                      : "100%"
                    : isDesktop
                    ? "276px"
                    : "378px",
                }}
                variant={"body2"}
              >
                {content.description}
              </Typography>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: matches
                  ? isTablet
                    ? "499px"
                    : "100%"
                  : isDesktop
                  ? "48%"
                  : isLargeDesktop
                  ? "48%"
                  : "48%",
                minHeight: matches ? (isMobile ? "100%" : "640px") : "698px",
                borderRadius: "16px",
                backgroundColor: isMobile ? "none" : "#FFF",
                padding:
                  matches || isDesktop
                    ? isMobile
                      ? "0 32px"
                      : "42px"
                    : "60px",
                boxShadow: isMobile ? "none" : "0px 4px 64px rgba(0, 0, 0, 0.06)",
              }}
            >
              {!showSuccess ? (
                <Form onSubmit={formik.handleSubmit}>
                  <Paper
                    elevation={0}
                    sx={{
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "column",
                      height: "max-content",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      borderRadius: matches ? "0" : null,
                      background: "none !important",
                    }}
                  >
                    <Grid sx={{ textAlign: "left" }}>
                      {error && (
                        <Paper
                          elevation={0}
                          sx={{
                            backgroundColor: "none !important",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            gap: "6px",
                            px: "18px",
                            py: "16px",
                          }}
                        >
                          <ErrorIcon color="error" />
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "20px",
                            }}
                          >
                            {content.error_msg}
                          </Typography>
                        </Paper>
                      )}
                    </Grid>
                    <Grid
                      display="flex"
                      flexDirection="column"
                      sx={{
                        textAlign: "left",
                        paddingTop: matches ? "12px" : "8px",
                        marginBottom: matches || isDesktop ? "24px" : "32px",
                        width: "100%",
                        gap: matches ? "20px" : "24px",
                      }}
                    >
                      <KinaryInput
                        inputContent={content.fields.name}
                        fieldType="text"
                        fieldName="full_name"
                        handleChange={formik.handleChange}
                        value={formik.values.full_name}
                        inputRef={inputRef}
                        hasError={
                          !!(
                            formik.touched.full_name && formik.errors.full_name
                          )
                        }
                        errorMessage={formik.errors.full_name}
                      />

                      <KinaryInput
                        inputContent={content.fields.email}
                        fieldType="email"
                        fieldName="email"
                        handleChange={formik.handleChange}
                        value={formik.values.email}
                        hasError={
                          !!(formik.touched.email && formik.errors.email)
                        }
                        errorMessage={formik.errors.email}
                      />
                      <KinarySelect
                        selectContent={content.fields.subject}
                        handleChange={formik.handleChange}
                        value={formik.values.subject}
                        fieldName={"subject"}
                        hasError={
                          !!(formik.touched.subject && formik.errors.subject)
                        }
                        errorMessage={formik.errors.subject}
                      />
                      <KinaryTextArea
                        inputContent={content.fields.message}
                        fieldName="message"
                        handleChange={formik.handleChange}
                        value={formik.values.message}
                        hasError={
                          !!(formik.touched.message && formik.errors.message)
                        }
                        errorMessage={formik.errors.message}
                      />
                    </Grid>
                    <Button
                      variant="contained"
                      disableTouchRipple
                      type="submit"
                      sx={{
                        margin: "auto",
                        width: "100%",
                      }}
                    >
                      {isLoading ? (
                        <CircularProgress size={30} color="inherit" />
                      ) : (
                        content.submit
                      )}
                    </Button>
                  </Paper>
                </Form>
              ) : (
                <Grid
                  flexDirection={"column"}
                  height={"inherit"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    marginTop: isMobile ? "54px" : "0",
                    marginBottom: isMobile ? "316px" : "0",
                  }}
                >
                  <Grid
                    sx={{
                      width: "65px",
                      height: "65px",
                    }}
                  >
                    <img
                      width={"100%"}
                      height={"auto"}
                      loading="lazy"
                      src={success}
                      alt={"success"}
                    />
                  </Grid>
                  <Typography
                    variant={"body1"}
                    sx={{ marginTop: matches || isDesktop ? "12px" : "16px" }}
                  >
                    {content.success_msg}
                  </Typography>
                  <Button
                    variant="contained"
                    disableTouchRipple
                    type="button"
                    onClick={() => navigate("/")}
                    sx={{
                      marginTop: matches || isDesktop ? "16px" : "20px",
                    }}
                  >
                    {content.backToHomeBtn}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <KinaryFooter {...{ matches }} />
      </Layout>
    </>
  );
}