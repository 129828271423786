import CaregiverCalgrows from "../CaregiverCalgrows";
import CaregiverCourses from "../CaregiverCourses";
import CaregiverEligibility from "../CaregiverEligibility";
import CaregiverKeyBenefits from "../CaregiverKeyBenefits";
import CaregiverDetails from "../CaregiverDetails";
import { Grid } from "@mui/material";
import CaregiverFcHero from "../CaregiverFcHero";
import { useRef } from "react";
import KinaryFooter from "../../KinaryFooter";
import Layout from "../../layout";
import Dc_hero from "../../../assets/Dc_hero.png";
import allContent from "../../../content/en.json";
import Testimonials from "../../Testimonials";

interface IPaidCareGiverProps {
  matches: boolean;
}

const {
  paidCaregiver,
} = allContent;


let content = paidCaregiver;

function PaidCareGiver(props: IPaidCareGiverProps) {
  const { matches } = props;
  const scrollToDiv = (ref: any) => {
    window.scrollTo(0, ref.current.offsetTop);
  };
  const registerForFreeRef = useRef();
  const coursesRef = useRef()

  return (
    <Layout matches={props.matches}>
      <Grid>
      <CaregiverFcHero
          matches={matches}
          content={content}
          reference={registerForFreeRef}
          banner={Dc_hero}
          click={() => scrollToDiv(coursesRef)}
        />
        <CaregiverCalgrows content={content} matches={matches} />
        <CaregiverKeyBenefits content={content} matches={matches} page={"paidCareGiver"} />
        <CaregiverDetails content={content} />
        <CaregiverEligibility content={content} matches={matches} />
        <Testimonials matches={matches}></Testimonials>
        <CaregiverCourses content={content} matches={matches} reference={coursesRef} page={"dcw_training"} />
      </Grid>
      <KinaryFooter {...{ matches }} />
    </Layout>
  );
}

export default PaidCareGiver;
