import { Grid, Typography } from "@mui/material";
import { IStaticContent } from "../../content/options";
import { parseHTML } from "../../service/utils";

export default function StaticContent(props: IStaticContent) {
  return (
    <Grid
      sx={{
        "& p": {
          fontSize: "11px !important",
          whiteSpace: "pre-line",
          hyphens: "auto",
        },
        px: "40px",
        py: "10px",
        maxWidth: "100vw",
        minHeight: "100vh",
        backgroundColor: "#f1f3f5",
      }}
    >
      <Typography variant="h3" fontSize="18px">
      {parseHTML(props.heading)}
      </Typography>
      <Typography variant="body2" component="p">
      {parseHTML(props.updatedOn)  + "\n"}
      </Typography>
      <br />
      <Typography variant="body2" textAlign="left">
      {parseHTML(props.content)}
      </Typography>
    </Grid>
  );
}
