import { Grid, Link, Typography, useTheme, useMediaQuery } from "@mui/material";
import CalGrowsBadge from "../../../assets/CalGrows_Badge.webp";
import infoIcon from "../../../assets/InfoIcon.svg";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import useAirtableData, { AirtableRecord } from "../../../utility/airtable";
import CoursesAirtableCard from "./CoursesAirtableCard";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ICaregiverCoursesProps {
  matches: boolean;
  reference: any;
  content: any;
  page: string;
}

function CaregiverCourses(props: ICaregiverCoursesProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const { matches, reference, content, page } = props;
  const navigate = useNavigate();
  const [languageList, setLanguageList] = useState([] as string[]);
  const [language, setLanguage] = useState("");
  const [courseFields, setCourseFields] = useState([] as any);
  const [selectedField, setSelectedField] = useState(null as any);
  const { courses } = content;
  const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY ?? "keykK3e2QBudYx0Lx";
  const baseId =
    page === "dcw_training" ? "appjr7t4Is4RGCI7O" : "appkrxaTknkP5F8eq";
  const table = "Caregiver Training";
  const sortby ="Priority";

  const data = useAirtableData(apiKey, baseId, table, sortby);

  useEffect(() => {
    setLanguage("English");
  }, []);

  useEffect(() => {
    if (data) {
      const fields = [] as AirtableRecord[];
      const langList = [] as string[];
      data.forEach((item) => {
        fields.push(item.fields);
        if (!langList.includes(item.fields.Language)) {
          langList.push(item.fields.Language);
        }
      });
      setCourseFields(fields);
      setLanguageList(langList);
    }
  }, [data]);

  useEffect(() => {
    if (language) {
      const field = courseFields.filter(
        (item: any) => item.Language === language
      );
      setSelectedField(field);
    }
  }, [language, courseFields]);

  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as string);
  };


  return (
    <div ref={reference}>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: matches || isDesktop ? "80px" : "120px",
          paddingX: matches ? (isTablet ? "48px" : "20px") : "0",
          background: "linear-gradient(360deg, #E0FAF5 0%, #9CEDE3 100%)",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: matches ? "100%" : "80%",
            maxWidth: "1274px",
          }}
        >
          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            paddingBottom={isMobile ? "12px" : "20px"}
          >
            <Typography
              variant="h2"
              sx={{
                textAlign: "left",
              }}
            >
              {courses.title}
            </Typography>
            {languageList.length && language && (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                onChange={handleChange}
                IconComponent={ExpandMoreIcon}
                sx={{
                  background: "#FFFFFF",
                  border: "none",
                  width: "130px",
                  height: "42px",
                  "& .MuiSelect-select": {
                    minHeight: "auto !important",
                  },
                  fontSize: isMobile ? "16px !important" : "18px !important",
                  lineHeight: isMobile ? "22px !important" : "24px !important",
                  letterSpacing: isMobile
                    ? "0.032px !important"
                    : "0.036px !important",
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                      outline: "none",
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                      outline: "none",
                    },
                }}
              >
                {languageList.length &&
                  languageList.map((lang, index) => (
                    <MenuItem key={`${lang}_${index}`} value={lang}>
                      <Typography
                        color={"#383838"}
                        textAlign={"left"}
                        variant="body2"
                      >
                        {lang}
                      </Typography>
                    </MenuItem>
                  ))}
              </Select>
            )}
          </Grid>
          <Grid display={"flex"}>
            <img
              width={isMobile ? "18px" : "20px"}
              height={isMobile ? "18px" : "20px"}
              loading="lazy"
              src={infoIcon}
              alt={"Info-icon"}
              style={{
                paddingTop: "2px",
                paddingRight: "5px",
              }}
            />
            <Typography
              variant="body2"
              sx={{
                color: "#383838",
                textAlign: "left",
              }}
            >
              {courses.description}
            </Typography>
          </Grid>
        </Grid>

        {selectedField?.length &&
          selectedField.map((item: any, index: number) => (
            <CoursesAirtableCard
              key={index}
              matches={matches}
              item={item}
              index={index}
              courses={courses}
              isMobile={isMobile}
              isDesktop={isDesktop}
              content={courses}
            />
          ))}
      </Grid>

      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: matches || isDesktop ? "80px" : "120px",
          px: isMobile ? "32px" : "0",
        }}
      >
        <Grid
          sx={{
            width: "223px",
            height: "188px",
            marginBottom: isMobile ? "32px" : "60px",
          }}
        >
          <img
            width={"100%"}
            height={"auto"}
            loading="lazy"
            src={CalGrowsBadge}
            alt={"CalGrowsBadge"}
          />
        </Grid>
        <Typography
          component={"div"}
          variant="body1"
          sx={{
            marginBottom: matches
              ? isTablet
                ? "30px"
                : "41px"
              : isDesktop
              ? "28px"
              : "24px",
            color: "#1F1F1F",
            textAlign: "center",
            display: matches ? "flex" : "inline-flex",
            flexDirection: matches ? "column" : "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid display={"flex"}>
            {courses.contactUsText1}
            <Link
              href="#"
              onClick={(event) => {
                event.preventDefault();
                navigate("/support");
              }}
              target="_self"
              color="#1F1F1F"
              sx={{
                marginX: "6px",
                textDecoration: "underline",
                textUnderlineOffset: "4px",
                textDecorationThickness: "1.5px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#1F1F1F",
                  textAlign: "center",
                  "&:hover": {
                    color: "#F25832",
                    textDecorationColor: "#F25832",
                  },
                  "&:active": {
                    color: "#F25832",
                    textDecorationColor: "#F25832",
                  },
                }}
              >
                {courses.contactUsText}
              </Typography>
            </Link>
          </Grid>
          <Grid display={"flex"} flexDirection={isMobile ? "column" : "row"}>
            {courses.contactUsText2}
            <Link
              href={`mailto:${courses.contactUsText3}`}
              target="_self"
              color="#1F1F1F"
              sx={{
                marginX: "6px",
                textDecoration: "underline",
                textUnderlineOffset: "4px",
                textDecorationThickness: "1.5px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#1F1F1F",
                  textAlign: "center",
                  "&:hover": {
                    color: "#F25832",
                    textDecorationColor: "#F25832",
                  },
                  "&:active": {
                    color: "#F25832",
                    textDecorationColor: "#F25832",
                  },
                }}
              >
                {courses.contactUsText3}
              </Typography>
            </Link>
          </Grid>
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            lineHeight: matches ? "20px" : "20px",
            fontSize: matches ? "14px" : "16px",
            color: "#383838",
            textAlign: "center",
            display: matches ? "flex" : "inline-flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          {courses.exploreText1}
          <Grid display={"flex"} sx={{ marginLeft: !isMobile ? "4px" : "0" }}>
            {courses.exploreText2}
            <Link
              href="https://www.calgrows.org/"
              target="_blank"
              color="#383838"
              sx={{
                color: "#383838",
                marginX: "6px",
                textDecoration: "underline",
                textUnderlineOffset: "4px",
                textDecorationThickness: "1.5px",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#383838",
                  textAlign: "center",
                  "&:hover": {
                    color: "#F25832",
                    textDecorationColor: "#F25832",
                  },
                  "&:active": {
                    color: "#F25832",
                    textDecorationColor: "#F25832",
                  },
                }}
              >
                {courses.exploreText3}
              </Typography>
            </Link>
            {courses.exploreText4}
          </Grid>
        </Typography>
      </Grid>
    </div>
  );
}

export default CaregiverCourses;
