import { useState, useEffect } from 'react';

export interface AirtableRecord {
  id: string;
  fields: any; // Customize the field types as per your data
}

function useAirtableData(apiKey: string, baseId: string, table: string,sortby :string = "Nun") {
  const [data, setData] = useState<AirtableRecord[]>([]);

  useEffect(() => {
    const fetchAirtableData = async () => {
      try {
       let url = `https://api.airtable.com/v0/${baseId}/${table}`
        if (sortby !== "Nun"){
          url += "?sort%5B0%5D%5Bfield%5D=Priority"
        }
        const response = await fetch(url
          ,
          {
            headers: {
              Authorization: `Bearer ${apiKey}`,
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          setData(result.records);
        } else {
          console.error('Error fetching data from Airtable API');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAirtableData();
  }, [apiKey, baseId, table]);

  return data;
}

export default useAirtableData;
