import {
    Avatar,
    Card, Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

export interface IKinaryTestimonialProps {
    data: any;
    matches: boolean
}

export default function TestimonialCard({data, matches}: any) {

    let colors = ["#e0f2f1", "#ffebee", "#e8eaf6"]
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));

    function stringToColor(string: string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name: string) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    function get_color(index: number) {
        return colors[index]
    }

    return (
        <Grid
            sx={{
                display: "flex",
                borderRadius: "16px",
                justifyContent: "center",
                alignItems: "center",

                gap: matches ? (isTablet ? "32px" : "24") : "0",
                padding: matches ? (isTablet ? "40px" : "40px 32px") : "40px",
                margin: matches ? (isTablet ? "40px" : "40px 32px") : "40px",
                background: "#FFFFFF",
                flexDirection: !isTablet ? "column" : "row",
                height: isMobile
                    ? "38rem"
                    : isDesktop || isTablet
                        ? "35rem"
                        : "40rem",

            }}

            style={{

                marginLeft: isMobile
                    ? "1rem"
                    : isDesktop || isTablet
                        ? "2rem"
                        : "2rem",
                marginRight: isMobile
                    ? "1rem"
                    : isDesktop || isTablet
                        ? "2rem"
                        : "2rem",
                marginBottom: "2rem",
                backgroundColor: get_color(data.order),
            }}>

            <div style={{
                textAlign: "center",
                alignItems: "center",
                justifyItems: "center",
                display: "flex",
                height: "100%",
                paddingLeft: isMobile
                    ? "2rem"
                    : isDesktop || isTablet
                        ? "5rem"
                        : "15rem",
                paddingRight: isMobile
                    ? "2rem"
                    : isDesktop || isTablet
                        ? "5rem"
                        : "15rem",

            }}>

                <div>

                    <Typography variant="h5"

                                sx={{
                                    color: "#1F1F1F",
                                }}>
                        <blockquote style={{display: "inline-block"}}>
                            {data.text}

                        </blockquote>
                    </Typography>


                    <div style={{
                            display: "inline-flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyItems: "center",
                            marginTop: "3rem"

                        }}>
                            <Avatar {...stringAvatar(data.by)} />
                            <div style={{
                                display: "grid",
                                marginLeft: ".5rem",
                                textAlign: "start"
                            }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1F1F1F"
                                    }}>{data.by}</Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontSize: ".5rem",
                                        color: "#606060"
                                    }}></Typography>

                            </div>
                        </div>

                </div>
            </div>

        </Grid>
    )
}