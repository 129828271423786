import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  styled,
} from "@mui/material";
import { parseHTML } from "../../../service/utils";

interface IDetail {
  title: string;
  body: string;
}

interface IDetailAccordionProps {
  expandedDetail: string | boolean;
  detailNumber: string;
  detail: IDetail;
  isMobile: boolean;
  onChange: () => void;
  isManuallyExpanded: boolean;
  hideProgressBar: boolean;
}

const StyledAccordion = styled(Accordion)(() => ({
  "&::before": {
    height: "0",
  },
  boxShadow: "none",
  borderRadius: "0px !important",
  backgroundColor: "transparent",
}));

const ProgressBar = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  animation: "progress-bar 3s linear",
  height: "2px",
}));

const DetailAccordion = ({
  expandedDetail,
  detailNumber,
  detail,
  onChange,
  isMobile,
  isManuallyExpanded,
  hideProgressBar
}: IDetailAccordionProps) => {
  const expanded = expandedDetail === detailNumber;
  return (
    <>
      {expanded && !isManuallyExpanded && !hideProgressBar? (
        <ProgressBar></ProgressBar>
      ) : (
        <div style={{ height: "1px", backgroundColor: "#ECECEC" }}></div>
      )}
      <StyledAccordion
        expanded={expandedDetail === detailNumber}
        onChange={onChange}
        disableGutters={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fontSize: isMobile ? "20px" : "28px" }}color="primary" />}
          sx={{
            padding: "4px 0px"
          }}
          aria-controls={`${detailNumber}-content`}
          id={`${detailNumber}-header`}
        >
          <Typography
            variant="body1"
            textAlign="left"
            color={"#1F1F1F"}
          >
            {detail.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0px",
            marginBottom: isMobile ? "16px" : "20px",
          }}
        >
          <Typography
            variant="body1"
            textAlign="left"
            color={"#606060"}
          >
            {parseHTML(detail.body)}
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
    </>
  );
};

export default DetailAccordion;
