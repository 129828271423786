import { useState } from "react";
import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import KinaryFooter from "../KinaryFooter";
import background from "../../assets/download_bg.jpg";
import downloadBanner from "../../assets/download-banner.webp";
import appStore from "../../assets/download-app-store.svg";
import playStore from "../../assets/download-play-store.svg";
import allContent from "../../content/en.json";
import { Link } from "react-router-dom";
import Layout from "../layout";
import CaregiverTrainingModal from "../Caregiver/CaregiverTrainingModal";
import { parseHTML } from "../../service/utils";
import { motion, Variants } from "framer-motion";

export interface IDownloadProps {
  matches: boolean;
}

const content = allContent.download;

export default function Download({
  matches,
}: IDownloadProps) {
  const [isCaregiveModalOpen, setIsCaregiverModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isExtraLargeDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const cardVariants: Variants = {
    offscreen: {
      y: -150,
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "tween",
        duration: 0.8,
      },
    },
  };
  const getDownloadImgSize = () => {
    if (isExtraLargeDesktop) {
      return {
        paddingTop: "100px",
        paddingBottom: "106px",
        width: "891px",
        height: "381px",
      };
    }
    if (isLargeDesktop) {
      return {
        paddingTop: "100px",
        paddingBottom: "123px",
        width: "844px",
        height: "361px",
      };
    }
    if (isDesktop) {
      return {
        paddingTop: "80px",
        paddingBottom: "149px",
        width: "638px",
        height: "273px",
      };
    }
    if (isTablet) {
      return {
        paddingTop: "120px",
        paddingBottom: "250px",
        width: "550px",
        height: "235px",
      };
    }
    return {
      paddingTop: "80px",
      paddingBottom: "145px",
      width: "356px",
      height: "151px",
    };
  };
  return (
    <>
      <CaregiverTrainingModal
        setIsCaregiverModalOpen={setIsCaregiverModalOpen}
        isCaregiveModalOpen={isCaregiveModalOpen}
        matches={matches}
      />
      <Layout
        setIsCaregiverModalOpen={setIsCaregiverModalOpen}
        matches={matches}
        page={"download"}
      >
        <Grid
          container
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            overflow: "auto",
            backgroundImage: `url(${background})`,
            backgroundAttachment: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundColor: "#FEE5D4",
          }}
        >
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            sx={{
              paddingTop: getDownloadImgSize().paddingTop,
              paddingBottom: getDownloadImgSize().paddingBottom,
            }}
          >
            <motion.div
          className="card-container"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.5 }}
      >
            <Grid
              sx={{
                width: getDownloadImgSize().width,
                height: getDownloadImgSize().height,
              }}
            >
              <motion.img
              variants={cardVariants}
                src={downloadBanner}
                style={{
                  width: "100%",
                  height: "auto",
                }}
                loading="lazy"
                alt="Download Banner"
              />
            </Grid>
            </motion.div>
            <Grid
              textAlign="center"
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
              sx={{
                margin: isMobile ? "60px 0 24px" : "24px 0 20px",
                px: "40px",
              }}
            >
              <Typography variant="h1" sx= {{ display: isMobile ? "block" : "flex", flexDirection: "column", marginBottom: isMobile ? "24px" : "20px"}}>
                {parseHTML(content.title)}
              </Typography>
              <Typography
                variant="body1"
                width={isMobile ? "100%" : isDesktop || isTablet ? "min(62%, 600px)" : "min(70%, 600px)"}
              >
                {content.description}
              </Typography>
            </Grid>
            <Grid
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              sx={{
                gap: isMobile ? "12px" : "17px",
                justifyContent: "center",
                "& > img": {
                  width: "130px",
                  height: "41px",
                  cursor: "pointer",
                },
              }}
            >
              <Link to={allContent.common.ios_store_link}>
                <img src={appStore} alt="App store icon" role="link" />
              </Link>
              <Link to={allContent.common.android_store_link}>
                <img src={playStore} alt="Google play store" role="link" />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <KinaryFooter
          matches={matches}
        />
      </Layout>
    </>
  );
}
