import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  Input,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import logo from "../../assets/kinary_footer_logo.svg";
import appleStoreLogo from "../../assets/apple-store.webp";
import googleStoreLogo from "../../assets/google-store.webp";
import { ReactComponent as Fb } from "../../assets/facebook.svg";
import copyright from "../../assets/copyright.svg";
import roundChevron from "../../assets/ic_round-chevron-left.svg";
import arrowTr from "../../assets/iconoir_arrow-tr.svg";
import { ReactComponent as Insta } from "../../assets/instagram.svg";
import { ReactComponent as X } from "../../assets/ri_twitter-x-fill.svg";
import * as React from "react";
import allContent from "../../content/en.json";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { setSubscriberEmail } from "../../service";

export interface IKinaryFooterProps {
  matches: boolean;
}

const { common, footer } = allContent;

export default function KinaryFooter({
  matches,
}: IKinaryFooterProps) {
  const [email, setEmail] = React.useState("");
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const [isValidEmail, setIsValidEmail] = useState(true);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(
    common.invalid_email_message
  );
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  useEffect(() => {
    if (isSubscribed) {
      setTimeout(() => {
        setIsSubscribed(false);
      }, 1000)
    }
  }, [isSubscribed]);

  const onSubscribeHandle = () => {
    const isEmailValid = emailRegex.test(email);
    setIsValidEmail(email === "" || isEmailValid);
    if (!email || !isEmailValid) return;

    setIsLoading(true);
    setSubscriberEmail(email)
      .then((res) => {
        if (res.ok) {
          setEmail("");
          return res.json();
        } else {
          console.log("error");
          setIsValidEmail(false);
          setErrorMessage(common.server_error_message);
        }
      })
      .then(() => {
        setIsLoading(false);
        setIsSubscribed(true);
      })
      .catch(() => {
        setIsLoading(false);
        setIsValidEmail(false);
        setErrorMessage(common.server_error_message);
      });
  };
  return (
    <Grid
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
        padding: matches ? "40px 20px" : "48px 120px",
        backgroundColor: "#083B33",
      }}
    >
      <Grid
        sx={{
          maxWidth: "1274px",
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
          width: "100%",
        }}
      >
        <Grid
          display="flex"
          flexDirection={matches || isDesktop ? "column" : "row"}
          justifyContent={matches || isDesktop ? "center" : "space-between"}
          flexWrap={"nowrap"}
          gap={"24px"}
        >
          <Grid
            display={"flex"}
            justifyContent={
              isTablet || isDesktop ? "space-evenly" : "space-between"
            }
            flexDirection={isMobile ? "column" : "row"}
          >
            <Grid
              display="flex"
              flexDirection={"column"}
              alignItems={isMobile ? "center" : "flex-start"}
              marginRight={isMobile || isTablet ? "0" : "125px"}
              marginBottom={isMobile ? "40px" : "0"}
            >
              <Grid width="157px" height="57px">
                <img width="100%" height="auto" src={logo} alt="Kinary logo" />
              </Grid>
              <Grid sx={{ margin: "12px 0 28px" }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#DFDFDF",
                    display: "block",
                    textAlign: matches ? "center" : "left",
                  }}
                >
                  {footer.tagline}
                </Typography>
              </Grid>
              <Grid
                display="flex"
                flexDirection={matches ? "column" : "row"}
                justifyContent="space-between"
                sx={{
                  alignItems: matches ? "center" : null,
                }}
              >
                <Grid
                  display="flex"
                  flexDirection={isTablet || isDesktop ? "column" : "row"}
                  alignItems="flex-end"
                  gap="12px"
                  sx={() => {
                    let styleObject: any = {
                      "& > svg": {
                        width: "24px",
                        height: "24px",
                        marginBottom: !matches ? "8px" : null,
                        "& > path": {
                          fill: "#ffffff",
                        },
                        cursor: "pointer",
                      },
                    };
                    if (matches) {
                      styleObject.maxWidth = "300px";
                      styleObject.flexWrap = "wrap";
                      styleObject.justifyContent = "center";
                    }
                    return styleObject;
                  }}
                >
                  <Link
                    to={common.ios_store_link}
                    target="_blank"
                    component={RouterLink}
                  >
                    <img
                      width="129"
                      height="41"
                      src={appleStoreLogo}
                      alt="download from apple store"
                    />
                  </Link>
                  <Link
                    to={common.android_store_link}
                    target="_blank"
                    component={RouterLink}
                  >
                    <img
                      width="129"
                      height="41"
                      src={googleStoreLogo}
                      alt="download from google play store"
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              display={"flex"}
              textAlign={"left"}
              flexDirection={
                isMobile || isTablet || isDesktop ? "column" : "row"
              }
              gap={isMobile ? "20px" : isTablet || isDesktop ? "42px" : "0"}
            >
              <Grid
                display="flex"
                gap={matches ? "30px" : "40px"}
                rowGap="20px"
                flexDirection={"column"}
                alignItems={"flex-start"}
                marginRight={isMobile ? "0" : "45px"}
                sx={{
                  "& span": { color: "#ffffff !important", cursor: "pointer" },
                  fontSize: matches ? "12px" : "16px",
                  flexWrap: matches ? "wrap" : null,
                }}
              >
                {!isMobile && (
                  <Typography variant="h6" color={"#FEE5D4"}>
                    {footer.resources}
                  </Typography>
                )}
                {footer.links.map((link, index) => {
                  if (link.openContactUs) {
                    return (
                      <Link
                        key={`${link.label}_${index}`}
                        display={"flex"}
                        justifyContent={"space-between"}
                        width={"100%"}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          key={link.label}
                          variant={"subtitle1"}
                          color={"#FFFFFF"}
                          onClick={() => navigate("/support")}
                        >
                          {link.label}
                        </Typography>
                        {isMobile && (
                          <Grid
                            key={index}
                            marginRight={!link.isExternal ? "3px" : "0"}
                          >
                            <img
                              src={link.isExternal ? arrowTr : roundChevron}
                              width={"100%"}
                              height={"auto"}
                              alt={"copyright"}
                              loading="lazy"
                            />
                          </Grid>
                        )}
                      </Link>
                    );
                  }
                  return (
                    <Grid
                      key={index}
                      display={"flex"}
                      justifyContent={"space-between"}
                      width={"100%"}
                    >
                      <Link
                        key={link.label}
                        component={RouterLink}
                        to={link.url}
                        target={link.url.startsWith("/") ? "_self" : "_blank"}
                        sx={{
                          textDecorationColor: "white",
                        }}
                      >
                        <Typography variant={"subtitle1"} color={"#FFFFFF"}>
                          {link.label}
                        </Typography>
                      </Link>
                      {isMobile && (
                        <Grid marginRight={!link.isExternal ? "3px" : "0"}>
                          <img
                            src={link.isExternal ? arrowTr : roundChevron}
                            width={"100%"}
                            height={"auto"}
                            alt={"copyright"}
                            loading="lazy"
                          />
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
              <Grid
                display="flex"
                gap={matches ? "30px" : "40px"}
                rowGap="12px"
                flexDirection={"column"}
                alignItems={"flex-start"}
                sx={{
                  "& span": { color: "#ffffff !important", cursor: "pointer" },
                  fontSize: matches ? "12px" : "16px",
                  flexWrap: matches ? "wrap" : null,
                }}
              >
                {!isMobile && (
                  <Typography variant="h6" color={"#FEE5D4"}>
                    {footer.learn}
                  </Typography>
                )}
                <Grid
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Link
                    key={footer.blog_link.label}
                    component={RouterLink}
                    to={footer.blog_link.url}
                    target={
                      footer.blog_link.url.startsWith("/") ? "_self" : "_blank"
                    }
                    sx={{
                      textDecorationColor: "white",
                    }}
                  >
                    <Typography variant={"subtitle1"} color={"#FFFFFF"}>
                      Blog
                    </Typography>
                  </Link>
                  {isMobile && (
                    <Grid>
                      <img
                        src={
                          footer.blog_link.isExternal ? arrowTr : roundChevron
                        }
                        width={"100%"}
                        height={"auto"}
                        alt={"copyright"}
                        loading="lazy"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid
              sx={{
                textAlign: "left",
                maxWidth: isTablet || isDesktop ? "70%" : "auto",
                margin: matches || isDesktop ? "70px auto 0" : null,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#FEE5D4",
                  textAlign: isTablet ? "center" : "left",
                }}
              >
                {footer.subscribe_msg}
              </Typography>
              <Grid
                sx={{
                  marginTop: "12px",
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "20px",
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <Grid>
                  <Input
                    error={!isValidEmail}
                    placeholder={footer.email_id_placeholder}
                    disableUnderline
                    type="email"
                    value={email}
                    onChange={(event) => {
                      // Validate email format using regex
                      setEmail(event.target.value);
                    }}
                    sx={{
                      marginBottom: "0px",
                      width: "316px",
                      border: "none",
                      fontWeight: "400",
                      height: "46px",
                      color: "#1F1F1F",
                      
                      fontSize:
                      isMobile
                        ? "16px !important"
                        : "18px !important",
                    lineHeight:
                      isMobile
                        ? "22px !important"
                        : "24px !important",
                    letterSpacing:
                      isMobile
                        ? "0.032px !important"
                        : "0.036px !important",
                    "&.Mui-error": {
                      border: "1px solid #FF7272",
                    },
                    "& ::placeholder": {
                      fontWeight: 400,
                      fontSize: 
                        isMobile
                          ? "16px !important"
                          : "18px !important",
                      lineHeight: 
                        isMobile
                          ? "22px !important"
                          : "24px !important",
                      letterSpacing:
                        isMobile
                          ? "0.032px !important"
                          : "0.036px !important",
                      color: "#8D8D8D !important",
                    },
                    }}
                  />
                  {!isValidEmail && (
                    <Grid
                    sx={{
                      color: "#FF7272",
                      fontFamily: "Source Sans Pro",
                      fontSize: "18px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      marginTop:"8px",
                      letterSpacing: "0.036px",
                    }}
                    >
                      {errorMessage}
                    </Grid>
                  )}
                </Grid>
                <Button
                  disableTouchRipple
                  variant="contained"
                  onClick={onSubscribeHandle}
                >
                  {isLoading ? (
                    <CircularProgress size={30} color="inherit" />
                  ) : isSubscribed ? (
                    <>
                      {common.subscribed}
                      <DoneIcon sx={{ marginLeft: "3px" }} />
                    </>
                  ) : (
                    common.subscribe
                  )}
                </Button>
              </Grid>
            </Grid>
          )}
          
          {(isTablet || isDesktop) && (
            <Grid display={"flex"} gap={"16px"} margin={"48px auto"}>
              <Link
                to={footer.insta_link}
                target="_blank"
                component={RouterLink}
                sx={{
                  width: "32px",
                  height: "32px",
                }}
              >
                <Insta />
              </Link>
              <Link
                to={footer.x_link}
                target="_blank"
                sx={{
                  width: "32px",
                  height: "32px",
                }}
                component={RouterLink}
              >
                <X />
              </Link>
              <Link
                to={footer.fb_link}
                target="_blank"
                sx={{
                  width: "32px",
                  height: "32px",
                }}
                component={RouterLink}
              >
                <Fb />
              </Link>
            </Grid>
          )}
        </Grid>
        <Divider
          variant="fullWidth"
          sx={{
            borderWidth: "1px",
            borderColor: "rgba(255, 255, 255, 0.40)",
            marginTop: isMobile
              ? "32px"
              : isTablet || isDesktop
              ? "0"
              : "109px",
            marginBottom: isMobile ? "32px" : "22px",
            justifyContent: "space-between",
          }}
        />

        <Grid
          display="flex"
          flexDirection={matches || isDesktop ? "column" : "row"}
          sx={{
            "& span": { cursor: "pointer" },
            gap: matches || isDesktop ? "12px" : "20px",
          }}
          justifyContent={"space-between"}
          alignItems={isMobile ? "flex-start" : "center"}
        >
          <Grid
            display={"flex"}
            flexDirection={isTablet || isDesktop ? "column" : "row"}
            gap={"20px"}
            alignItems={"center"}
          >
            {!isMobile && (
              <Grid order={matches || isDesktop ? 2 : 1}>
                <img
                  src={copyright}
                  width={"100%"}
                  height={"auto"}
                  alt={"copyright"}
                  loading="lazy"
                />
              </Grid>
            )}
            <Grid
              order={matches || isDesktop ? 1 : 2}
              display="flex"
              justifyContent="space-evenly"
              gap={isMobile ? "12px" : "24px"}
              flexDirection={isMobile ? "column" : "row"}
            >
              {footer.doc_links.map((link) => {
                return (
                  <Link
                    key={link.label}
                    to={link?.url}
                    component={RouterLink}
                    target={link?.url?.startsWith("/") ? "_self" : "_blank"}
                    sx={{
                      textDecorationColor: "rgba(255, 255, 255, 0.5)",
                      color: "rgba(255, 255, 255, 0.5)",
                    }}
                  >
                    <Typography
                      variant={"subtitle1"}
                      color="rgba(255, 255, 255, 0.5)"
                      textAlign={"left"}
                    >
                      {link.label}
                    </Typography>
                  </Link>
                );
              })}
            </Grid>
          </Grid>
          {!(isMobile || isTablet || isDesktop) && (
            <Grid display={"flex"} gap={"16px"}>
              <Link
                to={footer.insta_link}
                target="_blank"
                component={RouterLink}
                sx={{
                  width: "32px",
                  height: "32px",
                }}
              >
                <Insta />
              </Link>
              <Link
                to={footer.x_link}
                target="_blank"
                sx={{
                  width: "32px",
                  height: "32px",
                }}
                component={RouterLink}
              >
                <X />
              </Link>
              <Link
                to={footer.fb_link}
                target="_blank"
                sx={{
                  width: "32px",
                  height: "32px",
                }}
                component={RouterLink}
              >
                <Fb />
              </Link>
            </Grid>
          )}
        </Grid>

        {isMobile && (
          <Grid>
            <Divider
              variant="fullWidth"
              sx={{
                borderWidth: "1px",
                borderColor: "rgba(255, 255, 255, 0.40)",
                marginTop: "32px",
                marginBottom: "20px",
              }}
            />
            <Grid
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid
                sx={{
                  width: "147px",
                  height: "22px",
                }}
              >
                <img
                  src={copyright}
                  width={"100%"}
                  height={"auto"}
                  alt={"copyright"}
                  loading="lazy"
                />
              </Grid>
              <Grid display={"flex"} gap={"16px"}>
                <Link
                  to={footer.insta_link}
                  target="_blank"
                  component={RouterLink}
                  sx={{
                    width: "32px",
                    height: "32px",
                  }}
                >
                  <Insta />
                </Link>
                <Link
                  to={footer.x_link}
                  target="_blank"
                  sx={{
                    width: "32px",
                    display: 'flex',
                    height: "32px",
                   
                  }}
                  component={RouterLink}
                >
                  <div style={{
                     background: "white",
                    opacity :"0.6",
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}></div>
                  <X />
                </Link>
                <Link
                  to={footer.fb_link}
                  target="_blank"
                  sx={{
                    width: "32px",
                    height: "32px",
                  }}
                  component={RouterLink}
                >
                  <Fb />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
