import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { parseHTML } from "../../service/utils";
import { motion, Variants } from "framer-motion";

export interface IKinaryOverviewProps {
  matches: boolean;
  background: string;
  order: string;
  image: string;
  content: any;
}


export default function KinaryOverview({
  matches,
  background,
  image,
  order,
  content,
}: IKinaryOverviewProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isExtraLargeDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  let imageSize = {
    imageWidth: "",
    imageHeight: "",
    contentWidth: "",
  };
  const cardVariants: Variants = {
    offscreen: {
      y: -150,
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      y: matches ? 0 : 50,
      transition: {
        type: "tween",
        duration: 0.8,
      },
    },
  };
  const getSizes = () => {
    if (content.id === "1") {
      if (isExtraLargeDesktop) {
        return {
          imageWidth: "699px",
          imageHeight: "655px",
          contentWidth: "31%",
        };
      }
      if (isLargeDesktop) {
        return {
          imageWidth: "547px",
          imageHeight: "513px",
          contentWidth: "34%",
        };
      }
      if (isDesktop) {
        return {
          imageWidth: "446px",
          imageHeight: "418px",
          contentWidth: "49%",
        };
      }
      if (isTablet) {
        return {
          imageWidth: "400px",
          imageHeight: "375px",
          contentWidth: "65%",
        };
      }
      if (isMobile) {
        return {
          imageWidth: "301px",
          imageHeight: "282px",
          contentWidth: "100%",
        };
      }
    }
    if (content.id === "2") {
      if (isExtraLargeDesktop) {
        return {
          imageWidth: "670px",
          imageHeight: "648px",
          contentWidth: "31%",
        };
      }
      if (isLargeDesktop) {
        return {
          imageWidth: "565px",
          imageHeight: "546px",
          contentWidth: "33%",
        };
      }
      if (isDesktop) {
        return {
          imageWidth: "465px",
          imageHeight: "450px",
          contentWidth: "49%",
        };
      }
      if (isTablet) {
        return {
          imageWidth: "395px",
          imageHeight: "382px",
          contentWidth: "65%",
        };
      }
      if (isMobile) {
        return {
          imageWidth: "309px",
          imageHeight: "299px",
          contentWidth: "100%",
        };
      }
    }
    if (content.id === "3") {
      if (isExtraLargeDesktop) {
        return {
          imageWidth: "504px",
          imageHeight: "714px",
          contentWidth: "31%",
        };
      }
      if (isLargeDesktop) {
        return {
          imageWidth: "461px",
          imageHeight: "654px",
          contentWidth: "34%",
        };
      }
      if (isDesktop) {
        return {
          imageWidth: "351px",
          imageHeight: "496px",
          contentWidth: "49%",
        };
      }
      if (isTablet) {
        return {
          imageWidth: "307px",
          imageHeight: "435px",
          contentWidth: "65%",
        };
      }
      if (isMobile) {
        return {
          imageWidth: "290px",
          imageHeight: "411px",
          contentWidth: "100%",
        };
      }
    }
    if (content.id === "4") {
      if (isExtraLargeDesktop) {
        return {
          imageWidth: "704px",
          imageHeight: "660px",
          contentWidth: "31%",
        };
      }
      if (isLargeDesktop) {
        return {
          imageWidth: "558px",
          imageHeight: "524px",
          contentWidth: "34%",
        };
      }
      if (isDesktop) {
        return {
          imageWidth: "449px",
          imageHeight: "421px",
          contentWidth: "49%",
        };
      }
      if (isTablet) {
        return {
          imageWidth: "429px",
          imageHeight: "403px",
          contentWidth: "65%",
        };
      }
      if (isMobile) {
        return {
          imageWidth: "298px",
          imageHeight: "280px",
          contentWidth: "100%",
        };
      }
    }
    return imageSize;
  };

  const matches1 = isMobile || isTablet || isDesktop;

  return (
    <Grid
      sx={{
        background: background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: !matches1 ? "720px" : "auto",
        paddingY: matches1 ? "78px" : "0",
        paddingX: matches1 ? "32px" : "0",
      }}
    >
      <motion.div
        className="card-container"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.5 }}
      >
        <Grid
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={matches1 ? "column" : "row"}
        >
          <Grid
            sx={{
              width: getSizes().imageWidth,
              height: getSizes().imageHeight,
              order: matches1 ? "1" : order === "normal" ? "1" : "2",
            }}
          >
            <motion.img
              variants={cardVariants}
              alt={content.imageAlt}
              src={content.image}
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: getSizes().contentWidth,
              order: matches1 ? "2" : order === "normal" ? "2" : "1",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                textAlign: !matches1 ? "left" : "center",
              }}
            >
              {parseHTML(content.title)}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginTop: "24px",
                textAlign: !matches1 ? "left" : "center",
              }}
            >
              {content.description}
            </Typography>
          </Grid>
        </Grid>
      </motion.div>
    </Grid>
  );
}
