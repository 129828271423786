import KinaryFooter from "../KinaryFooter";
import Layout from "../layout";
import { Grid } from "@mui/material";

interface IDCWProofProps {
  matches: boolean;
}


function DCWProof(props: IDCWProofProps) {
  const {  matches } = props;
  return (
    <Layout matches={matches}>
      <Grid>
      <iframe className="airtable-embed" title="dcwProof" src='https://airtable.com/embed/shrOHB1E0X84BnZLW?backgroundColor=red frameborder="0" onmousewheel=""' width="100%" height="533" style={{background: "transparent", border: "1px solid #ccc"}}></iframe>
      </Grid>
      <KinaryFooter {...{ matches}} />
    </Layout>
  );
}

export default DCWProof;
