import KinaryFooter from "../KinaryFooter";
import Layout from "../layout";
import { Grid } from "@mui/material";

interface ITrainingLoginProps {
  matches: boolean;
}


function TrainingLogin(props: ITrainingLoginProps) {
  const {  matches } = props;
  return (
    <Layout matches={matches}>
      <Grid marginY={"30px"}>
      <iframe src='https://www.caregivertraininguniversity.com/LoginFrame.aspx' title="training-login" style={{background: "transparent", border: "1px solid #ccc"}} width="500" height="300" ></iframe>
      </Grid>
      <KinaryFooter {...{ matches }} />
    </Layout>
  );
}

export default TrainingLogin;
