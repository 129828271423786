import { Grid, Typography } from "@mui/material";
import background from "../../assets/testimonial-bg.svg";
import Carousel from "react-multi-carousel";
import { testimonialOptions } from "../../content/options";
import "react-multi-carousel/lib/styles.css";
import KinaryTestimonialCard from "./KinaryTestimonialCard";
import allContent from "../../content/en.json";

export interface IKinaryTestimonialProps {
  matches: boolean;
}

const {
  home: { testimonials },
} = allContent;

function IndicatorIcon(props: any) {
  return (
    <div
      style={{
        backgroundColor: props.active ? "#083B33" : "#C1C1C1",
        height: "9px",
        width: props.active ? "26px" : "12px",
        borderRadius: "100px",
        marginRight: "4px",
        cursor: "pointer",
      }}
      onClick={() => props.onClick()}
    ></div>
  );
}

const CustomLeftArrow = ({ onClick }: any) => (
  <button
    aria-label="Go to previous slide"
    className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
    onClick={() => onClick()}
  ></button>
);
const CustomRightArrow = ({ onClick }: any) => (
  <button
    aria-label="Go to next slide"
    className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
    onClick={() => onClick()}
  ></button>
);

export default function KinaryTestimonial({
  matches,
}: IKinaryTestimonialProps) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      partialVisibilityGutter: 5,
    },
    tablet: {
      breakpoint: { max: 1023, min: 500 },
      items: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 499, min: 0 },
      items: 1,
      partialVisibilityGutter: 70,
    },
  };
  return (
    <Grid
      display={"flex"}
      flexDirection="column"
      gap={matches ? "0px" : "16px"}
      sx={{
        overflowX: "hidden",
        backgroundImage: `url(${background})`,
        backgroundAttachment: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        py: matches ? "40px" : "80px",
      }}
    >
      <Grid sx={{ px: "20px" }}>
        <Typography
          variant="h3"
        >
          {testimonials.title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginTop: matches ? "16px" : "24px",
          }}
        >
          {testimonials.description}
        </Typography>
      </Grid>
      <Grid
        sx={{
          paddingLeft: matches ? "10px" : "100px",
        }}
      >
        <Carousel
          className={matches ? "container-mobile" : "container-desktop"}
          swipeable={true}
          draggable={false}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          arrows={true}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={false}
          centerMode={false}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass={matches ? "dot-list-mobile" : "dot-list-desktop"}
          itemClass="item-gap"
          partialVisible={true}
          customDot={<IndicatorIcon />}
        >
          {testimonialOptions.map((ele, index) => (
            <KinaryTestimonialCard key={index} matches={matches} {...ele} />
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
}
