import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import pattern from "../../assets/kinary-pattern-1.svg";
import allContent from "../../content/en.json";
import { parseHTML } from "../../service/utils";

interface IKinaryBaseProps {
  matches: boolean;
}

const {
  home: { base },
} = allContent;

const KinaryBase = ({ matches }: IKinaryBaseProps) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  return (
    <Grid
      item
      flex={1}
      display="flex"
      flexDirection="column"
      sx={{
        backgroundSize: "cover",
        backgroundImage: `url(${pattern})`,
        alignItems: "center",
        justifyContent: "center",
        py: isTablet ? "60px" : "80px",
        px: isMobile ? "32px" : "0",
      }}
    >
      <Typography
        variant="h2"
      >
        {parseHTML(base.title)}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginTop: matches ? "24px" : "28px",
          maxWidth: isMobile ? "89%" : (isTablet || isDesktop ? "min(85%, 637px)" : "min(70%, 1007px)")
        }}
      >
        {base.description}
      </Typography>
    </Grid>
  );
};

export default KinaryBase;
