import TestimonialCard from "./TestimonialCards";
import {CSSProperties, useState} from "react";
import {Grid, IconButton, Typography, useMediaQuery, useTheme} from "@mui/material";
import {IKinaryTestimonialProps} from "../KinaryTestimonial";
import allContent from "../../content/en.json";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
    East,
    West
} from "@mui/icons-material";

import {Carousel} from 'react-responsive-carousel';

const {
    testimonials,
} = allContent.paidCaregiver;


const {
    testimonialsOther,
} = allContent.familyCaregiver;

export default function Testimonials({
                                         matches,
                                     }: IKinaryTestimonialProps) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));

    const url = window.location.href;

    // eslint-disable-next-line eqeqeq
    let list_of_cards = []
    if (url.indexOf("dcw_training") !== -1) {
        list_of_cards = testimonials.testimonis.map((object, i) => {
            return <TestimonialCard data={object} matches={matches}/>;
        });
    } else {
        list_of_cards = testimonialsOther.testimonis.map((object, i) => {
            return <TestimonialCard data={object} matches={matches}/>;
        });
    }


    const arrowStyles: CSSProperties = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
    };

    const indicatorStyles: CSSProperties = {
        background: '#fff',
        borderRadius: 10,
        width: 25,
        height: 8,
        display: 'inline-block',
        margin :'0 8px'
    };


    const [activeSlide, setActiveSlide] = useState(0);


    return (

        <Grid
            display={"flex"}
            flexDirection="column"
            gap={matches ? "0px" : "16px"}
            sx={{
                overflowX: "hidden",
                backgroundAttachment: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                py: matches ? "40px" : "80px",
            }}
        >
            <Grid sx={{px: "20px"}}>
                <Typography
                    variant="h2"
                >
                    {testimonials.title}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginTop: matches ? "16px" : "24px",
                        marginBottom: matches ? "16px" : "24px"
                    }}
                >
                    {testimonials.description}
                </Typography>
            </Grid>
            <Grid
                sx={{
                    paddingLeft: matches ? "0px" : "0px",
                }}
            >
                <div
                    style={{
                        position: "relative",
                    }}
                >
                    <Carousel
                        centerSlidePercentage={isMobile ? 100 : 80}
                        showArrows={true}
                        showThumbs={false}
                        centerMode={true}
                        selectedItem={1}
                        showStatus={false}
                        infiniteLoop={true}
                        autoPlay={true}
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                                <IconButton type="button" onClick={onClickHandler} title={label}
                                            style={{
                                                ...arrowStyles,
                                                top: isMobile ? "92%" : "43%",
                                                left:isMobile ? "15%" : (isTablet ? "10%" : "20%"),
                                                backgroundColor: "white",
                                                padding: "25px"
                                            }}>
                                    <West/>
                                </IconButton>
                            )
                        }

                        renderArrowNext={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                                <IconButton type="button" onClick={onClickHandler} title={label}
                                            style={{
                                                ...arrowStyles,
                                                top: isMobile ? "92%" : "43%",
                                                right: isMobile ? "15%" : (isTablet ? "10%" : "20%"),
                                                backgroundColor: "white",
                                                padding: "25px"
                                            }}>

                                    <East/>
                                </IconButton>
                            )
                        }
                        renderIndicator={(onClickHandler, isSelected, index, label) => {
                            if (isSelected) {
                                return (
                                    <li
                                        style={{...indicatorStyles, background: '#083B33'}}
                                        aria-label={`Selected: ${label} ${index + 1}`}
                                        title={`Selected: ${label} ${index + 1}`}
                                    />
                                );
                            }
                            return (
                                <li
                                    style={{...indicatorStyles, background:"#D8D8D8", marginTop:"10px"}}
                                    onClick={onClickHandler}
                                    onKeyDown={onClickHandler}
                                    value={index}
                                    key={index}
                                    role="button"
                                    tabIndex={0}
                                    title={`${label} ${index + 1}`}
                                    aria-label={`${label} ${index + 1}`}
                                />
                            );
                        }}

                    >
                        {list_of_cards}

                    </Carousel>


                </div>
            </Grid>
        </Grid>
    );
}
