import { Grid, Typography } from "@mui/material";
import allContent from "../../content/en.json";
import trainingBanner from "../../assets/WhiteOpacityForeground.png";
import trainingBannerMobile from "../../assets/trainingBannerMobile.png";
import logo from "../../assets/training-program-logo.svg";
import Calgrowslogo from "../../assets/cal-grows-logo.svg";
import CalgrowslogoMobile from "../../assets/cal-grows-logo-mobile.svg";

interface ITrainingBannerSectionProps {
  matches: boolean;
}

const { training_program } = allContent;

function TrainingBannerSection(props: ITrainingBannerSectionProps) {
  const { matches } = props;
  return (
    <Grid sx={{ background: "#FAFAFA" }}>
      <img
        src={matches ? trainingBannerMobile : trainingBanner}
        style={{ width: "100%", height: "auto" }}
        loading="lazy"
        alt="trainingBanner"
      />
      <Grid
        sx={{
          paddingTop: matches ? "28px" : "100px",
          paddingBottom: matches ? "40px" : "80px",
          background: "#FAFAFA",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: matches ? "28px" : "48px",
            lineHeight: matches ? "32px" : "52px",
          }}
          lineHeight={"52px"}
          fontWeight={400}
        >
          {training_program.banner.title}
        </Typography>
        <Typography
          variant="body1"
          color="#606060"
          marginTop={matches ? "8px" : "10px"}
          sx={{
            fontSize: matches ? "16px" : "24px",
            lineHeight: matches ? "18px" : "32px",
          }}
        >
          {training_program.banner.description}
        </Typography>
        <Grid
          container
          paddingY={"42px"}
          gap={matches ? " 16px" : "48px"}
          display="flex"
          flexDirection={matches ? "column" : "row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img
            src={logo}
            loading="lazy"
            alt="Kinary Logo"
            style={{
              height: matches ? "43px": "75px",
              width: matches ? "131px" : "auto",
            }}
          />
          <img
            src={matches ? CalgrowslogoMobile : Calgrowslogo}
            loading="lazy"
            alt="calgrows Logo"
            style={{
              height: matches ? "31px" : "55px",
              width: matches ? "179px" : "auto",
            }}
          />
        </Grid>
        <Typography
          variant="body1"
          marginTop={"40px"}
          sx = {{
            lineHeight: matches ? "20px" : "32px",
            fontSize: matches? "16px" : "24px"
          }}
          margin="0 auto"
          width={matches ? "100%" : "min(70%, 709px)"}
        >
          {training_program.banner.supportText}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default TrainingBannerSection;
