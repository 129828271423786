import {
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField,
  styled,
} from "@mui/material";
import KinaryFooter from "../KinaryFooter";
import allContent from "../../content/en.json";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "@mui/material/Input";
import { useNavigate, useSearchParams } from 'react-router-dom'
import Layout from "../layout";
import { setDeletedAccountDetails } from "../../service";

interface IDeleteUserAccountProps {
  matches: boolean;
  setOpenAlert: (arg0: boolean) => void;
}
const formSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  phone_number: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  reason: Yup.string().required("Required"),
  comments: Yup.string().required("Required"),
  created_on: Yup.string(),
});

const content = allContent.delete_user_account;

const FormInput = styled(Input)(() => ({
  marginBottom: "0",
  fontSize: "16px",
  width: "100%",
}));

const FormFieldLabel = styled(Typography)(() => ({
  fontFamily: "Lora",
  fontSize: "16px",
  lineHeight: "20px",
  paddingBottom: "8px",
}));

const Form = styled("form")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default function DeleteUserAccount({
  matches,
  setOpenAlert
}: IDeleteUserAccountProps) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const fullName = searchParams.get('full_name');
  const phoneNumber = searchParams.get('phone_number');
  const email = searchParams.get('email');
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      name: fullName ?? "",
      phone_number: phoneNumber ?? "",
      email: email ?? "",
      reason: "Hard to use",
      comments: "",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      setDeletedAccountDetails({ ...values })
      .then((res) => {
        if (res.ok) {
          navigate('/ ');
        } else {
          console.log('error');
        }
      })
      .then(() => {
        setOpenAlert(true);
      })
      .catch(() => console.log('error'));
    },
  });
  return (
    <Layout matches={matches}>
      <Grid display="flex" flexDirection="column" alignItems="center" justifyContent="center" paddingTop="75px">
        <Grid maxWidth={matches ? "80%" : "max(70%, 770px)"}>
          <Typography variant="h3" fontSize={matches ? "32px" : "48px"}>
          {content.title}
          </Typography>
          <Typography
            variant="body1"
            fontSize={matches ? "16px" : "24px"}
            paddingTop="20px"
            sx={{ lineHeight: matches ? "24px" : "30px", pt: "12px" }}
          >
            {content.description}
          </Typography>
        </Grid>
        <Grid display="flex" justifyContent="center"  marginTop="30px" marginBottom="30px" alignItems="center" flexDirection="column">
          <Form onSubmit={formik.handleSubmit}>
            <Grid
              display="flex"
              flexDirection="column"
              sx={{
                textAlign: "left",
                paddingTop: matches ? "12px" : "8px",
                width: "100%",
                gap: matches ? "20px" : "28px",
              }}
              minWidth={matches ? "100%" : "max(70%, 400px)"}
            >
              <div>
                <FormFieldLabel variant="body1">
                  {content.fields.name.label}
                </FormFieldLabel>
                <FormInput
                  placeholder={content.fields.name.placeholder}
                  disableUnderline={true}
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
              </div>
              <div>
                <FormFieldLabel variant="body1">
                  {content.fields.phone_number.label}
                </FormFieldLabel>
                <FormInput
                  placeholder={content.fields.phone_number.placeholder}
                  disableUnderline={true}
                  name="phone_number"
                  onChange={formik.handleChange}
                  value={formik.values.phone_number}
                />
              </div>
              <div>
                <FormFieldLabel variant="body1">
                  {content.fields.email.label}
                </FormFieldLabel>
                <FormInput
                  placeholder={content.fields.email.placeholder}
                  type="email"
                  disableUnderline={true}
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </div>
              <div>
                <FormFieldLabel variant="body1">
                  {content.fields.reason.label}
                </FormFieldLabel>
                <Select
                  labelId="select-reason"
                  displayEmpty
                  value={formik.values.reason}
                  name="reason"
                  onChange={formik.handleChange}
                  sx={{
                    width: "100%",
                    height: "36px",
                    fontSize: "16px",
                    fontWeight: "400",
                    borderRadius: "8px",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>{content.fields.reason.placeholder}</em>
                  </MenuItem>
                  {content.fields.reason.options.map((ele, index) => (
                    <MenuItem key={index} value={ele.value}>
                      {ele.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <FormFieldLabel variant="body1">
                  {content.fields.message.label}
                </FormFieldLabel>
                <TextField
                  size="small"
                  placeholder={content.fields.message.placeholder}
                  multiline
                  name="comments"
                  className="textarea-root"
                  onChange={formik.handleChange}
                  value={formik.values.comments}
                  rows={3.5}
                  sx={{
                    width: "100%",
                  }}
                />
              </div>
            <Button
              variant="contained"
              disableTouchRipple
              type="submit"
              disabled={!formik.isValid}
              sx={{
                margin: "auto",
                marginTop: matches ? "20px" : "8px",
              }}
            >
              {content.send}
            </Button>
            </Grid>
          </Form>
        </Grid>
      </Grid>
      <KinaryFooter {...{ matches, setOpenAlert }} />
    </Layout>
  );
}
