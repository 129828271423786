import { Button, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import allContent from "../../content/en.json";
import CalGrowsBadge from "../../assets/CalGrows_Badge.webp";
import { parseHTML } from "../../service/utils";

interface ICaregiverTrainingProgramProps {
  matches: boolean;
  setIsCaregiverModalOpen: (arg0: boolean) => void;
}

const {
  home: { CaregiverTrainingPrograms },
  common,
} = allContent;

function CaregiverTrainingProgram(props: ICaregiverTrainingProgramProps) {
  const { matches, setIsCaregiverModalOpen } = props;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  return (
    <Grid
      sx={{
        background:
          "linear-gradient(180deg, rgba(255, 240, 211, 0.40) 0%, rgba(254, 225, 204, 0.00) 100%);",
        width: "100%",
        paddingY: matches || isDesktop ? "80px" : "120px",
        display: "flex",
        justifyContent: "center",
        paddingX: matches ? "30px" : "0",
      }}
    >
      <Grid
      >
        <Typography
          variant="h2"
          sx={{
            maxWidth: isMobile ? "100%" : "min(67%, 1000px)",
            margin: isMobile ? "0" : "0 auto"
          }}
        >
          {parseHTML(CaregiverTrainingPrograms.title)}
        </Typography>
        <Grid
          sx={{
            marginTop: isMobile ? "44px" : (isTablet? "60px" : "60px"),
            marginBottom: isMobile ? "44px" : (isTablet? "60px" : "42px"),
            marginX: "auto",
            width: matches ? "154px" : "223px",
            height: matches ? "130px" : "188px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            width={"100%"}
            height={"auto"}
            loading="lazy"
            src={CalGrowsBadge}
            alt={CaregiverTrainingPrograms.altImage}
          />
        </Grid>
        <Typography
          variant="body1"
          sx={{
            maxWidth: isMobile ? "100%" : "min(59%, 704px)",
            margin: isMobile ? "0" : "0 auto"
          }}
        >
          {CaregiverTrainingPrograms.registrationMessageWithNoStyle}
        </Typography>
        <Button
        disableTouchRipple
          variant="contained"
          size="large"
          onClick={(event) => {
            event.stopPropagation();
            setIsCaregiverModalOpen(true);
          }}
          sx={{
            marginTop: isMobile ? "24px" : (isTablet ? "32px" : "42px")
          }}
        >
          {common.learn_more}
        </Button>
      </Grid>
    </Grid>
  );
}

export default CaregiverTrainingProgram;
