import { Grid,Link, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link as RouterLink } from "react-router-dom";
import allContent from "../../content/en.json"; 

interface ITrainingLoginMobileProps {
    page: string;
  }
  
const { common } = allContent;
function TrainingLoginMobile({page}: ITrainingLoginMobileProps) {
    return (
        <Grid sx= {{
            paddingX: "20px",
            paddingY: "14px",
            background: "#083B33",
            display: "flex",
            position: "static",
            width: "100%",
            justifyContent: "space-between"
        }}>
            <Typography variant="h6" sx={{
                fontSize: "16px",
                fontFamily: "Source Sans Pro",
                lineHeight: "20px",
                color: "#B8F4E8"
            }}>
                {common.training_program}
            </Typography>
            <Link
          to="/training-login" 
          component={RouterLink} 
          underline="none"
          target="_self"
          color="#B8F4E8"
        >
          <ArrowForwardIcon
            fontSize="small" 
          ></ArrowForwardIcon>
        </Link>
        </Grid>
    )
}
export default TrainingLoginMobile;