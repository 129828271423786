import { Stack, Typography, styled, useMediaQuery, Grid, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import DetailAccordion from "./DetailAccordion";
import macBookPro from "../../../assets/MacBook_Pro.jpg";

const DetailsContainer = styled("div")(() => ({
  background:
    "linear-gradient(270deg, rgba(255, 240, 211, 0.40) 0.56%, rgba(254, 225, 204, 0.00) 99.89%)",
}));

const DetailImage = styled("img")(() => ({
  borderRadius: "16px",
}));

const DETAIL_CHANGE_TIMER_IN_MS = 3000;


function CaregiverDetails(props: {content: any}) {
  const {
    training 
 } = props.content;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isExtraLargeDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const details = training.details.description;
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [expandedDetail, setExpandedDetail] = useState<string | false>(false);
  const [automatedExpand, setAutomatedExpand] = useState(false);
  const [isManuallyExpanded, setIsManuallyExpanded] = useState(false);
  const [hideProgressBar, setHideProgressBar] = useState(false);
  const [autoChangeDetailTimer, setAutoChangeDetailTimer] =
    useState<NodeJS.Timeout | null>(null);

  const handlePanelExpand = (
    panelNumber: string,
    index: number,
    automated?: boolean,
    isManuallyExpanded: boolean = false
  ) => {

    const nextPanel = `detail-${(index + 1) % details.length}`;
    const isSamePanel = expandedDetail === panelNumber;
    autoChangeDetailTimer && clearTimeout(autoChangeDetailTimer);
    setIsManuallyExpanded(isManuallyExpanded);
    if (isSamePanel) {
      setExpandedDetail(false);
    } else {
      setExpandedDetail(panelNumber);
      if (index + 1 < details.length && automated) {
        const timeout = setTimeout(() => {
          handlePanelExpand(nextPanel, index + 1, true);
        }, DETAIL_CHANGE_TIMER_IN_MS);
        setAutoChangeDetailTimer(timeout);
      } else if (automated) {
        setTimeout(() => {
          // setExpandedDetail(false);
          setHideProgressBar(true);
        }, DETAIL_CHANGE_TIMER_IN_MS);
      }
    }
  };

  useEffect(() => {
    if (isIntersecting && !automatedExpand) {
      handlePanelExpand("detail-0", 0, true);
      setAutomatedExpand(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin: "0px" }
    );
    observer.observe((ref as any)?.current);

    return () => observer.disconnect();
  }, [isIntersecting]);
  return (
    <DetailsContainer style={
      { 
        padding: isExtraLargeDesktop || isLargeDesktop ? "120px 0px" : "80px 0px",
        paddingLeft:  isExtraLargeDesktop || isLargeDesktop ? "0" : (isTablet ? "48px" : "20px"),
        paddingRight:  isExtraLargeDesktop || isLargeDesktop ? "0" : (isTablet ? "48px" : "20px")
      }
      }>
      <Typography
        variant="h2"
      >
        {training.details.heading}
      </Typography>
      <div
        style={{
          marginTop: isMobile ? "24px" : "60px",
        }}
      >
        <Stack
          direction={"row"}
          gap={isExtraLargeDesktop ? "119px" : "103px" }
          sx={{ width: isMobile || isTablet ? "100%" : (isExtraLargeDesktop ? "70.8%" : "80.8%"), margin: "0 auto" }}
          justifyContent={"center"}
        >
          <div ref={ref} style={{ flex: "1"}}>
            {training.details.description.map((detail: any, index: number) => (
              <DetailAccordion
                key={index}
                isMobile={isMobile}
                hideProgressBar={hideProgressBar}
                expandedDetail={expandedDetail}
                detailNumber={`detail-${index}`}
                detail={detail}
                isManuallyExpanded={isManuallyExpanded}
                onChange={() =>
                  handlePanelExpand(`detail-${index}`, index, false, true)
                }
              />
            ))}
          </div>
          {isLargeDesktop || isExtraLargeDesktop ? (
            <Grid
              sx= {{
                width: isExtraLargeDesktop ? "613px" : "472px",
                height: isExtraLargeDesktop ? "404px" : "310px"
              }}
            >

              <DetailImage
                height="auto"
                width={"100%"}
                src={macBookPro}
                alt="MacBook Pro 16' - 5th Gen - Silver"
              />
            </Grid>
          ) : (
            <></>
          )}
        </Stack>
      </div>
    </DetailsContainer>
  );
}

export default CaregiverDetails;
