import { Grid } from '@mui/material';
import Header from '../Header';
import KinaryThinBanner from '../KinaryThinBanner';

export interface ILayoutProps {
    matches: boolean;
    page?: string;
}

export default function Layout(props: any) {
    
    return (
        <Grid container display='flex' height="100%">
            {(props.page === "home" || props.page === "download" || props.page === "support") && <KinaryThinBanner setIsCaregiverModalOpen={props.setIsCaregiverModalOpen} matches={props.matches} />}

            <Header {...props} />
            {
                props.children.map((i: any, index: number) => <Grid width="100%" key={index}>
                    {i}
                </Grid>)
            }
        </Grid>
    );
}
