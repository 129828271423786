import blog1 from "../assets/blog1.webp";
import blog2 from "../assets/blog2.webp";
import blog3 from "../assets/blog3.webp";
import group1 from "../assets/group-1.svg";
import group2 from "../assets/group-2.svg";
import group3 from "../assets/group-3.svg";
import group4 from "../assets/group-4.svg";
import group5 from "../assets/group-5.svg";
import group6 from "../assets/group-6.svg";
import overviewImage1 from "../assets/product-image-1.webp";
import overviewImage2 from "../assets/product-image-2.webp";
import overviewImage3 from "../assets/product-image-3.webp";
import overviewImage4 from "../assets/product-image-4.webp";
import { ICapabilityProps } from "../component/KinaryCapability";

export interface Testimonial {
  img_url_dektop: string;
  img_url_mobile: string;
  content: string;
  author: string;
  title: string;
}

export interface IStaticContent {
  heading: string;
  updatedOn: string;
  content: string;
}

export interface IFAQOptions {
  category: string;
  questionSet: Array<{
    question: string;
    answer: string;
  }>;
}

export const capabilitiesData: Array<Omit<ICapabilityProps, "matches">> = [
  {
    description: `Your one-stop assistant to help organize your daily priorities and to know where to focus your attention.`,
    heading: `Dashboard`,
    iconAlt: `Dashboard`,
    iconPath: group1,
  },
  {
    description: `Stay on top of daily tasks and take note of any new observations to ensure care needs are being met.`,
    heading: `Check-Ins`,
    iconAlt: `Check-Ins`,
    iconPath: group2,
  },
  {
    description: `Invite your loved one’s care team to divide and conquer care duties while staying connected.`,
    heading: `Care Squads`,
    iconAlt: `Care Squads`,
    iconPath: group3,
  },
  {
    description: `Easily manage all medication requirements, refills, and most importantly, adherence.`,
    heading: `Medication Manager`,
    iconAlt: `Medication Manager`,
    iconPath: group4,
  },
  {
    description: `Prepare for and document any type of appointment with checklists, question reminders, and notes.`,
    heading: `Appointment Tracker`,
    iconAlt: `Appointment Tracker`,
    iconPath: group5,
  },
  {
    description: `See real-time care tasks organized by squad members and status so everyone involved feels in sync.`,
    heading: `Activity Feed`,
    iconAlt: `Activity Feed`,
    iconPath: group6,
  },
];

export const overViewItems = [
  {
    id: "1",
    title: "Create and manage <i>individual</i> care plans",
    description:
      "Easily document the unique needs of each individual under your care – from medications and meals, to appointments and general activities of daily living.",
    image: overviewImage1,
    imageAlt: "involve others",
  },
  {
    id: "2",
    title: "<i>Involve others</i> and build supportive care squads",
    description:
      "Give secure access to your family, friends, and care professionals to delegate tasks, share instructions, and create an open line of communication and support.",
    image: overviewImage2,
    imageAlt: "Your squad",
  },
  {
    id: "3",
    title: "Ensure <i>quality care</i> gets provided when needed",
    description:
      "Receive reminders of daily tasks and real-time updates on the status of responsibilities to guarantee consistent and quality care of your loved one.",
    image: overviewImage3,
    imageAlt: "quality",
  },
  {
    id: "4",
    title: "Keep track of everything in <i>one central location</i>",
    description:
      "Get full visibility on all aspects of your loved one’s care from the palm of your hand so you can more effectively prioritize needs and make important decisions.",
    image: overviewImage4,
    imageAlt: "loction",
  },
];

export const testimonialOptions: Array<Testimonial> = [
  {
    img_url_dektop:
      `https://source.unsplash.com/random/${"588x244"}?id=` +
      Math.random() * 100,
    img_url_mobile:
      `https://source.unsplash.com/random/${"275x154"}?id=` +
      Math.random() * 100,
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    author: "Temitope Olugbanmu",
    title: "Caregiver",
  },
  {
    img_url_dektop:
      `https://source.unsplash.com/random/${"588x244"}?id=` +
      Math.random() * 100,
    img_url_mobile:
      `https://source.unsplash.com/random/${"275x154"}?id=` +
      Math.random() * 100,
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    author: "Temitope Olugbanmu",
    title: "Caregiver",
  },
  {
    img_url_dektop:
      `https://source.unsplash.com/random/${"588x244"}?id=` +
      Math.random() * 100,
    img_url_mobile:
      `https://source.unsplash.com/random/${"275x154"}?id=` +
      Math.random() * 100,
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    author: "Temitope Olugbanmu",
    title: "Caregiver",
  },
  {
    img_url_dektop:
      `https://source.unsplash.com/random/${"588x244"}?id=` +
      Math.random() * 100,
    img_url_mobile:
      `https://source.unsplash.com/random/${"275x154"}?id=` +
      Math.random() * 100,
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    author: "Temitope Olugbanmu",
    title: "Caregiver",
  },
];

export const privacyPolicyOptions: IStaticContent = {
  heading: "Kinary by Healthipeople Privacy Policy",
  updatedOn: "This policy was updated on January 10, 2024",
  content: `<b>About this Privacy Policy and Our Commitment to Your Privacy</b>

    This Privacy Policy (this "Policy" or these “Terms” ) sets forth the terms upon which Healthipeople Corp (or “Healthipeople”, “Kinary by Healthipeople”, “Kinary”, “we”, “us”, or “our”) collects information from you, our third party end-users (each, an "End-User", “Member”, “you” or “your”), and how we may use and disclose this information when you access our mobile application (the "App") or website located at <a href="https://www.Healthipeople.com" target="_blank">www.Healthipeople.com</a> (including any successor site) (the “Website” or “Site”). This Policy also describes the choices available to you regarding our use of your information, and how you can manage and update this information. By accessing the Website, by clicking “I Agree”, by accessing the Kinary by Healthipeople App, which (i) connects via invitation only a Caregiver, Care Recipient, and Secondary Caregivers together to form a “Care Squad” in order to facilitate the provision of supportive care for the benefit of the Caregiver and Care Recipient; (ii) facilitates confidential communication of certain health information (“Patient Information”) amongst members of the Care Squad; and (iii) connects Members to relevant expert content and curated resources (the “Service”), or by using the Kinary by Healthipeople App in any way, you agree to be bound by these Terms. Some online services offered by or affiliated with Healthipeople may be governed by a separate privacy policy. We suggest you review each of the privacy policies for the services you are wishing to receive. Any reference to the singular in this Agreement includes the plural and vice versa, and reference to a gender includes all other genders. Any reference to a “person” in these Terms includes any individual, firm, association or corporation.

    
    <b><u>Your Consent to this Privacy Policy by Using the Site</u></b>

    As described in this Privacy Policy, we may use and disclose your information to provide you with products and services and to improve your experience using those products and services. By using the Site, or any other website, online service or mobile application where this Privacy Policy is posted, you agree to our collection, use, disclosure, and storage of information as described in this Privacy Policy.
    

    <b><u>Third-Party Advertising on Our Site</u></b>
  
    We do not allow third parties to advertise to End-Users on our Site, unless you provide consent, which is not requested in this Privacy Policy.


    <b><u>How to Contact Us with Questions about this Privacy Policy</u></b>

    Please read this Privacy Policy carefully. If you have questions or complaints regarding our Privacy Policy or privacy practices, please contact us at: Attn: Privacy Concerns, Healthipeople Corp., 875 Tamarack Dr, San Rafael, CA 94903 or <a href="mailto:kinarysupport@healthipeople.com">kinarysupport@healthipeople.com</a>.


    <b><u>Information Addressed by this Privacy Policy</u></b>

    We want you to have a clear understanding of the information addressed by this Privacy Policy. We will collect information about you (“Information”) in a variety of ways, including directly from you, through automatic means, or from third parties. The Patient Information that is collected depends on how you use the Site.

    
    <b><u>Personal Information and Privacy</u></b>

    When you use our Service, you may be creating and maintaining a personal, private area of the application on behalf of yourself and/or one or more persons within your Care Squad (an “Account”), or visiting and posting to an Account created by someone else within your Care Squad. By their very nature, these Accounts contain a great deal of personal information about the Care Recipient and other End-Users on the Care Squad. Our goal is to balance our End-Users’ need to find each other and communicate information and support against our End-Users’ desire for a measure of privacy. By establishing an Account, you represent that you have all consents and authorizations necessary to use the information relating to members of your Care Squad necessary to establish the Account.
    
    
    <b><u>Site Access</u></b>

    The Account is accessible “By Invitation Only.” Once established, every member of a Care Squad, including the creators of Account  and all End-Users are responsible for maintaining the confidentiality of the Account  in accordance with the Care Recipient’s wishes.
    
    
    <b><u>Information You Provide Directly to Healthipeople</u></b>

    When you use the Site, you may provide certain information directly to us. For example, without limitation, when you choose to register an Account, you will be required to provide, among other things, your e-mail address, name, and phone number. When you create a Care Squad, we may collect identifying information about members of the Care Squad and the Caregiver, such as the Caregiver’s work status and living situation. We also collect information about how the Care Supporter can help the Care Recipient. We may use this information to suggest services that may be of use to the Caregiver or Care Recipient. When you use our Services, we may collect information regarding your past and present medical history (i.e., diagnosis, medical events, medications, etc.). We may also collect financial information in order to determine eligibility for certain programs and benefits. We also collect information in your contact list if you decide to add these Contacts as membesr of the squad.  Finally, we may collect information regarding your insurance coverage and benefits.  We may also collect information about how many medications are you tracking for the Care Recipient, how many appointments are you booking for the Care Recipient, how many squads are you a part of, what is the dosage frequency you track for the medications you manage, what features are you using in Kinary, What categories are you helping with, What are the top conditions Care Recipients on our site managing, Which Care Plan items are you using.

    <b><u>Certain Information in the Services is Visible to Other End-Users</u></b>

    Information regarding the Care Recipient’s needs, actions taken and updates may be shared among and be available to Care Squad members, but is not publicly visible to other End-Users outside of the Care Squad.
    
    
    <b><u>Information that is Automatically Collected</u></b>

    We and third parties may use automated means to collect information about you, your computer or other device that you used to access the Site, and your use of the Site. These automated means include common technologies such as cookies or similar technologies. These technologies help us analyze trends, administer the Site, track End-Users’ movements around the Site, gather demographic information about our user base as a whole, and otherwise provide you with relevant content (e.g., by gathering your zip or postal code). We may receive reports on an individual as well as aggregated basis based on the use of these technologies by Healthipeople, and any third-party vendors acting on Healthipeople’s behalf.
    
    If you provide us access to your phone’s contact list and images, we also collect these from you in the Kinary App.  The specific information we use are the names and the phone numbers of the contacts.  This is used by the App so that you can easily send invites to your contact list to be a part of your care squad.  We do not store any contact information permanently, but rather collect this information within the Kinary App for you to select individual names and send text message based invites to specific contacts.  You can always “decline” giving us access to your phone’s contact list and images and manually send these invites to your contacts.

    <b><u>Deletion of the data or of your account</u></b>

    You can always delete your account and the data by following these steps in the Kinary App:

    1. Click on the More tab
    2. Scroll to the bottom and click on “delete care recipient and squad”

    You can also initiate account deletion by sending us an email to: <a href="mailto:kinarysupport@healthipeople.com">kinarysupport@healthipeople.com</a> .  Be sure to mention your name, email and phone number.  Please note that deletion of the primary caregiver would also mean deletion of the entire squad.
    
    <b>‍Information We Collect from Third Parties</b>

    Healthipeople enters into business relationships with business partners including: healthcare providers and/or networks of healthcare providers (“Providers”), health plans or health insurers, including, without limitation, employer-sponsored health plans (“Health Insurers”), and companies that contract with Providers, Health Insurers, and/or Healthipeople (“Contractors”) to make Healthipeople’s products and services available to End-Users who are Patients of such Providers or enrollees, members, or participants of such Health Insurers or Contractors. Healthipeople’s business partners such as Providers, Health Insurers, and Contractors are collectively referred to in this Privacy Policy as “Healthipeople Partners.” When you use the Services, we may collect information about you, directly or indirectly, from or through applicable Healthipeople Partners as well as other End-Users of the Services. With your consent, we may also collect Information from other third parties.


    <b><u>Cookies and Tokens</u></b>

    Cookies are files that websites send to your computer or other Internet-connected device to uniquely identify your browser or to store information or settings on your device. Our Site may use HTTP cookies, HTML5 cookies, Flash cookies and other types of local storage (such as browser-based or plugin-based local storage, or application-based local storage on a mobile device). You may be able to restrict or disable certain cookies. Please note, however, that without cookies, you may not be able to use all of the features of our Site.

    Our cookies, tokens and similar technologies (collectively, “Tracking Technologies”) also are used for administering the Site, including without limitation, for authentication, to remember End-Users’ settings, to customize the content and layout of the Site for End-Users, to contact you about the Site, and to improve our internal operations, the content of our Site and our services. End-Users may be able to control the use of, or reject or disable, some Tracking Technologies at the individual browser level. If you reject or disable Tracking Technologies, you may still use our Site, but your ability to use some features or areas of our Site may be limited. We use Tracking Technologies to identify your device and keep track of your Internet session with our Site. Using these Tracking Technologies, we may automatically end your session on our Site after a period of inactivity (as determined by us in our sole discretion). We also use Tracking Technologies that allow us to recognize your device when you return to the Site within a certain period of time (as determined by us in our sole discretion) and automatically log you back into your account with us. UNLESS YOU AFFIRMATIVELY LOG OUT of your account PRIOR TO YOUR SESSION ENDING (whether by you or by us), YOU WILL BE AUTOMATICALLY LOGGED BACK IN THE NEXT TIME YOU OR ANY END-USER OF YOUR DEVICE VISITS OUR SITE within the period of time determined by us. If you do not wish to be automatically logged back in when you (or someone using your device) next initiate a session with our Site (using the same device that is being used for your current session), you should log out of your account (i) prior to ending your session, or (ii) if you will be inactive on our Site for more than a few minutes.


    <b><u>Web Logs</u></b>

    In conjunction with the gathering of data through cookies, Web servers may log records such as your device type, operating system type, device advertising identifier, browser type, domain, and other system settings, as well as the language your system uses and the country and time zone where your device is located. The Web server logs also may record the address of the Web page that referred you to our Site, the IP address (and associated city and state or province for the IP address) of the device you use to connect to the Internet, and data about your interaction with our Site, such as which pages you visit.



    <b><u>Pixels/Web Beacons</u></b>

    To control which Web servers collect information by automated means, we may place tags on our Web pages or in our app called “Web beacons” (or “pixels”), which are files that associate certain behavior with a session or user. We also may include Web beacons in e-mail messages to record whether an email has been opened or whether certain links in such email have been clicked. We or third parties also may send instructions to your device using JavaScript or other computer languages to store or gather the sorts of data described above and other details about your interactions with the Site. We may use third party pixels on the Site’s registration and login pages. For example, we may use these pixels to assess your progress in registering for our Site. If you start but fail to complete the registration process, we may also use third party pixels to deliver reminders to complete your registration. We may also use these pixels to deliver notices about new and existing features on our Site. These reminders and notices may appear on other websites, and third parties who provide the pixels may use the information obtained from pixels for their business purposes.


    <b><u>Online Analytics</u></b>

    We may use third-party web analytics services on our Site, such as those of Google Analytics. These service providers use the sort of technology described in this “Information that is Automatically Collected” section to help us analyze how End-Users use the Site, including by noting the third-party Web site from which End-Users arrive. The information (including your IP address) collected by the technology will be disclosed to or collected directly by these service providers, who use the information to evaluate your use of the Site.


    <b><u>Mobile Application Technologies</u></b>

    Our mobile applications may also collect information specific to use of your mobile device, such as unique device identifier and, with your consent, precise geolocation information, motion coprocessor data (e.g. recording information such as steps, distance, and elevation), accelerometer data (showing whether you’re stationary or moving), and gyroscope data. We may also use third-party analytics, such as those of Google Analytics


    <b><u>Collection of Information Across Personal Devices and Applications</u></b>

    Sometimes, we (or our service providers) may use the information we collect—for instance, log-in credentials, IP addresses, hashed email addresses, and unique mobile device identifiers—to locate or try to locate the same unique users across multiple browsers or devices (such as smartphones, tablets, or computers), or work with providers that do this, in order to better tailor content, features, and advertising (for Healthipeople on other sites), and provide you with a seamless experience across the devices you use to access our services.‍


    <b>Our Use of Information</b>

    <b><u>Use and Storage of Information to Provide You with the Site and Related Services</u></b>

    Healthipeople, and any third party vendors acting on Healthipeople’s behalf, may use and store your Information to perform activities associated with providing you with the Site and related services such as: (i) registering you to use the Site and otherwise logging you into the Site, including the authentication of your identity, where applicable, and otherwise enabling your access to the Site; (ii) resolving disputes and/or troubleshooting problems including logging into your account to troubleshoot problems or otherwise help you navigate the Site; (iii) verifying your compliance with your obligations in our Terms of Service or other Healthipeople policies; (iv) improving the quality of the Site, (v) fulfilling any request you make; (vi) communicating with you; (vii) connecting you with and providing you with information about services, providers of services, resources or programs to which you may have access; (viii) providing you with Site content, including, without limitation, generating recommendations (such as recommended Site activities or services), providing caregiver navigation services, creating action plans for caregivers, helping caregivers anticipate the needs of the care recipient, and processing your preferences and requests; (ix) analyzing, customizing, and personalizing the Site, including through the delivery of tailored content and communications; and (x) otherwise as directed by you or provided for herein. Except where prohibited by law, and only to the extent permitted by Healthipeople’s agreements with applicable Healthipeople Partners, we may also use your Information in connection with any other services we make available to you.


    <b><u>Additional Information Regarding Use of Information for Communications with You</u></b>

    If you choose to tell a friend about our Service or share an Individual Squad through our Site, we will ask you for your friend’s name and email address or phone number. We will automatically send your friend a one-time email or text message inviting him or her to visit the Site. We retain this information to identify the End-User as part of the squad when they sign in.

    Healthipeople, Healthipeople Partners, and any third-party vendors acting on Healthipeople’s behalf, may also use your Information to communicate with you (including, without limitation, via email and/or mobile push notifications) about the Site and/or our products and services (and related features and functionality) or those of any Healthipeople Partner that is providing services to you. Our communications to you will include, without limitation, contacting you by SMS text message or at the email address(es) that you use to register for our Site to communicate with you regarding the Site and actions that you take in connection with the Site.


    <b><u>Your Choices Regarding Our Communications with You</u></b>

    In accordance with applicable law, we will offer you the opportunity to opt out of certain communications, such as, for example, communications that deal with any of our products or services you are not using but that we think might be of interest to you. Where applicable, you can opt out by emailing us at <a href="mailto:info@healthipeople.com">info@healthipeople.com</a>. However, even if you opt out of such communications, you may continue to receive other transactional, relationship, and/or administrative communications from or through Healthipeople that are important or related to your Healthipeople account or the products and services you are using (such as, for example, a notice that there has been a change in one of your services or a change to this Privacy Policy). Please also note that you, not Healthipeople, have control over certain settings for how communications such as emails and push notifications are displayed on your devices, and the settings you select will affect how Healthipeople’s communications are displayed.


    <b><u>Creation and Use of Combined Data, De-Identified Data, and Aggregated Data</u></b>

    As permitted by law and by Healthipeople’s agreements with applicable Healthipeople Partners, Healthipeople, and any third-party vendors acting on Healthipeople’s behalf, may aggregate and/or de-identify your Information and/or combine your Information with other information maintained or available to Healthipeople and use or disclose such information as follows:

    We may use aggregated or combined data to communicate with you about the Site and/or our products and services and disclose such aggregated or combined data to Healthipeople Partners in connection with providing the Site.

    We may also use and disclose de-identified data, de-identified aggregated data, and/or de-identified combined data for any business purpose, which may include, without limitation, improving our products and services, conducting analytics such as evaluating our Site and developing additional benefits, programs, and services, and disclosing to Healthipeople Partners for analytics purposes.
    
    
    <b>Disclosure of Information. We may share your Information in the following circumstances:</b>

    <b><u>Disclosures to Service Providers and Healthipeople Partners</u></b>

    With the limited exceptions discussed below, we do not share your information with third parties, unless you tell us to. We will not share your personal information under any circumstance with any third parties for the purpose of allowing them to conduct direct marketing to you. In this section, we describe the potential uses of your information in greater detail

    With Third Parties That Provide Requested Benefits: As stated above, we primarily share your information, including your personal information, with third parties for the purpose of providing certain benefits that you request. Specifically, at your direction, we will submit your information to the appropriate third parties for purposes of filling your third-party service requests. For example, if you are interested in transportation or caregiver navigation services, then we will share your information, at your direction, with third parties that work with Healthipeople to provide transportation or caregiver navigation services, which may fit your particular needs. The third party, not Healthipeople, will evaluate your eligibility to obtain service from its program. The third party’s use of your personal information will be governed by the third party’s own terms of service and privacy policy, not Healthipeople’s; Healthipeople does not have any control over your information once it is submitted to a third party for receipt of benefits. While we work with reputable third-party service providers, we cannot represent how any of them will use your personal information in connection with their services, and recommend that you review their terms of use and privacy policies before requesting their services. 

    To Perform Services on Our Behalf: We may hire vendor companies to provide limited services on our behalf, such as payment processing, sending postal and electronic mail, providing technical support, and to assist us in providing the products and services that you request from us. We only provide those service providers with the information necessary to perform the requested service.


    <b><u>Additional Disclosures with Your Consent</u></b>

    With your consent outside of this Privacy Policy, we may share your Information with third parties or allow them to collect information from the Site in some ways not specifically described in this Privacy Policy.


    <b><u>Safety, Security and Compliance with Law</u></b>

    Your Information and the contents of your communications through the Site may be disclosed to third parties as required by law, such as to comply with a subpoena or similar legal process, or when we reasonably believe disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, report improper or unlawful activity, or respond to a government request. 


    <b><u>Subsidiaries and Affiliated Companies</u></b>

    Except where prohibited by law or precluded by contract, we may share your Information with any subsidiaries or other affiliated companies under the control of, or under common control with, Healthipeople for the purposes set out in this Privacy Policy.


    <b><u>Sale, Merger or Similar Transaction</u></b>

    We may share your Information in connection with any proposed or actual merger, reorganization, a sale of some or all our assets, a financing or acquisition of all or a portion of our business by another company, or to a receiver, trustee or successor in interest in bankruptcy, in which case such company may continue to process your Information as set forth in this Privacy Policy (except where prohibited by law) or otherwise with your consent.


    <b>We Do Not Control the Privacy Policies of Third Parties</b>

    Our Site may contain links that enable you to visit or use other third-party websites, resources or programs. However, we do not have control over the other websites, resources, or programs that you choose to visit, so this Privacy Policy does not apply to information collected or that you provide while visiting such other websites, resources, or programs. You should refer to the privacy policies, if any, applicable to the other websites, resources, or programs.


    <b>Data Security and Integrity</b>

    The security of your Information is important to us. We follow security standards to protect the Information submitted to us from loss, interference, misuse, unauthorized access, disclosure, alteration or destruction, both during transmission and once we receive it. These safeguards vary based on the sensitivity of the Information that we collect, process and store and the current state of technology. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute security. We also maintain procedures to help ensure that such data is reliable for its intended use and is accurate, complete and current. If you have any questions about data security and integrity on our Site, you can contact us at <a href="mailto:info@healthipeople.com">info@healthipeople.com</a>.
    
    
    <b>Be Sure to Log Off When Using Public or Third-Party Computers</b>

    As described above, we use Tracking Technologies that automatically log you back into your account with us unless you affirmatively log out of your account prior to your session ending (whether you end your session or we end your session, for example if our Site has “timed out” – i.e. we have ended your session automatically after a period of inactivity as determined by us in our sole discretion). If you log into the Site using a public computer or device, or a computer or device of that belongs to or is shared with another person, you should affirmatively log out of your account. Otherwise, the next user of that computer or device may be able to access your account and your Information in your account if your session has not ended.
    
    
    <b>Managing and Updating Information</b>

    To access, review, update and/or delete certain Information, you may contact us at <a href="mailto:info@healthipeople.com">info@healthipeople.com</a>, and you may update and/or delete certain Information by logging into the Site and updating your account. We will respond to your request within a reasonable and lawful timeframe under the circumstances of your request. Please remember, however, if we have already disclosed some of this Information to third parties, we cannot access that Information any longer and cannot force the deletion or modification of any such Information by the parties to whom we have made those disclosures. Additionally, if you modify certain Information on our Site that we received from a third party, we reserve the right, but are not obligated, to share the updated information back to that third party. As a result, you will need to provide updated information to those third parties if you want to be sure they have your updates.

    Certain Information is necessary in order for us to provide the Site and our related Services; therefore, if you delete such necessary Information you will not be able to use the Site or receive our Services. If you wish to have your account deactivated, you may contact us at <a href="mailto:info@healthipeople.com">info@healthipeople.com</a>. After we receive your request, within a reasonable timeframe under the circumstances of your request, we will deactivate your account; provided, however, that: 1) Healthipeople (and our third-party vendors acting on our behalf), our Healthipeople Partners, and any other applicable third parties may continue to use and share for any purpose de-identified data and aggregated or combined data developed using your Information that has been de-identified and 2) Healthipeople may continue to use Information in your deactivated account for compliance with applicable regulations. Also, please note that even though you may deactivate your account or request the deletion of your Information, we may be required (by law or otherwise, such as to prevent fraud, resolve disputes, or troubleshoot problems) to retain this information, in which case we will comply with your deletion request only after we have fulfilled such requirements. We may also keep a copy of your Information to protect our legal rights, such as in connection with your use of the Site or your agreement to our Terms of Service, as permitted or required by applicable law.


    <b>California Do-Not-Track Disclosure Requirements (for California End-Users)</b>

    We are committed to providing you with meaningful choices about the information collected on our Site for third-party purposes. However, we do not recognize or respond to browser-initiated Do Not Track signals, as the Internet industry is currently still working on Do Not Track standards, implementations, and solutions.


    <b><u>Your California Privacy Rights (for California End-Users)</u></b>

    California law permits customers of Healthipeople who are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please write us:

    Healthipeople Corp.

    875 Tamarack Dr

    San Rafael, CA 94903

    email: <a href="mailto:info@healthipeople.com">info@healthipeople.com</a>.

    We will provide a list of the categories of personal information, if any, disclosed to third parties during the immediately preceding calendar year for third-party direct marketing purposes, along with the names and addresses of these third parties. This request may be made no more than once per calendar year. We reserve our right not to respond to requests submitted other than to the email or postal address specified above. You should put “California Privacy Rights” in the subject line and in your request. You must provide us with specific information regarding yourself so that we can accurately respond to the request.


    <b>Children; Special California Notice</b>

    The Site and Services are not directed to children under the age of 18, and children under the age of 18 years of age are not eligible to use them. Protecting the privacy of children is very important to us. Through the Site and Services, we do not collect Information from people we actually know are under 18 years of age, and no part of the Site or Services is designed to attract people under 18 years of age. If we later learn that an individual has provided us with their information through the Site or Services and they are under 18 years of age, we will take steps to remove that End-User’s Information from our databases and to prevent the End-User from utilizing the Site or Services.

    If you are a California resident under the age of 18 and are a registered End-User of our Site, you may request that we remove from our Site any content you post to our Site that can be accessed by any other End-User (whether registered or not). Please note that any content that is removed from our Site may still remain on our servers and in our systems. To request removal of content under this provision, please send an email to <a href="mailto:info@healthipeople.com">info@healthipeople.com</a> and provide us with a description of the content and the location of the content on our Site, and any other information we may require in order to consider your request. Please note that removal of content under this provision does not ensure complete or comprehensive removal of the content or information posted on the Site by you.‍


    <b>Uses and Disclosures as Required or Permitted by Law, Including Research, Health Care Operations, and Public Health</b>

    To the extent not prohibited by law or precluded by Healthipeople’s agreements with the applicable Healthipeople Partner, Healthipeople, and any third party vendors acting on Healthipeople’s behalf, may use and disclose your Information: (a) as required or permitted by law, including, where applicable, the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”), which may include disclosures to the applicable Healthipeople Partner; (b) for Research purposes; (c) for purposes of Health Care Operations of the applicable Healthipeople Partner; and (d) for Public Health, as each is defined and in accordance with HIPAA.‍ . For more information see: 
    <a href="https://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html" target="_blank">www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html</a>


    <b>Retention of Personal Data</b>

    Personal data will be retained only for so long as reasonably necessary for the purposes set out in this Privacy Policy, considering criteria such as applicable rules on statute of limitations and at least the duration of your use of our Site and Services. Information may persist in copies made for backup and business continuity purposes for additional time.


    <b>Our Responsibilities</b>

    We are required by law to maintain the privacy and security of your protected health information. We will let you know promptly if a breach occurs that may have compromised the privacy or security of your information. We must follow the duties and privacy practices described in this notice and give you a copy of it. We will not use or share your information other than as described here unless you tell us we can in writing. If you tell us we can, you may change your mind at any time. Let us know in writing if you change your mind. 

    For more information see: <a href="https://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html" target="_blank">www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html</a>
    
    
    <b><u>Modifications to this Privacy Policy</u></b>

    From time to time, we may update this Privacy Policy to reflect changes to our information practices. If we make changes to this Privacy Policy, they will be reflected in an updated Privacy Policy that will be posted on the Site, and all changes will be effective upon such posting or upon any later date specified by us in writing. We encourage you to periodically review this page for the latest information on our privacy practices. You can determine when this Privacy Policy was last revised by referring to the “Effective Date” legend at the top of this Privacy Policy. By continuing to use the Site or any services following the effective date of any updated Privacy Policy, you are agreeing to be bound by the terms and conditions of such updated Privacy Policy.
    `
};

export const cookiePolicyOptions: IStaticContent = {
  heading: "Kinary by Healthipeople Cookie Policy",
  updatedOn: "This policy was updated on February 21, 2023",
  content: `Healthipeople Corp (or “Healthipeople”, “Kinary by Healthipeople”, “Kinary”, “we”, “us”, or “our”) and our affiliates, third parties, and other partners use cookies and other identification technologies on our websites, mobile applications, email communications, advertisements, and other online services (collectively, the "Services") for a number of purposes, including: authenticating users, remembering user preferences and settings, determining the popularity of content, delivering and measuring the effectiveness of advertising campaigns, analyzing site traffic and trends, and generally understanding the online behaviors and interests of people who interact with our Services. You can read more here about the types of cookies we use, why we use them, and how you can exercise your choices.

    ​COOKIES AND RELATED TECHNOLOGIES OVERVIEW
    Cookies are small text files that are stored on your browser or device by websites, apps, online media, and advertisements that are used to remember your browser or device during and across website visits. We also utilize other technologies that may identify you or the devices you use. For example, “pixel tags” (also called beacons) are small blocks of code installed on (or called by) a webpage, app, or advertisement which can retrieve certain information about your device and browser, including for example: device type, operating system, browser type and version, website visited, time of visit, referring website, IP address, advertising identifiers, and other similar information, including the small text file (the cookie) that uniquely identifies the device. Pixels provide the means by which third parties can set and read browser cookies from a domain that they do not themselves operate and collect information about visitors to that domain, typically with the permission of the domain owner. “Local storage” refers generally to other places on a browser or device where information can be stored by websites, ads, or third parties (such as HTML5 local storage and browser cache). “Software Development Kits” (also called SDKs) function like pixels and cookies, but operate in the mobile app context where pixels and cookies cannot always function. The primary app developer can install pieces of code (the SDK) from partners in the app, and thereby allow the partner to collect certain information about user interaction with the app and information about the user device and network information.
    
    ADVERTISING SYNCHRONIZATION & RELEVANCY
    In order to facilitate the most relevant ads possible, Kinary by Healthipeople works with various service providers who assist us in delivering similar ads to end users across devices and platforms. For example, we work with social media advertising services to provide you with relevant ads based on your Kinary by Healthipeople activity through their media channels. We may also use service providers to provide you with a similar ad on a mobile website or mobile application as with a traditional website ad. See below for more information on your choices to limit these types of advertising.
    
    YOUR CHOICES
    You have the right to choose whether or not to accept cookies. However, they are an important part of how our Services work, so you should be aware that if you choose to refuse or remove cookies, this could affect the availability and functionality of the Services.
    Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. To do so, please follow the instructions provided by your browser which are usually located within the "Help" or “Preferences” menu. Some third parties also provide the ability to refuse their cookies directly by clicking on an opt-out link, and we have indicated where this is possible in the table below.
    ​Removing or rejecting browser cookies does not necessarily affect third-party flash cookies which may be used by us or our partners in connection with our Services. To delete or disable flash cookies please visit this site for more information. For further information about cookies, including how to see what cookies have been set on your device and how to manage and delete them, you can visit www.youradchoices.com
    For mobile users, you have controls in your device Operating System that enables you to choose whether to allow cookies, or share your advertising ID with companies like Kinary by Healthipeople or our advertising service providers. For information on controlling your mobile choices, you can visit www.networkadvertising.org/mobile-choices.
    To help control or block certain ads in mobile applications, you may choose to download and utilize the DAA mobile app, https://youradchoices.com/appchoices
    
    ​TYPES AND PURPOSES OF COOKIES
    The following table sets out the different categories of cookies that our Services use and why we use them.
    Purpose: Strictly Necessary
    These cookies (including local storage and similar technologies) are essential to enable your use of the site or services, such as assisting with your account login, so we can show you the appropriate experience and features such as your account information, trip history, and to edit your account settings. They may also provide authentication, site security, and help localize the language based on the geography of your visit.
    Purpose: Site features and Services
    These provide functionality that help us deliver products and Services. For example, cookies help you log in by pre-filling fields. We may also use cookies and similar technologies to help us provide you and others with social plugins and other customized content and experiences, such as making suggestions to you and others.
    Purpose: Analytics, performance and research
    These are used to understand, improve, and research products and Services, including when you access the Kinary by Healthipeople website and related websites and apps from a computer or mobile device. For example, we may use cookies to understand how you are using site features, and segmenting audiences for feature testing. We and our partners may use these technologies and the information we receive to improve and understand how you use websites, apps, products, services and ads.
    Purpose: Advertising
    These cookies and pixels are used to deliver relevant ads, track email marketing or ad campaign performance and efficiency. For example, we and our ad partners may rely on information gleaned through these cookies to serve you ads that may be interesting to you on other websites. Similarly, our partners may use a cookie, attribution service or another similar technology to determine whether we’ve served an ad and how it performed or provide us with information about how you interact with them.
    Purpose: Mobile-Specific
    These service providers use various technologies to deliver relevant mobile ads, track mobile marketing or mobile ad campaign performance and efficiency.
    If you have any questions about our use of cookies, please review our Privacy Policy, and/or please contact us at <a href="mailto:info@healthipeople.com">info@healthipeople.com</a>`,
};

export const termsOptions: IStaticContent = {
  heading: "Kinary by Healthipeople End-User License Agreement",
  updatedOn: "This policy was updated on February 21, 2023",
  content: `This End-User License Agreement (this "EULA") sets forth the terms and conditions upon which Healthipeople Corp (or “Healthipeople”, “Kinary by Healthipeople”, “Kinary”, “we”, “us”, or “our”) offer you, our third party end-users (each, an "End-User", “Member”, “you” or “your”), access to our mobile application (the "App"). By accessing the Kinary by Healthipeople App, which (i) connects via invitation only a Caregiver, Care Recipient, and Secondary Caregivers together to form a “Care Squad” in order to facilitate the provision of supportive care for the benefit of the Caregiver and Care Recipient; (ii) facilitates confidential communication of Patient Information amongst members of the Care Squad; and (iii) connects Members to relevant expert content and curated resources (the “Service”), or by using the Kinary by Healthipeople App, you agree to be bound by the terms and conditions of this EULA . If you do not agree to the terms and conditions of this EULA, do not access or use the Service. This is a legally enforceable contract.

    YOUR USE OF THE SERVICE
    We may review the Patient Information that you maintain through the Service to normalize and label data for display, but we are not responsible for any errors in Patient Information resulting from such review process. We make no effort to review Patient Information for any other purpose, including, but not limited to, accuracy, legality or non-infringement. We are not responsible for any Patient Information provided by any Member. We cannot guarantee or assume responsibility for the completeness, timeliness, accuracy, relevance, deletion or non-delivery of any Patient Information.
    Your right to access and use the Service is personal to you and is not transferable by you to any other person or entity. You are only entitled to access and use the Service for lawful purposes. Accurate information enables us to provide the Service to you. You must provide true, accurate, current and complete information and may not misrepresent information (i) in your registration information, (ii) about you, any Caregiver, Care Recipient, Secondary Caregiver, or third-party health care providers, or (iii) about your Patient Information. In order for the Service to function effectively, you must also keep such information listed above up-to-date and accurate. If you do not do this, the accuracy and effectiveness of the Service will be affected.
    Your access and use of the Service may be interrupted from time to time for any of several reasons, including, without limitation, unanticipated system failures, the malfunction of equipment, periodic updating, maintenance or repair of the App or other actions beyond the control of we or that we, in its sole discretion, may elect to take. we cannot always foresee or anticipate technical or other difficulties which may result in, and we shall therefore not be responsible or liable for, any (i) failure to obtain Patient Information, (ii) loss of Patient Information or personalization settings or (iii) other service interruptions.
    
    LICENSE; RESERVATION OF RIGHTS
    Limited License to Use the Service. Subject to End-User’s compliance with the terms and conditions of this EULA, we hereby grant to End-User a limited, personal, non-exclusive, non-transferable, license to use the App to access the Service solely for the End-User’s own personal purposes. Except for the licenses and rights expressly granted under this EULA, no licenses or rights are granted by us to End-User hereunder, by implication, estoppel or otherwise. All such other licenses and rights are reserved unto us.
    Other License Restrictions. End-User shall not (i) copy or modify the Service or App for any purpose; (ii) reverse-engineer, decompile, modify, translate, disassemble or discover the source code for all or any portion of the Service or the App; (iii) distribute, disclose, market, rent, lease or otherwise transfer the Service or the App to any other person or entity; or (iv) use the App or the Service or permit, enable or assist a third party to create competing products or services.
    Intellectual Property Rights. We retain all intellectual property rights in and to the Service and the App and all related documentation interest (including all copyrights, patents, service marks, trademarks and other intellectual property rights), including, but not limited to, any and all updates, enhancements, customizations, revisions, modifications, future releases and any other changes thereto, and all related information, material and documentation. Except for rights specifically provided in this EULA, End-User hereby assigns to us all other intellectual property rights it may now or hereafter possess in the Service or the App and related documentation, and all derivative works and improvements thereof, and agrees to execute all documents, and take all actions, that may be necessary to confirm such rights. End-User also agrees to retain all proprietary marks, legends and patent and copyright notices that appear on the Service or the App and any related documentation delivered to End-User by us and all whole or partial copies thereof.
    
    WARRANTIES AND LIMITATIONS OF LIABILITY
    Warranties. We warrant that we will use commercially reasonable efforts to cause the Service and the App to operate in substantial conformance with its published documentation. Our sole obligation and End-User’s sole remedy with respect to any failure of the Service or the App to substantially conform to the documentation therefore is for us to use commercially reasonable efforts to remedy any such failure as soon as is reasonably practicable, and if such failure is not remedied in a reasonable time, for End-User to terminate this EULA pursuant to Section titled “Term and Termination”.
    No Specific Results. Specifically, we do not represent, warrant or otherwise guarantee: (a) the reliability and suitability of the App; (b) the effectiveness, suitability or reliability of communicated information (including any data provided through the Service); (c) that the App will work without interruption or that it will be free of errors, viruses or other harmful components; (d) outcomes resulting from your use of the App; (e) that the information available on the App or through the Service is comprehensive or exhaustive, or that it addresses all relevant symptoms, medicines, and treatments for various diseases or medical conditions; or (f) any outcome with respect to health and well-being as a result of the application of this information.
    No Practice of Medicine. The Service and the App are not intended to be, and must not be taken to be, the practice of medicine or other healthcare services by us. Use of the Service and the App do not create a physician/patient or provider/patient relationship with us. We are not recommending or endorsing any specific treatment, physician, healthcare provider or medication.
    Data Collection; Privacy Policy. Our Privacy Policy is incorporated and made part of this EULA. You hereby agree to such Privacy Policy, which may be updated from time to time. Your continued use of the Service and the App signifies your acknowledgment and acceptance of the revised Privacy Policy. Any personal data (for example, your name, address, telephone number or e-mail address) that you transmit to the Service or through the App will be used by us in accordance with the Privacy Policy. Any other communication or material you transmit to the Service or through the App, such as questions, comments, suggestions or the like, will be treated as non-confidential and non-proprietary.
    Ability to Contract; Legal Age. The Service and the App are offered and available to End-Users who are 18 years of age or older. By using the Services, you represent and warrant that you are of legal age to form a binding contract with us and meet all of the foregoing eligibility requirements. If you are under the age of 18, make sure that your parent or legal guardian reads and agrees with this EULA on your behalf, as your parent or legal guardian shall be fully responsible for your compliance with this EULA. If you do not meet all of these requirements, you must not access or use the Service or the App. In this EULA, “you”, “user” or “your” means any person or entity using the Service or the App, provided that if such person is under the age of 18, “you” and “your” also includes such person’s parent or legal guardian.
    Warranty Disclaimer. UNLESS OTHERWISE EXPLICITLY STATED IN THIS EULA, WE DISCLAIM ANY AND ALL WARRANTIES RELATING TO THE SERVICE OR ANY OTHER MATTER COVERED BY THIS EULA, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT THE SERVICE OR APP WILL OPERATE WITHOUT INTERRUPTION OR DELAY AND/OR BE ERROR-FREE, OR THAT ALL FAILURES OF THE SERVICE OR APP TO CONFORM TO THE DOCUMENTATION CAN OR WILL BE CORRECTED. WE MAKE NO WARRANTIES OR REPRESENTATIONS AS TO THE ACCURACY OF ANY CONTENT PROCESSED BY THE SERVICE OR THE APP.
    Limitations of Liability. IN NO EVENT SHALL WE BE LIABLE FOR LOST PROFITS OR BUSINESS, LOSS OF GOODWILL, LOSS OF DATA, INTERRUPTION OF BUSINESS, OR FOR ANY EXEMPLARY, PUNITIVE, SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES, REGARDLESS OF WHETHER SUCH DAMAGES ARISE UNDER ANY THEORY OF TORT, CONTRACT, STRICT LIABILITY OR OTHERWISE AND REGARDLESS OF WHETHER SUCH PARTY IS ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGES. The aggregate liability of Healthipeople for any and all claims arising under or in connection with this EULA or its subject matter shall not exceed $500.
    Essential Part of the Bargain. The parties acknowledge that the disclaimers and limitations set forth in this Section are an essential element of this EULA between the parties and the parties would not have entered into this EULA without such disclaimers and limitations.
    Telecommunication Services. Use of the Service may be available through a compatible mobile device, Internet and/or network access and may require software. You agree that you are solely responsible for these requirements, including any applicable changes, updates and fees as well as the terms of your agreement with your mobile device and telecommunications providers. WE MAKE NO WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESS, STATUTORY OR IMPLIED AS TO: (I) THE AVAILABILITY OF TELECOMMUNICATION SERVICES FROM YOUR PROVIDER AND ACCESS TO THE SERVICE AT ANY TIME OR FROM ANY LOCATION; (II) ANY LOSS, DAMAGE, OR OTHER SECURITY INTRUSION OF THE TELECOMMUNICATION SERVICES; AND (III) ANY DISCLOSURE OF INFORMATION TO THIRD PARTIES OR FAILURE TO TRANSMIT ANY DATA, COMMUNICATIONS OR SETTINGS CONNECTED WITH THE SERVICE.
    
    INDEMNIFICATION; UNAUTHORIZED USE
    Indemnification. End-User hereby agrees to indemnify and hold us harmless from any and all damages, costs and expenses (including, without limitation, reasonable attorneys’ fees) suffered or incurred by Healthipeople in connection with any claims that we are required to pay to third parties to the extent such damages, settlement amounts, costs and expenses are attributable to End-User’s provision of content by the End-User content through the Service or the App (“End-User Content”), including any actual or alleged volitions of third party intellectual property by such End-User Content.
    Notification of Unauthorized Use. End-User shall promptly notify us in writing upon its discovery of any unauthorized use or infringement of the Service, the App, or the related documentation, or our intellectual property rights with respect thereto. We shall have the sole and exclusive right to bring an infringement action or proceeding against any infringing third party and, in the event that we bring such an action or proceeding, End-User shall cooperate and provide full information and assistance to us and our counsel in connection with any such action or proceeding.
    
    TERM AND TERMINATION
    Term. End-User’s access to the App and Services shall begin on the date End-User registers for the service and shall continue until terminated earlier under the provisions of this EULA.
    Termination for Convenience. We may terminate End-User’s use of the Service at any time. End-User may terminate this Agreement at any time by cancelling its account for the Services and uninstalling the App.
    Effect of Termination. Upon the expiration or sooner termination of this EULA, all license rights of End-User under this EULA shall automatically and immediately cease, and End-User shall promptly cease all uses of the Service and shall uninstall the App. All sections that may legally survive the expiration or sooner termination of this EULA shall survive the expiration or sooner termination of this EULA.
    
    GENERAL
    Export Compliance. The Service and the App may be subject to U.S. and other national export controls and economic sanctions. Individuals or entities owned or controlled, registered in, or related to Cuba, Iran, Sudan, Syria, or North Korea are not permitted to use the App or access the Service without prior written permission from we once granted by the appropriate jurisdiction. The rights and obligations of End-User shall be subject to such United States laws and regulations as shall from time to time govern the license and delivery of technology abroad by persons subject to the jurisdiction of the United States, including the Export Administration Act of 1979, as amended, any successor legislation to the Export Administration Act of 1979, and the Export Administration regulations issued by the Department of Commerce, International Trade Administration, Office of Export Administration. End-User shall certify that it shall not, directly or indirectly, export, re-export or transship the Service in such manner as to violate such laws and regulations in effect from time to time. End-User shall indemnify us and hold us harmless from and against any and all losses, claims and expenses incurred by us as a result of the breach of End-User's obligations under this Section.
    Independent Contractors. In making and performing this EULA, the parties are acting and shall act as independent contractors. Neither party is, nor will be deemed to be, an agent, legal representative, joint venturer or partner of the other party for any purpose.
    Force Majeure. In the event that we are unable to perform our obligations under this EULA because of acts of God, strikes, equipment or transmission failure, unavailability or poor performance of the Internet, or other causes reasonably beyond its control, we shall not be liable to the End-User for any damages resulting from such failure to perform or otherwise from such causes.
    Governing Law. These Terms of Service are governed by the laws of the State of California, without regard to its choice of law provisions. The courts of general jurisdiction located within California will have exclusive jurisdiction over any and all disputes arising out of, relating to or concerning this EULA or in which this EULA is a material fact.
    Waiver of Jury Trial and Class Actions. BY ENTERING INTO THESE TERMS OF SERVICE, BOTH PARTIES ACKNOWLEDGE AND AGREE TO WAIVE CERTAIN RIGHTS TO LITIGATE DISPUTES IN COURT, TO RECEIVE A JURY TRIAL OR TO PARTICIPATE AS A PLAINTIFF OR AS A CLASS MEMBER IN ANY CLAIM ON A CLASS OR CONSOLIDATED BASIS OR IN A REPRESENTATIVE CAPACITY. BOTH PARTIES WAIVE, TO THE FULLEST EXTENT ALLOWED BY LAW, ANY RIGHT TO PURSUE OR TO PARTICIPATE AS A PLAINTIFF OR AS A CLASS MEMBER IN ANY CLAIM ON A CLASS OR CONSOLIDATED BASIS OR IN A REPRESENTATIVE CAPACITY.
    Public Statements. Either party may disclose the existence of this EULA but may not represent to any third party any positions, statements, intentions or other actions on behalf of the other.
    Assignment. End-User may not assign any of its rights or privileges, or delegate any of its duties or obligations hereunder to any third party without our prior written consent. Any purported assignment in contravention of this Section shall be null and void. Subject to the foregoing, this EULA shall be binding upon and inure to the benefit of the parties hereto and their respective permitted successors and assigns.
    Notices. All notices and other communications hereunder shall be in writing and shall be deemed effective when delivered by hand, facsimile transmission, reputable overnight delivery service, or certified mail (return receipt requested), postage prepaid to: (a) in our case of, to the address set forth on the healthipeople.com website; and (b) in the case of End-User, to the address set forth in its account information.
    Entire Agreement; Amendment. This EULA constitutes the entire agreement between the parties concerning the subject matter hereof and supersedes all prior understandings and agreements between the parties, whether written or oral, regarding the subject matter hereof. This EULA may not be amended, supplemented or otherwise modified by you except by an instrument in writing signed by both parties and attached hereto. We may modify the EULA upon providing written notice to you either via the e-mail registered under your account or by posting such changes through the App or through our website. Any use of the App or the Service following any such modification constitutes an acceptance of the modification. If you do not accept any modification, you must immediately cease all usage of the App.
    Waivers. A waiver by either party of a breach or violation of any provision of this EULA will not constitute or be construed as a waiver of any subsequent breach or violation of that provision or as a waiver of any breach or violation of any other provision of this EULA.
    Severability. If any provision of this EULA is held to be unenforceable, it shall be severed and the remaining provisions will remain enforceable. The severed provision will be replaced by an enforceable provision most nearly reflecting the intention of the parties.
    `,
};

export const faqOptions: Array<IFAQOptions> = [
  {
    category: "Get to know Kinary",
    questionSet: [
      {
        question: "What is Kinary?",
        answer: `Kinary is a Caregiver-first digital assistant that helps individuals and families manage
          and coordinate care for a chronically ill, aging, or disabled loved one. We understand
          your privacy and safety are important, and we take it seriously. Kinary is a safe place
          and we will protect your data, so you can focus on taking care of your loved one.`,
      },
      {
        question: "What does Kinary mean?",
        answer: `Kinary’s name comes from the “spirit of Canary”. Much like our Family Caregivers, this
          spirit animal has a strong sense of compassion, knows the importance of clear
          communication, and has a natural ability to uplift others. Canaries want to encourage
          us to seek out our own sense of enjoyment in order to create that balance needed to get
          through difficult times.
          We couldn’t think of anything better than the spirit of the Canary to represent our digital
          assistant. That is why we chose “Kin”+”Ary”= Kinary.`,
      },
      {
        question: "Why Kinary?",
        answer: `Kinary is the only Caregiver-first digital assistant that gives you practical tools to
          manage everyday caregiving for your loved one(s). Plus, Kinary is the only social graph
          in the making that will connect Caregivers. While there are other Caregiver apps out in
          the market, we would only call them Employer-first Caregiver support systems.`,
      },
      {
        question: "How does Kinary work?",
        answer: `Simple- 3 steps.
          1. Download the Kinary app from the Apple App Store or Google Play Store.
          2. Create an account.
          3. Invite other squad members (aka your family/friends)- only if needed
          Then, let Kinary help you and support you with everyday caregiving.`,
      },
      {
        question: "Who is Kinary designed to help?",
        answer: `Kinary is designed to help Individuals and Families taking care of a a chronically ill,
          aging, or disabled loved one. Kinary is also a great tool for Direct Care Workers to
          make it easy to manage the care of multiple clients, all from one place.`,
      },
      {
        question: "What medical conditions can Kinary help me with?",
        answer: `All medical conditions! We let your doctors and specialists focus on treating the
          condition(s). Our job is to help you manage the care of your loved one outside of the
          hospital/doctor’s office with your friends, family, and external support and with the
          resources you need.`,
      },
      {
        question: "What if I just want to manage my own care?",
        answer: `That is absolutely fine. The squad is helpful but not needed for you to manage your
          own care via Kinary. You can manage all aspects of your care via Kinary with just you
          as the superhero for your own care.`,
      },
      {
        question: "What if I am a direct care worker managing a client?",
        answer: `Kinary works just as well for direct care workers. Good news is that you can manage
          and coordinate the care of multiple clients all from Kinary.`,
      },
      {
        question: "Where is Kinary available?",
        answer: `Kinary is currently available in the USA on both the Apple App Store and Google Play
          Store.`,
      },
    ],
  },
  {
    category: "Care recipient",
    questionSet: [
      {
        question: "Who can be the care recipient?",
        answer: `Care recipient is anyone who needs help managing their care outside of the doctor’s
          office and/or the hospital. This can be a a chronically ill, aging, or disabled loved one or
          a client if you are a Direct Care Worker. Kinary can also be used to manage your own
          care- in that case you are the care recipient.`,
      },
      {
        question: "What if I am the care recipient and the primary caregiver?",
        answer: `That is absolutely fine. Kinary helps make you self-sustainable and independent in
          managing and coordinating your own care.`,
      },
      {
        question: "What kind of of help can I provide to a care recipient?",
        answer: `You can help manage all aspects of care for a care recipient. This includes managing a
          care plan- medication administration & refills, appointment preparation and rides,
          coordination with the care team, tracking vitals, routine activities, day2day errands,
          meals tracking and exercise tracking. You can also learn about various local community
          services and resources available in your zipcode.`,
      },
    ],
  },
  {
    category: "Squad management",
    questionSet: [
      {
        question: `What is a primary caregiver? Who plays this role?`,
        answer: `In the Kinary world, the primary caregiver is typically the caregiver who is closest to the
          care recipient and is the most active in managing and coordinating the care. This can
          be a close family member, a friend, a Direct Care Worker or home health aide. It is also
          important to note that whoever downloads Kinary first and sets up the squad is
          automatically designated as the primary caregiver.`,
      },
      {
        question: `What is the difference between a primary caregiver and
        a secondary caregiver?`,
        answer: `A primary caregiver is the one that downloads Kinary first and sets up the care recipient
          and the squad. A secondary caregiver refers to all the other squad members that have
          been invited. By default, if a secondary caregiver leaves the squad, all the tasks are
          assigned to the primary caregiver.`,
      },
      {
        question: `Who can join my squad?`,
        answer: `Anyone who is helping the care recipient can join the squad. Squad members can
          consist of family members, friends, providers, home health aides, social workers, direct
          care workers, or neighbors.`,
      },
      {
        question: `How do I leave a squad?`,
        answer: `We would recommend letting your squad know first and ensuring this has been
          planned. In Kinary, for a secondary caregiver, all you have to do is click on the “More”
          tab, click on “Manage Squads”, scroll to the bottom and click on “Leave Squad” button.
          If you do this, then all the tasks assigned to you will be re-assigned to the primary
          caregiver.
          If you are a primary caregiver, and want to leave the squad, please contact us via
          <a href="https://kinary.io/support/" target="_blank">https://kinary.io/support/</a> and select Delete Squad, as the reason to contact. We will
          help you with this action. In future releases, you will be able to do this yourself as a
          primary caregiver.`,
      },
      {
        question: `How do I remove a squad member?`,
        answer: `We would recommend letting your squad know first and ensuring this has been
          planned. Only a primary caregiver, can take this action and only secondary caregivers
          can be removed from the squad. As a primary caregiver, you click on the “More” tab,
          click on “Manage Squads”, click on edit next to the squad members, and then select
          the squad member you want to remove and click remove at the bottom. If you do this,
          then all the tasks assigned to the removed squad member will be re-assigned to the
          primary caregiver.
          If you are a primary caregiver, and want to remove yourself from the squad, please
          contact us via <a href="https://kinary.io/" target="_blank">https://kinary.io/</a> and clicking on Contact Us button, and we will help you with this action. In
          future releases, you will be able to do this yourself as a primary caregiver.`,
      },
      {
        question: `How do I delete a squad?`,
        answer: `We would again recommend ensuring your squad and the care recipient are ok with
          this. Only a primary caregiver can delete the squad. As a primary caregiver you click
          on the “More” tab, click on “Manage Squads”, scroll all the way to the bottom and click
          on “delete care recipient and squad”. Please note that this will delete all of the squad
          data, care recipient and care plan data. It will take approximately 30 days to complete
          this operation. And, this action CANNOT be reversed.`,
      },
      {
        question: `How do I switch between squads?`,
        answer: `Easy. Go to homepage. Click on the down arrow next to the squad name, select
          another squad and Kinary will automatically switch to that squad.`,
      },
    ],
  },
  {
    category: "Profile management",
    questionSet: [
      {
        question: `How do I change my personal details?`,
        answer: `Click on “More”, go to “My Profile”, and make the relevant changes and hit save.`,
      },
      {
        question: `How do I edit my phone number?`,
        answer: `Please contact us via <a href="https://kinary.io/" target="_blank">https://kinary.io/</a> and clicking on Contact Us button to make this change. Editing the phone
          number is a complicated task given your identity is tied to it. In the future releases of
          Kinary, you will also be able to make this change directly via Kinary.`,
      },
    ],
  },
  {
    category: "Care hub / Care plan",
    questionSet: [
      {
        question: `What is the Care Hub?`,
        answer: `The Care Hub is the one central place from where you can easily view and manage all
          the required details of care for the care recipient. This covers the Care Plan, all the
          Medications, all the Appointments and all the Provider information.`,
      },
      {
        question: `What is the Care Plan?`,
        answer: `This can be the discharge plan you leave with from the hospital or the plan prescribed
          by a doctor/specialist. Care Plan is a combination of all the activities (recurring and one
          time) that need to be managed for a care recipient to help them towards better health
          outcomes. These activities can be related to medications, appointments, tracking vitals,
          day2day errands, meals and exercise tracking.`,
      },
      {
        question: `Can I have multiple Care Plans?`,
        answer: `With our first release, you can only manage one care plan in Kinary. We are working
          towards new features in Kinary, where our users will be able to manage multiple
          individual care plans. Stay tuned!`,
      },
    ],
  },
  {
    category: "Check-in",
    questionSet: [
      {
        question: `What is a check-in?`,
        answer: `Check-in allows you to mark the task as completed and note down observations related
          to it. For example you can mark down the task “Tracking Blood Pressure” as completed
          and note down what the blood pressure was at the same time. Check-ins help you stay
          on track with care plans, view care responsibilities and the assigned squad members,
          and help you plan your contribution to care needs.`,
      },
      {
        question: `Can I check-in for other squad members?`,
        answer: `While the best practice is that each squad member check-in for themselves, you
          certainly can check-in for other squad members if you know they have completed the
          task but just haven’t been able to check-in yet.`,
      },
    ],
  },
  {
    category: "Task management",
    questionSet: [
      {
        question: `How do I know if someone has accepted the task I
        assigned?`,
        answer: `There are three ways ways to look for this information.
          1. Kinary will send you a push notification when the squad member has accepted or
          rejected a task.
          2. You can also navigate to the individual task and see the status and if the squad
          member has accepted or rejected the task. Accepted status means they have
          taken on the responsibility. Rejected status means they can’t take on the
          responsibility. And, Pending status means they are either thinking about it or still
          haven’t seen the task.
          3. The Activity tab will also show you when a squad member has accepted or rejected
          the task.`,
      },
      {
        question: `How do I see what tasks have been assigned to me?`,
        answer: `Check-in tab is the best place to see all the tasks that have been assigned to you for a
          particular day. You can also navigate to the Care Plan, Medications and/or
          Appointments to see which tasks have been assigned to you.`,
      },
      {
        question: `How to reassign tasks?`,
        answer: `Easy. Edit the task you want to reassign. Go to the bottom and select which squad
          member you want to reassign it to and click on save.`,
      },
      {
        question: `How do I know if someone has requested help from
        me?`,
        answer: `Just like the batman icon, the Kinary logo on the top right of the homepage lights up if
          someone has requested help from you. You will also see this in the activity feed.`,
      },
      {
        question: `What if I need to take a vacation and/or I can not do a
        task?`,
        answer: `In Kinary we make it simple to deal with life’s ad-hoc challenges that come up. If you
          are not able to do a task, simply reassign the task(s) to other squad members via
          editing the task, selecting the new owner and saving it. And, we make it really easy to
          do this via Care Plan, Medications and/or Appointments.`,
      },
    ],
  },
  {
    category: "Medications",
    questionSet: [
      {
        question: `What kind of Medications can I track?`,
        answer: `You can track any and all medications and/or supplements with Kinary. Currently,
          Kinary allows you to search and add all US based FDA approved medications. For the
          other types of medications/supplements, you can manually add these in Kinary. We are
          continuously adding other medications and supplements to make the entry easier.
          Once you have added the medication and/or supplement, you can also setup the
          dosage administration schedule.`,
      },
      {
        question: `Can I set the refill date?`,
        answer: `Find the medication in Kinary and set the refill date.`,
      },
      {
        question: `Can I schedule refill pickups?`,
        answer: `Yes you can. Click on Care Hub, click on Medications, Scroll to the bottom where it
          says Manage Tasks- select the refill task, click the date when you would like the
          medication picked up and assign it to a squad member.`,
      },
      {
        question: `Can I transfer prescriptions to the pharmacy?`,
        answer: `You can not currently do this in Kinary, but we are working on this feature for future
          releases.`,
      },
      {
        question: `Can I track discontinued medications?`,
        answer: `It is recommended these medications are tracked in Kinary so you always have a
          history of what medications were administered for a care recipient. This maybe helpful
          as you switch clinical providers or talk to other family members.`,
      },
    ],
  },
  {
    category: "Appointments",
    questionSet: [
      {
        question: `What kind of appointments can I track?`,
        answer: `Many different types of appointments can be tracked in Kinary. We would suggest
          tracking any (clinical, personal or other) appointments related to the care of the care
          recipient be tracked here.`,
      },
      {
        question: `How do I find the appointments once the date has
        passed?`,
        answer: `Easy. Just go to Care Hub, click on Appointments, and look at the Past Appointments
          tab.`,
      },
      {
        question: `How can Kinary help me prepare for an appointment?`,
        answer: `Kinary provides the capabilities to add checklists or questions.
          Checklists are things that you need to do to prepare for an appointment, things you
          may need to remember or things you need to take to an appointment. Some
          examples of checklist items include: calling the insurance company, taking the
          medications or other doctor’s reports, making sure you get the parking ticket
          validated. Other squad members can also add to the checklist.
          Questions are the questions you may want to ask the Provider. It is recommended
          that you spend some time thinking through this in advance of the appointment so
          you cover everything with the Provider during the appointment. Other squad
          members can also add to this question list.`,
      },
      {
        question: `Do my appointments in Kinary synch with my other
        calendars?`,
        answer: `Kinary does automatically add the appointments to your local phone calendar. Kinary
          automatically syncs with your devices primary calendar application. So, we recommend
          you create the appointment in Kinary so you can benefit from important features of the
          calendar application such as reminders and alarms. Appointments created outside of
          Kinary do not synch with Kinary.`,
      },
      {
        question: `Where do I capture my notes during or post
        appointment?`,
        answer: `Sometimes you may want to share the updates from the appointment with the rest of
          the squad. The best way to do this is via Notes related to the appointment. You can
          add your summary from the visit and also add anything you want to remember from the
          visit to the appointment. This way anyone looking at the appointment will also be able
          to see the note. In future releases, you will be able to add photos to the appointment
          note as well.`,
      },
      {
        question: `Can I connect directly with ride apps or transportation
        services to arrange rides to appointments?`,
        answer: `This is not a feature we currently support. We are looking to build this in the future
          releases of Kinary. You can learn about different local community services via Kinary,
          but not connect with them directly yet.`,
      },
    ],
  },
  {
    category: "Providers",
    questionSet: [
      {
        question: `What kind of Providers can I track?`,
        answer: `Any and all types of Providers can be tracked in Kinary. We suggest maintaining a
          database of all of the Providers (clinical, non-clinical) that are involved in the care of a
          care recipient is tracked in Kinary. This allows all the squad members to have access to
          their contact information in case of need. It also saves time for you.`,
      },
      {
        question: `Can I search for Providers in my zipcode in Kinary?`,
        answer: `Searching and finding new providers by zipcode and/or specialty is not a feature that
          Kinary provides right now. This is something we are considering for future releases.`,
      },
      {
        question: `How do I track Providers with multiple addresses?`,
        answer: `Currently, a Provider in Kinary does not have an address associated with it. However,
          you can find and select a Provider facility address when creating an appointment.
          These can be different or same for a Provider when creating an appointment.`,
      },
    ],
  },
  {
    category: "Activity",
    questionSet: [
      {
        question: `What is the Activity feed?`,
        answer: `Just like facebook, an activity feed allows you to see all the important actions that have
        happened in Kinary regarding the care for a care recipient. These actions can include new
          squad members joining/leaving, new medication tasks being
          created/accepted/rejected, new appointments being added, new tasks for care plan
          being created/accepted/rejected. It is the one place to go to understand what all has
          happened during the day/week/month for a care recipient.`,
      },
    ],
  },
  {
    category: "Your account",
    questionSet: [
      {
        question: `How do i get billed?`,
        answer: `Currently- we are offering Kinary as a free service for our Beta users. As we move to a
          GA release, we will be updating our pricing information on the website. All billing will be
          handled via the Apple App Store or Google Play Store subscription service.`,
      },
      {
        question: `Can I cancel anytime?`,
        answer: `Users can cancel the service anytime. Please see above - squad management to
          understand how a secondary caregiver and the primary caregiver can leave the squad
          anytime.`,
      },
      {
        question: `What happens if I need to delete the data?`,
        answer: `If you are a secondary caregiver, you can leave the squad anytime by going to More,
          Manage Squads, Select the squad and then click on Leave Squad. If you are a primary
          caregiver OR want to delete the squad/care recipient, please fill in the request via
          <a href="https://kinary.io/#/useraccountdelete" target="_blank">https://kinary.io/#/useraccountdelete</a> to perform this operation.`,
      },
      {
        question: `Who do I talk to if I have problems?`,
        answer: `Please contact us via <a href="https://kinary.io/" target="_blank">https://kinary.io/</a> and clicking on Contact Us button. We monitor this channel daily and will
          get back to you within 2 business days.`,
      },
      {
        question: `How do I ask for changes?`,
        answer: `Please contact us via <a href="https://kinary.io/" target="_blank">https://kinary.io/</a> and clicking on Contact Us button. We monitor this channel daily and will
          get back to you within 2 business days.`,
      },
    ],
  },
  {
    category: "Your information",
    questionSet: [
      {
        question: `How do you protect my information?`,
        answer: `Privacy is a very important word for us and we take it very seriously. You can view our
          Privacy Policy here. We will never share your information with anyone without your
          consent.`,
      },
      {
        question: `How do you protect my loved one’s/client’s
        information?`,
        answer: `Privacy is a very important word for us and we take it" very seriously. You can view our
          Privacy Policy at <a href="https://kinary.io/#/privacy-policy" target="_blank">https://kinary.io/#/privacy-policy</a>. We will never share this information with anyone without your
          consent.`,
      },
      {
        question: `What type of encryption do you use?`,
        answer: `We use 256-bit encryption, the same level used by banks. We force HTTPS for all traffic
          to ensure data is encrypted and to protect against man-in-the-middle attacks. Please
          contact us if you need to learn more.`,
      },
      {
        question: `Who sees my information?`,
        answer: `With your consent, ONLY the “invited” squad members for “A” care recipient can see
          each other’s information.`,
      },
      {
        question: `Who sees my loved one’s/client’s information?`,
        answer: `With your consent, ONLY the “invited” squad members for “A” care recipient see the
          care recipient’s information.`,
      },
      {
        question: `How do I report a security concern?`,
        answer: `Please contact us via <a href="https://kinary.io/" target="_blank">https://kinary.io/</a> and clicking on Contact Us button.  We monitor this daily.`,
      },
    ],
  },
];

export const blogOptions = [
  {
    blogImg: blog1,
    date: "Jan 31, 2024",
    length: "4 min read",
    link: "https://medium.com/@kinary/caregiving-101-wondering-how-to-get-started-b4d31b307aa1",
    title: "Caregiving 101: Wondering how to get started?",
    body: "The start of any caregiving journey is a precarious place — coming to terms with the fact that your loved one needs special care combined with the pressure to fit a major responsibility into your life seamlessly makes the transition overwhelming.",
  },
  {
    blogImg: blog2,
    date: "Feb 13, 2024",
    length: "4 min read",
    link: "https://medium.com/@kinary/navigating-caregiving-responsibilities-asking-family-for-help-bfc8c17713c9",
    title: "Navigating caregiving responsibilities: Asking family for help",
    body: "Caregiving is a lot like a team sport. If one person tries to play every position, chaos is inevitable. Unfortunately, this is the reality for most caregivers. It’s also one of the reasons why 36% of caregivers in America experience stress, which can result in burnout.",
  },
  {
    blogImg: blog3,
    date: "Feb 13, 2024",
    length: "3 min read",
    link: "https://medium.com/@kinary/navigate-the-financial-implications-of-caregiving-confidently-5805abd1e7da",
    title: "Navigate the financial implications of caregiving confidently",
    body: "As a caregiver, you’re in a unique position to be able to actively support your loved one’s physical, mental, and emotional health. But the flip side is that long-term caregiving can contribute to stress.",
  },
];
